import {
  Alert,
  AlertIcon,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  Button
} from "@chakra-ui/react";

type AlertProps = ChakraAlertProps & {
  message?: string;
  onClick?: () => void;
};

export function DataNotReadyAlert({
  message = "Dane nie są gotowe do wyświetlenia",
  onClick,
  ...alertProps
}: AlertProps): JSX.Element {
  return (
    <Alert status="warning" {...alertProps}>
      <AlertIcon />
      <AlertTitle>{message}</AlertTitle>
      {!!onClick && (
        <Button onClick={onClick} colorScheme="yellow" size="sm">
          Spróbuj ponownie
        </Button>
      )}
    </Alert>
  );
}

export function FailedFetchingAlert({
  message = "Nieudane pobieranie listy sklepów",
  ...alertProps
}: AlertProps): JSX.Element {
  return (
    <Alert status="error" {...alertProps}>
      <AlertIcon />
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
}

export function NoDataAlert({
  message = "Nie znaleziono sklepów",
  ...alertProps
}: AlertProps): JSX.Element {
  return (
    <Alert status="info" {...alertProps}>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
}
