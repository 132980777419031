import { testIsDate, testIsTime } from "utils/dateTime";

export const getCurrentDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  return new Date(date);
};

export const parseDate = (value?: string) => {
  if (!value) return null;
  const isTime = testIsTime(value);
  const isDate = testIsDate(value);

  try {
    if (isTime) {
      const date = new Date();
      const [hours, minutes] = value.split(":");
      date.setHours(parseInt(hours), parseInt(minutes), 0, 0);

      return date;
    }

    if (isDate) {
      const [day, month, year] = value.split("/");

      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    return new Date(value);
  } catch (error) {
    console.log(error);
  }
};
