import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FailedFetchingAlert } from "components";
import { Link } from "react-router-dom";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { TableBottomSection } from "components/tableBottomSection/TableBottomSection";
import { farLeftCellStyle, farRightCellStyle } from "utils/commonStyles";

import { TopSection } from "./TopSection";
import { useStoreChainsData } from "./useStoreChainsData";

export function StoreChains(): JSX.Element {
  const {
    storeChains,
    isMoreStoreChains,
    error,
    isLoading,
    fetchMoreStoreChains
  } = useStoreChainsData();

  if (error) {
    return (
      <FailedFetchingAlert
        mt="30px"
        message="Nieudane pobieranie sieci sklepów"
      />
    );
  }

  return (
    <>
      <TopSection />

      <BorderedBox mt="0">
        {!!storeChains?.length && (
          <TableContainer maxWidth="100vw" overflowX="auto">
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th {...farLeftCellStyle} lineHeight="2rem">
                    ID
                  </Th>
                  <Th>Nazwa</Th>
                  <Th>Ostatnia aktualizacja</Th>
                  <Th {...farRightCellStyle} />
                </Tr>
              </Thead>
              <Tbody>
                {storeChains.map(storeChain => (
                  <Tr key={storeChain.chainId} height="60px">
                    <Td {...farLeftCellStyle}>{storeChain.chainId}</Td>
                    <Td>{storeChain.name}</Td>
                    <Td>{storeChain.updatedAt.toLocaleString()}</Td>
                    <Td {...farRightCellStyle}>
                      <Button as={Link} to={storeChain.chainId}>
                        Więcej
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <TableBottomSection
          isMore={isMoreStoreChains}
          isLoading={isLoading}
          noData={!storeChains.length}
          fetchMore={fetchMoreStoreChains}
          entityName="sieci sklepów"
        />
      </BorderedBox>
    </>
  );
}
