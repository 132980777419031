import { HStack, Skeleton, Tag, Tooltip } from "@chakra-ui/react";
import {
  ComponentNotFoundValue,
  PosPaperStateValue
} from "api/common/storeComponentState";
import { ComponentsState } from "api/common/storeState";

import { getStoreComponentsProperties, posPaperStateNames } from "./utils";

type HealthchecksProps = {
  isLoadingHealthchecks?: boolean;
  isLoadingPosPaperState?: boolean;
  healthchecks?: ComponentsState;
  posPaperState?: PosPaperStateValue | ComponentNotFoundValue;
};

export function Healthchecks({
  isLoadingHealthchecks: isLoadingComponentsState = true,
  isLoadingPosPaperState = true,
  healthchecks,
  posPaperState
}: HealthchecksProps): JSX.Element {
  return (
    <HStack spacing="0.25rem" flexWrap="wrap">
      <Skeleton
        isLoaded={!isLoadingComponentsState}
        minWidth="4rem"
        height="1.5rem"
        mb="0.25rem"
      >
        <HStack spacing="0.25rem">
          {healthchecks &&
            getStoreComponentsProperties(healthchecks).map(healthcheck => (
              <Tooltip
                key={healthcheck.name}
                hasArrow={true}
                placement="top"
                label={healthcheck.stateLabel}
              >
                <Tag
                  mr="0.25rem"
                  key={healthcheck.name}
                  colorScheme={healthcheck.colorScheme}
                  textAlign="center"
                  flexShrink="0"
                >
                  {healthcheck.name}
                </Tag>
              </Tooltip>
            ))}
        </HStack>
      </Skeleton>
      <Skeleton
        isLoaded={!isLoadingPosPaperState}
        minWidth="4rem"
        height="1.5rem"
      >
        {posPaperState && (
          <Tooltip
            hasArrow={true}
            placement="top"
            label={posPaperStateNames[posPaperState].stateLabel}
          >
            <Tag
              colorScheme={posPaperStateNames[posPaperState].colorScheme}
              textAlign="center"
            >
              Papier POS
            </Tag>
          </Tooltip>
        )}
      </Skeleton>
    </HStack>
  );
}
