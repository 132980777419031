import { useEffect } from "react";
import { FailedFetchingAlert } from "components";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";
import { DataNotReadyAlert } from "components/alerts/Alerts";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { HeaderWithOptions } from "components/headerWithOptions/HeaderWithOptions";

import { PreviewContent } from "./components/PreviewContent";
import { RemoveKeyButton } from "./components/RemoveKeyButton";
import { responseMapper } from "./helpers";
import { useGetAppConfigKey } from "./methods/useGetAppConfigKey";

export function AppConfigKeysPreview() {
  const { [PathParameters.STORE_APP_CONFIG_KEY_ID]: keyId } = useParams();
  const getKey = useGetAppConfigKey(keyId);

  useEffect(() => {
    if (!keyId) return;

    getKey.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyId]);

  if (!keyId) return <FailedFetchingAlert message="Brak ID klucza" />;

  if (getKey.isLoading) return <CustomSpinner />;

  const isDataNotReady = getKey.error?.status === 500;
  if (isDataNotReady)
    return <DataNotReadyAlert onClick={() => getKey.fetchData()} />;

  if (getKey.error)
    return <FailedFetchingAlert message="Nieudane pobieranie klucza" />;

  return (
    <>
      <CustomBreadcrumbs />

      <BorderedBox padding="2rem" mt=".5rem">
        <HeaderWithOptions
          isEdit
          additionalButtons={[
            <RemoveKeyButton isIcon keyId={keyId} key={keyId} />
          ]}
          title="Klucz konfiguracyjny"
        />
        {getKey.data && (
          <PreviewContent
            appConfigKey={responseMapper(getKey.data)}
            isLoading={getKey.isLoading}
          />
        )}
      </BorderedBox>
    </>
  );
}
