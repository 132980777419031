import * as Yup from "yup";

import { StoreChainFields } from "types/configFields";

import { componentsSchema } from "./componentsSchema";
import { openingScheduleSchema } from "./openingScheduleSchema";
import { parametersSchema } from "./parametersSchema";
import { servicesSchema } from "./servicesSchema";

export const requiredMessage = "To pole nie może być puste";

export const validationSchema = Yup.object().shape({
  [StoreChainFields.NAME]: Yup.string().required(requiredMessage),
  [StoreChainFields.CHAIN_ID]: Yup.string().required(requiredMessage),
  [StoreChainFields.PARAMETERS]: parametersSchema,
  [StoreChainFields.SERVICES]: servicesSchema,
  [StoreChainFields.COMPONENTS]: componentsSchema,
  [StoreChainFields.OPENING_SCHEDULE]: openingScheduleSchema
});
