import { ChakraStylesConfig } from "chakra-react-select";

import { TOption } from "./CustomSelect";

export const getCustomStyles = ({
  isChevronHidden
}: {
  isChevronHidden?: boolean;
}): ChakraStylesConfig => ({
  input: provided => ({
    ...provided,
    gridArea: "auto"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    background: "transparent",
    paddingRight: ".5rem",
    paddingLeft: "0",
    color: "gray.600",
    border: "1px solid red"
  }),
  downChevron: provided => ({
    ...provided,
    height: "28px",
    width: "28px",
    display: isChevronHidden ? "none" : "block"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    background: "white"
  }),
  indicatorSeparator: () => ({
    visibility: "hidden"
  }),
  option: (provided, context) => {
    const { isSelected } = context;

    return {
      ...provided,
      padding: "2",
      paddingLeft: "6",
      fontSize: "0.8rem",
      borderBottom: "1px solid",
      marginBottom: "0",
      borderColor: "transparent",
      fontWeight: isSelected ? "bold" : "initial",
      backgroundColor: isSelected && "blue.dark !important"
    };
  },
  menuList: provided => ({
    ...provided,
    boxShadow: "0 0 20px 10px #00000020"
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: "blue.light"
  }),
  multiValueLabel: () => ({
    lineHeight: 2
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: "sm"
  }),

  clearIndicator: () => ({
    fontSize: ".7rem",
    backgroundColor: "blue.500",
    color: "white",
    height: "1.2rem",
    width: "1.2rem",
    display: "flex",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center"
  })
});

export const normalizeValue = ({
  options,
  value
}: {
  options: TOption[];
  value: TOption["value"];
}) => {
  const el = options.find(option => option.value === value);

  return el || false;
};

export const getValue = ({
  options,
  isMulti,
  value
}: {
  options: TOption[];
  isMulti?: boolean;
  value?: TOption["value"];
}) => {
  if (!value) return false;

  if (options) {
    if (isMulti && Array.isArray(value)) {
      return value.map((item: TOption["value"]) =>
        normalizeValue({ options, value: item })
      );
    }

    return normalizeValue({ options, value });
  }
};

export const optionMapper = ({
  option,
  isMulti
}: {
  option: unknown;
  isMulti?: boolean;
}) => {
  if (isMulti && Array.isArray(option)) {
    const values = option.map(item => item.value);

    return values;
  }

  return (option as TOption).value;
};
