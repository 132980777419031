import { ReactNode, createContext, useContext } from "react";
import { StoreChainConfig } from "api/common";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";
import { useStoreChainData } from "pages/storeChain/hooks/useStoreChainData";

interface IStoreChainContextProviderProps {
  children: ReactNode;
}

export const StoreChainContext = createContext<{
  isLoading: boolean;
  storeChain: StoreChainConfig | null;
  error: boolean;
  storeChainId?: string;
}>({
  isLoading: false,
  storeChain: null,
  error: false,
  storeChainId: ""
});

export const useStoreChainContext = () => {
  const context = useContext(StoreChainContext);

  if (!context) {
    throw new Error(
      "useStoreChainContext hook must be used within a StoreChainContextProvider"
    );
  }

  return context;
};

export const StoreChainContextProvider = ({
  children
}: IStoreChainContextProviderProps) => {
  const { [PathParameters.STORE_CHAIN_ID]: storeChainId } = useParams();
  const { storeChain, isLoading, error } = useStoreChainData(storeChainId);

  return (
    <StoreChainContext.Provider
      value={{ storeChain, isLoading, error, storeChainId }}
    >
      {children}
    </StoreChainContext.Provider>
  );
};
