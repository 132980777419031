import { EPatternType, StoreWithChainName, TFilters } from "./types";

export const getFilteredStores = (
  stores: StoreWithChainName[],
  pattern: string | number,
  patternType: EPatternType,
  filters: TFilters
) => {
  const searchPattern = String(pattern).toLocaleLowerCase();
  const filteredStores = stores
    .filter(store => {
      if (filters.state === "ALL") {
        return true;
      }

      return filters.state === store.state;
    })
    .filter(store => {
      if (filters.storeAvailability === "ALL") {
        return true;
      }
      return filters.storeAvailability === store.storeAvailability;
    })
    .filter(store => {
      if (searchPattern === "") {
        return true;
      }

      const {
        externalStoreId,
        address,
        chainId,
        components: { aifi, infokiosk }
      } = store;

      switch (patternType) {
        case EPatternType.EXTERNAL_STORE_ID:
          return externalStoreId?.toLocaleLowerCase().match(searchPattern);
        case EPatternType.ADDRESS:
          return address?.toLocaleLowerCase().match(searchPattern);
        case EPatternType.CHAIN_ID:
          return chainId.toLocaleLowerCase().match(searchPattern);
        case EPatternType.AIFI_ID:
          return String(aifi.aifiId).match(searchPattern);
        case EPatternType.SCOPE_NANOVO:
          return infokiosk.scope.toLocaleLowerCase().match(searchPattern);
        default:
          return externalStoreId?.toLocaleLowerCase().match(searchPattern);
      }
    });

  return filteredStores;
};
