// API/SSD/016

import { API_ROOT, DEFAULT_RESULTS_PER_PAGE } from "consts";

import { StoreConfig } from "./common";
export type { StoreConfig } from "./common";

export const listStoreConfigsUrl = `${API_ROOT}/stores`;

export type ListStoreConfigsQueryParams = {
  maxResults?: string;
  nextPageMarker?: string;
  externalStoreId?: string;
  aifiStoreId?: string;
  infokioskScope?: string;
  chainId?: string;
};

export type SearchQueryParam = Exclude<
  keyof ListStoreConfigsQueryParams,
  "nextPageMarker" | "maxResults"
>;

export type ListStoreConfigsResponse = {
  nextPageMarker?: string;
  stores: StoreConfig[];
};

export function prepareUrlWithSearchParams(
  {
    maxResults = DEFAULT_RESULTS_PER_PAGE,
    nextPageMarker
  }: ListStoreConfigsQueryParams,
  query?: SearchQueryParam,
  value?: string
): URL {
  const params: ListStoreConfigsQueryParams = {
    maxResults
  };

  if (nextPageMarker) {
    params.nextPageMarker = nextPageMarker;
  }

  if (query && value) {
    params[query] = value;
  }

  const url = new URL(listStoreConfigsUrl);
  url.search = new URLSearchParams(params).toString();

  return url;
}
