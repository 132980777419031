import {
  EComplaint,
  EComplaintResponse,
  ECurrency,
  EOrder,
  ERefundIssuingStatus,
  ESource,
  EStatus,
  ETrack,
  TComplaint,
  TComplaintsResponse
} from "pages/complaints/methods/useGetComplaints/types";

export const mockComplaint: TComplaint = {
  [EComplaint.ID]: "e10141ad-045c-4682-bb13-a6420e13b7cf",
  [EComplaint.ORDER_ID]: "af14cca6-7313-40a0-808f-253ca16ba367",
  [EComplaint.ORDER]: {
    [EOrder.HAS_COUPON]: true,
    [EOrder.AIFI_ID]: 17657263756293,
    [EOrder.CASH_AMOUNT]: 200,
    [EOrder.CURRENCY]: ECurrency.PLN
  },
  [EComplaint.ACCOUNT_ID]: "ed6c0c20-755a-4664-a6e8-4f80d8ce7ec9",
  [EComplaint.STORE_ID]: "2c78ea38-be4c-4e8f-b9d2-84a5682bfe7e",
  [EComplaint.USER_ID]: "47f1cbae-f0f4-4e51-b877-2af30488a42a",
  [EComplaint.LOYAL_ID]: "de1e0e30-ed33-491b-91ce-4b060eb1a430",
  [EComplaint.EMAIL]: "test@email.com",
  [EComplaint.CREATED_AT]: new Date().toISOString(),
  [EComplaint.UPDATED_AT]: new Date().toISOString(),
  [EComplaint.CORRECTED_ORDER_CASH_AMOUNT]: 100,
  [EComplaint.SOURCE]: ESource.BACKOFFICE,
  [EComplaint.STATUS]: EStatus.APPROVED,
  [EComplaint.TRACK]: ETrack.AUTOMATIC,
  [EComplaint.REFUND_ISSUING_STATUS]: ERefundIssuingStatus.FULL
};

export const mockComplaintsResponse: TComplaintsResponse = {
  [EComplaintResponse.COMPLAINTS]: [
    mockComplaint,
    {
      ...mockComplaint,
      [EComplaint.ID]: "98c806f1-75de-4224-a119-80d6f65c0255",
      [EComplaint.SOURCE]: ESource.INSTA,
      [EComplaint.STATUS]: EStatus.IN_PROGRESS,
      [EComplaint.TRACK]: ETrack.MANUAL,
      [EComplaint.REFUND_ISSUING_STATUS]: ERefundIssuingStatus.FULL,
      [EComplaint.ORDER]: {
        ...mockComplaint[EComplaint.ORDER],
        [EOrder.HAS_COUPON]: false,
        [EOrder.CURRENCY]: ECurrency.EUR
      }
    },
    {
      ...mockComplaint,
      [EComplaint.ID]: "745ba099-3913-470a-9f66-3e70009256b9",
      [EComplaint.SOURCE]: ESource.MOBILE_APP,
      [EComplaint.STATUS]: EStatus.OPEN,
      [EComplaint.TRACK]: ETrack.AUTOMATIC,
      [EComplaint.REFUND_ISSUING_STATUS]: ERefundIssuingStatus.NONE
    },
    {
      ...mockComplaint,
      [EComplaint.ID]: "22957edd-9ae9-4f0a-aa52-cd6f16a0aaff",
      [EComplaint.SOURCE]: ESource.MOBILE_APP,
      [EComplaint.STATUS]: EStatus.REJECTED,
      [EComplaint.TRACK]: ETrack.MANUAL,
      [EComplaint.REFUND_ISSUING_STATUS]: ERefundIssuingStatus.PARTIAL,
      [EComplaint.ORDER]: {
        ...mockComplaint[EComplaint.ORDER],
        [EOrder.HAS_COUPON]: false,
        [EOrder.CURRENCY]: ECurrency.EUR
      }
    }
  ],
  [EComplaintResponse.COUNT]: 100,
  [EComplaintResponse.PAGE]: {
    [EComplaintResponse.PAGE_OFFSET]: 1,
    [EComplaintResponse.SIZE]: 30,
    [EComplaintResponse.NEXT_PAGE_OFFSET]: 31
  }
};
