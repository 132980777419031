import { useCallback, useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  ValidateStoreConfigResponse,
  validateStoreConfigUrl
} from "api/validateStoreConfig";
import { useFetch } from "hooks";
import { useParams } from "react-router-dom";

import { DEFAULT_TOAST_DURATION_MS } from "consts";
import { PathParameters } from "routes";

export function useValidateStoreConfig(openModal: () => void): {
  validateStoreConfig: () => void;
  isLoading: boolean;
  validationResult: ValidateStoreConfigResponse | null;
} {
  const { [PathParameters.STORE_ID]: storeId } = useParams();
  const [validationResult, setValidationResult] =
    useState<ValidateStoreConfigResponse | null>(null);
  const { postWithAccessToken, isLoading } = useFetch();
  const toast = useToast();

  const validateStoreConfig = useCallback(
    async function validateStoreConfigAsync() {
      if (!storeId) {
        return;
      }

      const url = validateStoreConfigUrl(storeId);
      try {
        const response =
          await postWithAccessToken<ValidateStoreConfigResponse>(url);
        const result = await response.json();
        setValidationResult(result);
        openModal();
      } catch (e) {
        toast({
          title: "Nieudana walidacja konfiguracji",
          status: "error",
          duration: DEFAULT_TOAST_DURATION_MS,
          isClosable: true
        });
      }
    },
    [postWithAccessToken, storeId, toast, openModal]
  );

  return {
    validationResult,
    validateStoreConfig,
    isLoading
  };
}
