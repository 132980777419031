import { StoreData } from "./types";

export function getFilteredStores({
  stores,
  pattern,
  isWithoutToken = true
}: {
  stores: StoreData[];
  pattern: string;
  isWithoutToken?: boolean;
}): StoreData[] {
  const p = pattern.toLocaleLowerCase();
  const filteredStores = stores
    .filter(store => {
      if (isWithoutToken) return !!store.components.powerBouncer?.token;

      return true;
    })
    .filter(store => {
      if (pattern === "") {
        return true;
      }

      const { name, address, storeId, externalStoreId } = store;

      const n = name.toLocaleLowerCase();
      const a = address.toLocaleLowerCase();
      const id = storeId.toLocaleLowerCase();

      try {
        const found = n.match(p) || a.match(p) || id === p;

        if (!found && externalStoreId) {
          const eId = externalStoreId.toLocaleLowerCase();
          return eId.match(p);
        }
        return found;
      } catch (error) {
        return false;
      }
    });

  return filteredStores;
}
