import { Tag } from "@chakra-ui/react";
import { EStoreAvailability } from "api/common/storeState";

import { StateColorScheme } from "consts";

export const StoreAvailabilityNames: Record<
  EStoreAvailability,
  { stateLabel: string; colorScheme: StateColorScheme }
> = {
  [EStoreAvailability.OPEN]: {
    stateLabel: "Otwarty",
    colorScheme: StateColorScheme.OK
  },
  [EStoreAvailability.CLOSED_MAINTENANCE_MANUALLY]: {
    stateLabel: "Zamknięty ręcznie",
    colorScheme: StateColorScheme.OTHER
  },
  [EStoreAvailability.CLOSED_OUTSIDE_WORKING_HOURS]: {
    stateLabel: "Poza godzinami pracy",
    colorScheme: StateColorScheme.OTHER
  },
  [EStoreAvailability.CLOSED_MAINTENANCE_COMPONENT]: {
    stateLabel: "Awaria",
    colorScheme: StateColorScheme.ERROR
  },
  [EStoreAvailability.CLOSED_OTHER_REASON]: {
    stateLabel: "Inny powód",
    colorScheme: StateColorScheme.WARNING
  }
};

type StoreAvailabilityProps = {
  storeAvailability?: EStoreAvailability;
};

export function StoreAvailability({
  storeAvailability
}: StoreAvailabilityProps): JSX.Element | null {
  if (!storeAvailability) {
    return null;
  }

  return (
    <Tag colorScheme={StoreAvailabilityNames[storeAvailability].colorScheme}>
      {StoreAvailabilityNames[storeAvailability].stateLabel}
    </Tag>
  );
}
