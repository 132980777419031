import { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { FailedFetchingAlert } from "components";
import { Form, Formik } from "formik";
import { Navigate } from "react-router-dom";

import { Routes } from "routes";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { HeaderWithOptions } from "components/headerWithOptions/HeaderWithOptions";
import { Property } from "components/property";
import { useStoreChainContext } from "contexts/StoreChainContext";
import { useRoles } from "hooks/roles/useRoles";

import SearchPanel from "./components/SearchPanel";
import Fields from "./Fields";
import { responseMapper } from "./mappers/response";

export function StoreChain(): JSX.Element {
  const { canEditStoreChains } = useRoles();

  const { storeChain, storeChainId, isLoading, error } = useStoreChainContext();
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  if (!storeChainId) {
    return <Navigate to={Routes.STORE_CHAINS} />;
  }

  if (error) {
    return (
      <FailedFetchingAlert message="Nieudane pobieranie danych sieci sklepów" />
    );
  }

  const mappedStoreChain = responseMapper(storeChain);

  return (
    <>
      <CustomBreadcrumbs
        uniqueLabels={{
          [storeChainId as string]: storeChain
            ? `${storeChain.name} (${storeChainId})`
            : storeChainId
        }}
      />

      <BorderedBox padding={{ base: "1rem", lg: "3rem" }}>
        {storeChain && (
          <HeaderWithOptions
            title={`Sieć sklepów ${storeChain.name}`}
            isEdit={canEditStoreChains}
          />
        )}

        <Flex gap="4rem">
          <Flex direction="column" gap="1rem">
            <Property
              label="Ostatnia aktualizacja konfiguracji"
              isLoading={isLoading}
            >
              {storeChain?.updatedAt.toLocaleString()}
            </Property>
          </Flex>
        </Flex>
      </BorderedBox>

      <Box mt="1rem">
        <Flex
          maxHeight="74vh"
          overflow="auto"
          position="relative"
          id="storeChain-wrapper"
        >
          <SearchPanel setExpandedIndices={setExpandedIndices} />
          {storeChain && (
            <Box w="80%">
              <Formik initialValues={mappedStoreChain} onSubmit={() => {}}>
                <Form>
                  <Fields
                    isReadOnly
                    expandedIndices={expandedIndices}
                    setExpandedIndices={setExpandedIndices}
                    isLoading={false}
                  />
                </Form>
              </Formik>
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
}
