import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRoles } from "hooks/roles/useRoles";

import { navigation } from "./navigationDefinition";
import { TNavigationLink, TParentTab } from "./types";

export function getChildrenPermissions(link: TParentTab | TNavigationLink) {
  if (!("children" in link)) {
    return link.permissions;
  }

  return link.children.flatMap(child => child.permissions);
}

export function useAvailableLinks(links: (TParentTab | TNavigationLink)[]) {
  const { shouldDisplay } = useRoles();

  const shouldDisplayLink = (link: TParentTab | TNavigationLink) =>
    shouldDisplay(getChildrenPermissions(link));
  const availableLinks = links.filter(shouldDisplayLink);

  return { shouldDisplayLink, availableLinks };
}

export function useNavigation() {
  const [selectedTab, setSelectedTab] = useState<number>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentTab = navigation.findIndex(tab =>
      "to" in tab
        ? tab.to === location.pathname
        : tab.children.some(child => child.to === location.pathname)
    );

    setSelectedTab(currentTab);
  }, [location.pathname]);

  const { availableLinks, shouldDisplayLink } = useAvailableLinks(navigation);

  const saveLocation = (parent: string, link: string) => {
    try {
      localStorage.setItem(`navigation-${parent}`, link);
    } catch {}
  };

  const switchPage = (index: number) => {
    setSelectedTab(index);

    const selectedParent = availableLinks[index];

    if ("children" in selectedParent) {
      const recentlyVisited = localStorage.getItem(
        `navigation-${selectedParent.label}`
      );
      let selectedLink = selectedParent.children.find(
        link => link.label === recentlyVisited
      );

      if (recentlyVisited && selectedLink && shouldDisplayLink(selectedLink)) {
        navigate(selectedLink.to);
        return;
      }

      selectedLink = selectedParent.children.find(shouldDisplayLink);

      if (!selectedLink) {
        return;
      }

      saveLocation(selectedParent.label, selectedLink.label);
      navigate(selectedLink.to);

      return;
    }

    navigate(selectedParent.to);
  };

  return {
    selectedTab,
    switchPage,
    availableLinks,
    shouldDisplayLink,
    saveLocation
  };
}
