import * as Yup from "yup";

const requiredMessage = "To pole jest wymagane";

const requiredByApiKey = [
  "AIFI",
  "ADYEN",
  "INFOKIOSK",
  "ERP",
  "SHORTENER",
  "SYNERISE"
];

const requiredByBaseUrl = [
  "AIFI",
  "INFOKIOSK",
  "ERP",
  "SHORTENER",
  "SYNERISE",
  "IO_CONTROLLER"
];

export const createConfigKeySchema = Yup.object().shape({
  id: Yup.string()
    .transform(value => value.trim())
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "ID może zawierać jedynie litery, cyfry oraz myślniki"
    )
    .required(requiredMessage),
  name: Yup.string().required(requiredMessage),
  key: Yup.object().shape({
    type: Yup.string().required(requiredMessage),
    apiKey: Yup.string().when("type", {
      is: (value: string) => requiredByApiKey.includes(value),
      then: schema => schema.required(requiredMessage),
      otherwise: schema => schema.optional()
    }),
    baseUrl: Yup.string().when("type", {
      is: (value: string) => requiredByBaseUrl.includes(value),
      then: schema => schema.required(requiredMessage),
      otherwise: schema => schema.optional()
    }),
    token: Yup.string().when("type", {
      is: "AIFI",
      then: schema => schema.required(requiredMessage)
    }),
    terminalUrl: Yup.string().when("type", {
      is: "ADYEN",
      then: schema => schema.required(requiredMessage)
    }),
    posServiceUrl: Yup.string().when("type", {
      is: "POS",
      then: schema => schema.required(requiredMessage)
    })
  })
});

export const editConfigKeySchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage),
  key: Yup.object().shape({
    type: Yup.string().required(requiredMessage),
    apiKey: Yup.string().optional(),
    baseUrl: Yup.string().when("type", {
      is: (value: string) => requiredByBaseUrl.includes(value),
      then: schema => schema.required(requiredMessage),
      otherwise: schema => schema.optional()
    }),
    token: Yup.string().optional(),
    terminalUrl: Yup.string().when("type", {
      is: "ADYEN",
      then: schema => schema.required(requiredMessage)
    }),
    posServiceUrl: Yup.string().when("type", {
      is: "POS",
      then: schema => schema.required(requiredMessage)
    })
  })
});
