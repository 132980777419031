export const tableCellStyle = {
  marginal: {
    position: "sticky",
    backgroundColor: "white"
  },
  farLeft: { left: 0 },
  farRight: { right: 0, width: "7.5rem" }
} as const;

export const farLeftCellStyle = {
  ...tableCellStyle.marginal,
  ...tableCellStyle.farLeft
};
export const farRightCellStyle = {
  ...tableCellStyle.marginal,
  ...tableCellStyle.farRight
};
