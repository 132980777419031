import { ECurrency } from "pages/complaint/methods/useGetComplaint/types";

export enum EUserOrderProperties {
  TRANSACTION_NUMBER = "transactionNumber",
  ORIGINAL = "original",
  TOTAL = "total",
  CASH = "cash",
  CURRENCY = "currency",
  AIFI_SESSION_ID = "aifiSessionId"
}

export enum EUserOrderDetails {
  ID = "id",
  FISCAL_RECEIPT = "fiscalReceipt",
  CREATED_AT = "createdAt",
  PRICE = "price",
  AIFI_SESSION_ID = "aifiSessionId"
}

export type TUserOrderDetails = {
  [EUserOrderDetails.ID]: string;
  [EUserOrderDetails.FISCAL_RECEIPT]: {
    [EUserOrderProperties.TRANSACTION_NUMBER]: string;
  };
  [EUserOrderDetails.CREATED_AT]: string;
  [EUserOrderDetails.PRICE]: {
    [EUserOrderProperties.ORIGINAL]: {
      [EUserOrderProperties.TOTAL]: number;
      [EUserOrderProperties.CASH]: number;
      [EUserOrderProperties.CURRENCY]: ECurrency;
    };
  };
  [EUserOrderDetails.AIFI_SESSION_ID]: string;
};
