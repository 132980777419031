import { Badge, Flex, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";

import { useStoreContext } from "contexts/StoreContext";
import { EStore, TStore } from "types/configFields";

type TProps = {
  name?: string;
  setShouldUseChainValue?: (val: boolean) => void;
  shouldUseChainValue?: boolean;
};

const formatDisplayValue = (value: any) => {
  if (typeof value === "boolean") return value.toString();

  if (typeof value === "object") return JSON.stringify(value, null, 2);

  return (
    value || (
      <Text fontStyle="italic" as="span">
        brak danych
      </Text>
    )
  );
};

const StoreChainValue = ({
  name,
  setShouldUseChainValue,
  shouldUseChainValue
}: TProps) => {
  const { values } = useFormikContext<TStore>();
  const { storeChains } = useStoreContext();
  const storeChainId = getIn(values, EStore.CHAIN_ID);
  const currentChain =
    storeChains.find(chain => chain.chainId === storeChainId) || null;

  if (!name) return null;

  const formValue = getIn(values, name);
  const storeChainValue = getIn(currentChain, name);
  const isColorScheme = !formValue?.toString() && !!storeChainValue;
  const displayValue = formatDisplayValue(storeChainValue);

  return (
    <Flex fontSize=".75rem" gap=".5rem" alignItems="center">
      <Badge
        fontSize=".6rem"
        variant="solid"
        minWidth="2.5rem"
        backgroundColor={
          isColorScheme || shouldUseChainValue ? "green.700" : undefined
        }
        cursor={storeChainValue ? "pointer" : "not-allowed"}
        onClick={() =>
          storeChainValue &&
          setShouldUseChainValue &&
          setShouldUseChainValue(true)
        }
      >
        Sieć
      </Badge>
      <Text
        color={isColorScheme || shouldUseChainValue ? "green.700" : "gray.600"}
      >
        {displayValue}
      </Text>
    </Flex>
  );
};

export default StoreChainValue;
