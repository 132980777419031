import { emptyComponents } from "pages/store/consts";
import { EComponentProperty, TStoreComponents } from "types/configFields";
import { formatDateTimeToISOString } from "utils/dateTime";

import { getTypeFromDatetime } from "../helpers";

export const componentsMapper = (components: TStoreComponents | null) => {
  if (!components) {
    return emptyComponents;
  }

  return {
    ...components,
    pos: {
      ...components?.pos,
      posService: {
        ...components?.pos?.posService,
        maintenanceBreaks: components?.pos?.posService?.[
          EComponentProperty.MAINTENANCE_BREAKS
        ]?.map(item => ({
          type: getTypeFromDatetime(item.startsAt),
          startsAt: formatDateTimeToISOString(item.startsAt),
          endsAt: formatDateTimeToISOString(item.endsAt)
        }))
      }
    }
  };
};
