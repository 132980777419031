import { AppConfigKeysType } from "api/listAppConfigKeys";

export const availableAppConfigKeyTypes: AppConfigKeysType[] = [
  "AIFI",
  "ADYEN",
  "INFOKIOSK",
  "ERP",
  "POS",
  "SHORTENER",
  "SYNERISE",
  "IO_CONTROLLER"
];
