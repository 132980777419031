import { StoreChainComponents } from "api/common/storeChainConfig";

import { getTypeFromDatetime } from "pages/store/mappers/helpers";
import { formatDateTimeToISOString } from "utils/dateTime";

export const componentsMapper = (
  components: StoreChainComponents
): StoreChainComponents | null => {
  if (!components) {
    return null;
  }

  return {
    ...components,
    pos: {
      ...components?.pos,
      posService: {
        ...components?.pos?.posService,
        maintenanceBreaks: components?.pos?.posService?.maintenanceBreaks?.map(
          item => ({
            type: getTypeFromDatetime(item.startsAt),
            startsAt: formatDateTimeToISOString(item.startsAt),
            endsAt: formatDateTimeToISOString(item.endsAt)
          })
        )
      },
      ipos: {
        vatRates: components?.pos?.ipos?.vatRates
          ? Object.values(components.pos.ipos.vatRates).join(",")
          : ""
      }
    }
  };
};
