import { Flex } from "@chakra-ui/react";

import { Healthchecks } from "components/healthchecks/Healthchecks";
import { Property } from "components/property";
import { StoreAvailability } from "components/storeAvailability/StoreAvailability";

import { PosPaperState, StoreState as StoreStateType } from "../types";

type StoreStateProps = {
  storeState?: StoreStateType | null;
  posPaperState: PosPaperState | null;
};

export function StoreState({
  storeState,
  posPaperState
}: StoreStateProps): JSX.Element {
  return (
    <>
      <Flex gap="4rem">
        <Flex gap="1rem 4rem" wrap="wrap">
          <Property label="Dostępność" isLoading={storeState?.isLoadingState}>
            <StoreAvailability
              storeAvailability={storeState?.storeAvailability}
            />
          </Property>

          <Property label="Healthchecki">
            <Healthchecks
              healthchecks={storeState?.healthchecks}
              isLoadingHealthchecks={storeState?.isLoadingState}
              posPaperState={posPaperState?.posPaperState}
              isLoadingPosPaperState={posPaperState?.isLoadingPOSPaperState}
            />
          </Property>
        </Flex>

        <Property
          label="Ostatnia zmiana stanu"
          isLoading={storeState?.isLoadingState}
          minWidth="20rem"
        >
          {storeState?.stateUpdatedAt?.toLocaleString()}
        </Property>
      </Flex>
    </>
  );
}
