export const getValueByPath = (path: string, obj: { [key: string]: any }) => {
  const keys = path.split(".");
  let data = obj;

  for (const key of keys) {
    if (data === undefined || data === null) {
      return null;
    }
    data = data[key];
  }

  return data;
};
