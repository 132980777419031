import { useFormikContext, getIn } from "formik";

import { CustomDatePicker, TDate } from "./CustomDatePicker";
import { parseDate } from "./helpers";

type TProps = {
  isDetailedTime?: boolean;
  isTime?: boolean;
  isDate?: boolean;
  isYearVisible?: boolean;
  name: string;
  minDate?: TDate;
  maxDate?: TDate;
  isDisabled?: boolean;
};

export function FieldCustomDatePicker({
  isDetailedTime,
  isTime,
  isDate,
  isYearVisible,
  name,
  minDate,
  maxDate,
  isDisabled
}: TProps) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<{
      [key: string]: string;
    }>();

  return (
    <CustomDatePicker
      value={parseDate(getIn(values, name))}
      onChange={date => {
        setFieldValue(name, date?.toISOString());
        setFieldTouched(name, true);
        setFieldError(name, "");
      }}
      {...{
        name,
        isDetailedTime,
        isTime,
        isDate,
        isYearVisible,
        minDate,
        maxDate,
        isDisabled
      }}
    />
  );
}
