export enum ComponentStateValue {
  OK = "OK",
  NOT_OK = "NOT_OK"
}

export type ComponentsState = {
  aifiStoreOpen?: ComponentStateValue;
  posAvailability?: ComponentStateValue;
  posPaperState?: ComponentStateValue;
  terminalAvailability?: ComponentStateValue;
  infokioskAvailability?: ComponentStateValue;
  powerBouncerAvailability?: ComponentStateValue;
};

export enum StoreStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED"
}

export type StoreStateManual = {
  status: StoreStatus;
  changedBy: string;
  changeReason: string;
  changeType: "MAINTENANCE" | "WORKING_HOURS";
};

export type StoreState = {
  storeState: StoreStatus;
  manual?: StoreStateManual;
  workingHours: {
    scheduleState: "INSIDE_WORKING_HOURS" | "OUTSIDE_WORKING_HOURS";
    state: "INSIDE_WORKING_HOURS" | "OUTSIDE_WORKING_HOURS";
  };
  components: ComponentsState;
  revisionId: string;
  updatedAt: string;
  revisedAt: string;
};

export enum EStoreAvailability {
  OPEN = "OPEN",
  CLOSED_MAINTENANCE_MANUALLY = "CLOSED_MAINTENANCE_MANUALLY",
  CLOSED_OUTSIDE_WORKING_HOURS = "CLOSED_OUTSIDE_WORKING_HOURS",
  CLOSED_MAINTENANCE_COMPONENT = "CLOSED_MAINTENANCE_COMPONENT",
  CLOSED_OTHER_REASON = "CLOSED_OTHER_REASON"
}

export function getStoreAvailabilityState(
  storeState: StoreState
): EStoreAvailability {
  if (!storeState) return EStoreAvailability.CLOSED_OTHER_REASON;

  if (storeState.storeState === StoreStatus.OPEN) {
    return EStoreAvailability.OPEN;
  }

  if (storeState.manual?.status === StoreStatus.CLOSED) {
    if (storeState.manual?.changeType === "WORKING_HOURS") {
      return EStoreAvailability.CLOSED_OUTSIDE_WORKING_HOURS;
    }
    return EStoreAvailability.CLOSED_MAINTENANCE_MANUALLY;
  }

  if (storeState.workingHours?.state === "OUTSIDE_WORKING_HOURS") {
    return EStoreAvailability.CLOSED_OUTSIDE_WORKING_HOURS;
  }

  if (storeState.components) {
    const anyFailedComponent = Object.entries(storeState.components).find(
      ([, state]) => state === ComponentStateValue.NOT_OK
    );

    if (anyFailedComponent) {
      return EStoreAvailability.CLOSED_MAINTENANCE_COMPONENT;
    }
  }

  return EStoreAvailability.CLOSED_OTHER_REASON;
}
