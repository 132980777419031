export enum ESource {
  MOBILE_APP = "MOBILE_APP",
  INSTA = "INSTA",
  BACKOFFICE = "BACKOFFICE"
}

export enum EStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export enum ETrack {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC"
}

export enum ERefundIssuingStatus {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
  NONE = "NONE"
}

export enum ERefundRequest {
  ALL = "all",
  TRUE = "true",
  FALSE = "false"
}

export enum EQueryParams {
  CREATED_AFTER = "createdAfter",
  CREATED_BEFORE = "createdBefore",
  STATUS = "status",
  STORE_ID = "storeId",
  REFUND_ISSUING_STATUS = "refundIssuingStatus",
  SOURCE = "source",
  HAS_COUPON = "hasCoupon",
  CLIENT_EMAIL = "clientEmail",
  ORDER_AIFI_ID = "orderAifiId",
  CLIENT_LOYAL_ID = "clientLoyalId",
  MAX_RESULTS = "maxResults",
  OFFSET = "offset",
  COMPLAINT_ID = "complaintId",
  BARCODE = "barcode"
}

export enum EHelperQueryParams {
  SEARCH_KEY = "searchKey",
  SEARCH_VALUE = "searchValue"
}

export type TQueryParams = {
  [EQueryParams.CREATED_AFTER]?: string;
  [EQueryParams.CREATED_BEFORE]?: string;
  [EQueryParams.STATUS]?: EStatus;
  [EQueryParams.STORE_ID]?: string;
  [EQueryParams.REFUND_ISSUING_STATUS]?: ERefundIssuingStatus;
  [EQueryParams.SOURCE]?: ESource;
  [EQueryParams.HAS_COUPON]?: boolean;
  [EQueryParams.CLIENT_EMAIL]?: string;
  [EQueryParams.ORDER_AIFI_ID]?: number;
  [EQueryParams.CLIENT_LOYAL_ID]?: string;
  [EQueryParams.MAX_RESULTS]?: number;
  [EQueryParams.OFFSET]?: number;
  [EHelperQueryParams.SEARCH_KEY]: any;
  [EHelperQueryParams.SEARCH_VALUE]: string;
};

export enum EOrder {
  HAS_COUPON = "hasCoupon",
  AIFI_ID = "aifiId",
  CASH_AMOUNT = "cashAmount",
  CURRENCY = "currency"
}

export enum ECurrency {
  PLN = "PLN",
  EUR = "EUR"
}

export type TOrder = {
  [EOrder.HAS_COUPON]: boolean;
  [EOrder.AIFI_ID]: number;
  [EOrder.CASH_AMOUNT]: number;
  [EOrder.CURRENCY]: ECurrency;
};

export enum EComplaint {
  ID = "id",
  ORDER_ID = "orderId",
  ORDER = "order",
  ACCOUNT_ID = "accountId",
  STORE_ID = "storeId",
  USER_ID = "userId",
  LOYAL_ID = "loyalId",
  EMAIL = "email",
  SOURCE = "source",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  STATUS = "status",
  TRACK = "track",
  REFUND_ISSUING_STATUS = "refundIssuingStatus",
  CORRECTED_ORDER_CASH_AMOUNT = "correctedOrderCashAmount"
}

export type TComplaint = {
  [EComplaint.ID]: string;
  [EComplaint.ORDER_ID]: string;
  [EComplaint.ORDER]: TOrder;
  [EComplaint.ACCOUNT_ID]: string;
  [EComplaint.STORE_ID]: string;
  [EComplaint.USER_ID]: string;
  [EComplaint.LOYAL_ID]: string;
  [EComplaint.EMAIL]: string;
  [EComplaint.SOURCE]: ESource;
  [EComplaint.CREATED_AT]: string;
  [EComplaint.UPDATED_AT]: string;
  [EComplaint.STATUS]: EStatus;
  [EComplaint.TRACK]: ETrack;
  [EComplaint.REFUND_ISSUING_STATUS]: ERefundIssuingStatus;
  [EComplaint.CORRECTED_ORDER_CASH_AMOUNT]: number;
};

export enum EComplaintResponse {
  COMPLAINTS = "complaints",
  COUNT = "count",
  PAGE = "page",
  PAGE_OFFSET = "pageOffset",
  SIZE = "size",
  NEXT_PAGE_OFFSET = "nextPageOffset"
}

export type TComplaintsResponse = {
  [EComplaintResponse.COMPLAINTS]: TComplaint[];
  [EComplaintResponse.COUNT]: number;
  [EComplaintResponse.PAGE]: {
    [EComplaintResponse.PAGE_OFFSET]: number;
    [EComplaintResponse.SIZE]: number;
    [EComplaintResponse.NEXT_PAGE_OFFSET]: number;
  };
};
