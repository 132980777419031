import { ComponentStyleConfig } from "@chakra-ui/react";

const Text: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "body",
    color: "inherit"
  }
};

export default Text;
