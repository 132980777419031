import { Box, Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useStoreContext } from "contexts/StoreContext";
import StoreField from "pages/store/components/StoreField";
import { statesOptions, typesOptions } from "pages/store/consts";
import { EStore, TStore } from "types/configFields";

import { cardStyles } from "../../styles";

type TProps = {
  isEdit?: boolean;
  isCreate?: boolean;
};

const GeneralInfo = ({ isEdit, isCreate }: TProps) => {
  const { isLoading, storeChains, templateStore } = useStoreContext();
  const { values } = useFormikContext<TStore>();
  const templateState = templateStore?.state;
  const templateMpk = templateStore?.externalStoreId;
  const currentState = values["state"];

  const isSameState = templateState === currentState;

  return (
    <BorderedBox mt="0">
      <Flex {...cardStyles}>
        <StoreField
          isSimpleField
          name={EStore.NAME}
          fieldType={EFieldType.TEXT}
        />
        <StoreField
          isSimpleField
          name={EStore.STORE_ID}
          fieldType={EFieldType.TEXT}
          isDisabled={isEdit}
        />

        <StoreField
          isSimpleField
          name={isEdit || isCreate ? EStore.CHAIN_ID : EStore.CHAIN_NAME}
          fieldType={EFieldType.SELECT}
          isLoading={isLoading}
          options={storeChains.map((chain: any) => ({
            value: chain.chainId,
            label: chain.name
          }))}
          labelOverride={`${[EStore.CHAIN_NAME]}`}
        />
        <StoreField
          isSimpleField
          name={EStore.CHAIN_ID}
          fieldType={EFieldType.TEXT}
          isDisabled
        />

        <StoreField
          isSimpleField
          name={EStore.EXTERNAL_STORE_ID}
          fieldType={EFieldType.TEXT}
        />
        <StoreField
          isSimpleField
          name={EStore.TYPE}
          fieldType={EFieldType.SELECT}
          options={typesOptions}
        />
        <Box>
          <StoreField
            isSimpleField
            name={EStore.STATE}
            fieldType={EFieldType.SELECT}
            options={statesOptions}
          />
          {templateStore && !isSameState && (
            <Text fontSize="14px" mt="0.5rem" color="orange ">
              {`⚠️ Wybrana wartość różni się od wzorcowego sklepu (${templateMpk}: ${templateState})`}
            </Text>
          )}
        </Box>
        <StoreField
          isSimpleField
          name={EStore.ADDRESS}
          fieldType={EFieldType.TEXT}
        />
        <StoreField
          isSimpleField
          name={EStore.MANAGER_EMAIL}
          fieldType={EFieldType.TEXT}
        />
      </Flex>
    </BorderedBox>
  );
};

export default GeneralInfo;
