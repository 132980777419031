import { ChakraProps, Text } from "@chakra-ui/react";

type TProps = {
  search: string;
  text: string;
} & ChakraProps;

const FormattedText = ({ search, text, ...restProps }: TProps) => {
  const index = text.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) return <Text>{text}</Text>;

  const before = text.slice(0, index);
  const after = text.slice(index + search.length);

  return (
    <Text {...restProps}>
      {before}
      <Text as="span" fontWeight="bold">
        {text.slice(index, index + search.length)}
      </Text>
      {after}
    </Text>
  );
};

export default FormattedText;
