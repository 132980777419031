import { CloseStoreChangeType } from "api/closeStore";
import * as Yup from "yup";

import { ECloseStoreReport, TCloseStoreReport } from "./types";

const requiredMessage = "To pole jest wymagane";

export const reasons = {
  inventory: "Inwentaryzacja",
  fraud: "Fraud",
  routineService: "Rutynowy serwis sklepu",
  storeFailure: "Awaria sklepu",
  storeTests: "Testy sklepu",
  plannedWorks: "Remodeling/prace planowe",
  locationUnavailable: "Niedostępność lokalizacji, w której jest sklep",
  endOfHours: "Koniec godzin pracy",
  other: "Inny"
};

export const reasonsOptions = Object.entries(reasons).map(([value, label]) => ({
  value,
  label
}));

export const reasonsOptionsForWorkingHours = reasonsOptions.filter(item =>
  ["other", "endOfHours"].includes(item.value)
);

export const initialValues: TCloseStoreReport = {
  [ECloseStoreReport.CHANGE_TYPE]: CloseStoreChangeType.MAINTENANCE,
  [ECloseStoreReport.REASON]: "",
  [ECloseStoreReport.REASON_DESCRIPTION]: ""
};

export const storeCloseReasons: Record<CloseStoreChangeType, string> = {
  [CloseStoreChangeType.MAINTENANCE]: "Przerwa serwisowa",
  [CloseStoreChangeType.WORKING_HOURS]: "Koniec godzin pracy sklepu"
};

export const validationSchema = Yup.object().shape({
  [ECloseStoreReport.CHANGE_TYPE]: Yup.string().required(requiredMessage),
  [ECloseStoreReport.REASON]: Yup.string().required(requiredMessage),
  [ECloseStoreReport.REASON_DESCRIPTION]: Yup.string().when(
    ECloseStoreReport.REASON,
    {
      is: "other",
      then: schema => schema.required(requiredMessage)
    }
  )
});
