import React from "react";
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react";
import { useField } from "formik";

interface RadioButtonsProps {
  name: string;
  label?: string;
  buttons: React.ReactElement[];
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  name,
  label,
  buttons
}) => {
  const [field, meta, { setValue }] = useField(name);

  const handleClick = (value: string) => {
    setValue(value);
  };

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Stack direction="row" spacing={4}>
        {buttons.map((button, index) =>
          React.cloneElement(button, {
            key: index,
            onClick: () => {
              if (button.props.onClick) {
                button.props.onClick();
              }

              handleClick(button.props.value);
            },
            variant: field.value === button.props.value ? "solid" : "outline"
          })
        )}
      </Stack>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default RadioButtons;
