import { Text } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import { headers } from "../../labels";
import { headerStyles } from "../../styles";

import Adyen from "./Adyen";
import Aifi from "./Aifi";
import Infokiosk from "./Infokiosk";
import Pos from "./Pos";
import PowerBouncer from "./PowerBouncer";

const Components = () => {
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    mt: "0"
  } as const;

  return (
    <>
      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.AIFI}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.AIFI}`]}
        </Text>
        <Aifi />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.ADYEN}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.ADYEN}`]}
        </Text>
        <Adyen />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]}
        </Text>
        <Infokiosk />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.POS}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.POS}`]}
        </Text>
        <Pos />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]}
        </Text>
        <PowerBouncer />
      </BorderedBox>

      <BorderedBox {...sectionStyles}>
        <Text
          data-searchid={`${EStore.COMPONENTS}.${EComponent.TOTEM}`}
          {...headerStyles}
        >
          {headers[`${EStore.COMPONENTS}.${EComponent.TOTEM}`]}
        </Text>
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${EStore.COMPONENTS}.${EComponent.TOTEM}.${EComponentProperty.AIFI_ZONE_ID}`}
        />
      </BorderedBox>
    </>
  );
};

export default Components;
