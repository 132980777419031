import * as Yup from "yup";

import {
  EComplaintPreview,
  EPrice,
  EProducts
} from "pages/complaint/methods/useGetComplaint/types";

import {
  EComplaintDetails,
  EProcess
} from "../../methods/useSendComplaintsReview/types";

const errorMessage = "Pole jest wymagane";

const refoundSchema = Yup.object().shape({
  [EComplaintDetails.REFUND_AMOUNT]: Yup.string().required(errorMessage)
});
const reviewApprovedSchema = Yup.object().shape({
  [EComplaintPreview.PRODUCTS]: Yup.array()
    .required(errorMessage)
    .of(
      Yup.object().shape({
        [EProducts.DISPLAY_NAME]: Yup.string().required(errorMessage),
        [EProducts.BARCODE]: Yup.string().required(errorMessage),
        [EProducts.QUANTITY]: Yup.string().required(errorMessage),
        [EProducts.PRICE]: Yup.object().shape({
          [EPrice.TOTAL]: Yup.string().required(errorMessage),
          [EPrice.CASH]: Yup.string().required(errorMessage),
          [EPrice.LOYALTY_POINTS]: Yup.string().required(errorMessage)
        })
      })
    )
});
const reviewRejectedSchema = Yup.object().shape({});

export const getSchema = (currentProcess?: EProcess) => {
  if (currentProcess === EProcess.REFUND) return refoundSchema;
  if (currentProcess === EProcess.APPROVED) return reviewApprovedSchema;
  if (currentProcess === EProcess.REJECTED) return reviewRejectedSchema;
  return Yup.object().shape({});
};
