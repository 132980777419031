import { ECurrency } from "pages/complaint/methods/useGetComplaint/types";
import {
  EUserOrderDetails,
  EUserOrderProperties,
  TUserOrderDetails
} from "pages/complaint/methods/useGetUserOrderDetails/types";

export const userOrderDetailsMock: TUserOrderDetails = {
  [EUserOrderDetails.ID]: "c7e994f9-240e-4031-8450-8da78cae958d",
  [EUserOrderDetails.FISCAL_RECEIPT]: {
    [EUserOrderProperties.TRANSACTION_NUMBER]: "123"
  },
  [EUserOrderDetails.CREATED_AT]: "2021-08-24T14:15:22Z",
  [EUserOrderDetails.PRICE]: {
    [EUserOrderProperties.ORIGINAL]: {
      [EUserOrderProperties.TOTAL]: 200,
      [EUserOrderProperties.CASH]: 300,
      [EUserOrderProperties.CURRENCY]: ECurrency.PLN
    }
  },
  [EUserOrderDetails.AIFI_SESSION_ID]: "eb4768da-6bec-4eaa-bd38-7048a6139547"
};
