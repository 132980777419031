import {
  EProductComplaintType,
  EProductInvalidPriceProblem,
  EProductProblemProblem
} from "pages/complaint/methods/useGetComplaint/types";

export const productComplaintLabels = {
  [EProductComplaintType.INVALID_QUANTITY]: "Zła liczba sztuk na rachunku",
  [EProductComplaintType.PRODUCT_PROBLEM]: "Problem z Produktem",
  [EProductComplaintType.INVALID_PRICE]: "Niepoprawna cena",
  [EProductComplaintType.OTHER]: "Coś innego"
};

export const productPriceLabels = {
  [EProductInvalidPriceProblem.DIFF_THEN_PRICE_TAG]: "Cena innna niż na półce",
  [EProductInvalidPriceProblem.DIFF_THEN_PROMOTION]: "Cena inna niż w promocji",
  [EProductInvalidPriceProblem.MULTI_PROMOTION_NOT_APPLIED]:
    "Nie naliczyła się promocja na wielosztuki"
};
export const productAffectedLabels = {
  [EProductProblemProblem.DAMAGED]: "Uszkodzony",
  [EProductProblemProblem.EXPIRED]: "Przeterminowany",
  [EProductProblemProblem.INVALID_PREPARATION]: "Źle przygotowany"
};
