import { useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { AppConfigKeysType } from "api/listAppConfigKeys";
import { Link } from "react-router-dom";

import { DataNotReadyAlert } from "components/alerts/Alerts";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import { TableBottomSection } from "components/tableBottomSection/TableBottomSection";
import { farLeftCellStyle, farRightCellStyle } from "utils/commonStyles";

import { TopSection } from "./components/TopSection";
import {
  ANY_KEY_TYPE_KEYWORD,
  useAppConfigKeysData
} from "./useAppConfigKeysData";

export function AppConfigKeys(): JSX.Element {
  const {
    appConfigKeys,
    error,
    fetchMoreKeys,
    filterAppConfigKeys,
    isLoading,
    isMoreKeys
  } = useAppConfigKeysData();

  const [searchQuery, setSearchQuery] = useState(ANY_KEY_TYPE_KEYWORD);

  return (
    <>
      <TopSection
        fetchMethod={filterAppConfigKeys}
        {...{ searchQuery, setSearchQuery }}
      />

      {error ? (
        // TODO: both alerts can be useful to distinguish kind of errors - uncomment when ready
        // (
        //   <FailedFetchingAlert
        //     mt="30px"
        //     message="Nieudane pobieranie listy kluczy"
        //   />
        // )
        <DataNotReadyAlert
          mt="30px"
          message="Nieudane pobieranie listy kluczy"
          onClick={() => filterAppConfigKeys(searchQuery as AppConfigKeysType)}
        />
      ) : (
        <BorderedBox>
          {!!appConfigKeys?.length && (
            <TableContainer maxWidth="100vw" overflowX="auto">
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th {...farLeftCellStyle} lineHeight="2rem">
                      ID
                    </Th>
                    <Th>Nazwa</Th>
                    <Th>Typ</Th>
                    <Th>Ostatnia aktualizacja</Th>
                    <Th {...farRightCellStyle} />
                  </Tr>
                </Thead>
                <Tbody>
                  {appConfigKeys.map(storeappConfigKey => (
                    <Tr key={storeappConfigKey.id} height="60px">
                      <Td {...farLeftCellStyle}>{storeappConfigKey.id}</Td>
                      <Td>{storeappConfigKey.name}</Td>
                      <Td>{storeappConfigKey.type}</Td>
                      <Td>{storeappConfigKey.updatedAt.toLocaleString()}</Td>
                      <Td {...farRightCellStyle}>
                        <Button as={Link} to={storeappConfigKey.id}>
                          Więcej
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          <TableBottomSection
            isMore={isMoreKeys}
            isLoading={isLoading}
            noData={!appConfigKeys.length}
            fetchMore={fetchMoreKeys}
            entityName="kluczy konfiguracyjnych"
          />
        </BorderedBox>
      )}
    </>
  );
}
