import { EUnavailableStoreReport, TUnavailableStoreReport } from "./types";

export const reasons = {
  inventory: "Inwentaryzacja (Dodana wstecznie)",
  fraud: "Fraud (Dodana wstecznie)",
  routineService: "Rutynowy serwis sklepu (Dodana wstecznie)",
  storeFailure: "Awaria sklepu (Dodana wstecznie)",
  storeTests: "Testy sklepu (Dodana wstecznie)",
  plannedWorks: "Remodeling/prace planowe (Dodana wstecznie)",
  locationUnavailable:
    "Niedostępność lokalizacji, w której jest sklep (Dodana wstecznie)",
  endOfHours: "Koniec godzin pracy (Dodana wstecznie)",
  other: "Inny"
};

export const reasonsOptions = Object.entries(reasons).map(([value, label]) => ({
  value,
  label
}));

export const initialValues: TUnavailableStoreReport = {
  [EUnavailableStoreReport.STARTS_AT]: null,
  [EUnavailableStoreReport.ENDS_AT]: null,
  [EUnavailableStoreReport.REASON]: "",
  [EUnavailableStoreReport.REASON_TYPE]: ""
};
