import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { Form, useFormikContext, getIn } from "formik";
import { useNavigate } from "react-router-dom";

import FormField, { EFieldType } from "components/formField/FormField";
import FieldGenerationContextProvider from "contexts/FieldGenerationContext";
import { fieldLabels, sensitiveDataFields } from "pages/appConfigKey/constants";
import { getKeyFields } from "pages/appConfigKey/helpers";
import { availableAppConfigKeyTypes } from "pages/appConfigKeys/constants";

import { FormDataType } from "../../types";

import { EditableField } from "./EditableField";

type FieldsProps = {
  isLoading?: boolean;
  disabledFields?: string[];
};

export function Fields({ isLoading, disabledFields }: FieldsProps) {
  const navigate = useNavigate();
  const { values, errors } = useFormikContext<FormDataType>();

  const handleGoBack = () => navigate(-1);

  return (
    <Form>
      <FieldGenerationContextProvider labels={fieldLabels}>
        <Flex direction="column" gap="1rem" mb="1rem" maxW="40rem">
          <FormField
            labelSize="1rem"
            fieldType={EFieldType.TEXT}
            name="id"
            isDisabled={disabledFields?.includes("id")}
          />
          <FormField labelSize="1rem" fieldType={EFieldType.TEXT} name="name" />
          <FormField
            labelSize="1rem"
            name="key.type"
            labelOverride="type"
            fieldType={EFieldType.SELECT}
            options={availableAppConfigKeyTypes.map(item => ({
              value: item,
              label: item
            }))}
            isDisabled={disabledFields?.includes("key.type")}
          />

          {getKeyFields(values.key.type).map(field => {
            if (sensitiveDataFields.includes(field)) {
              return (
                <EditableField
                  labelSize="1rem"
                  key={field}
                  name={`key.${field}`}
                  label={fieldLabels[field]}
                  errorMessage={getIn(errors, `key.${field}`)}
                  placeholder={getIn(values, `key.${field}Mask`)}
                />
              );
            }

            return (
              <FormField
                labelSize="1rem"
                fieldType={EFieldType.TEXT}
                key={field}
                name={`key.${field}`}
              />
            );
          })}

          <Flex gap="1rem" w="full" justifyContent="flex-end">
            <Button
              onClick={handleGoBack}
              leftIcon={<CloseIcon />}
              minW="6rem"
              mt="2rem"
              alignSelf="flex-end"
            >
              Anuluj
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              leftIcon={<CheckIcon />}
              variant="solid"
              minW="6rem"
              mt="2rem"
              alignSelf="flex-end"
            >
              Zapisz
            </Button>
          </Flex>
        </Flex>
      </FieldGenerationContextProvider>
    </Form>
  );
}
