import { CloseStoreChangeType } from "api/closeStore";

export enum ECloseStoreReport {
  CHANGE_TYPE = "changeType",
  REASON = "reason",
  REASON_DESCRIPTION = "reasonDescription"
}
export type TCloseStoreReport = {
  [ECloseStoreReport.CHANGE_TYPE]: CloseStoreChangeType;
  [ECloseStoreReport.REASON]: string;
  [ECloseStoreReport.REASON_DESCRIPTION]: string;
};
