import {
  EPrice,
  EProducts,
  TProduct
} from "pages/complaint/methods/useGetComplaint/types";
import { TComplaintRefound } from "pages/complaint/methods/useSendComplaintsRefound/types";

import {
  EComplaintDetails,
  EComplaintResult,
  TComplaintApproved,
  TComplaintRejected
} from "../../methods/useSendComplaintsReview/types";

export const emptyProduct: TProduct = {
  [EProducts.DISPLAY_NAME]: "",
  [EProducts.BARCODE]: "",
  [EProducts.QUANTITY]: 0,
  [EProducts.PRICE]: {
    [EPrice.TOTAL]: 0,
    [EPrice.CASH]: 0,
    [EPrice.LOYALTY_POINTS]: 0
  }
};

export const initialRefoundValues: TComplaintRefound = {
  [EComplaintDetails.REVIEWED_BY]: "",
  [EComplaintDetails.REFUND_AMOUNT]: ""
};

export const initialApprovedValues: TComplaintApproved = {
  [EComplaintDetails.RESULT]: EComplaintResult.APPROVED,
  [EComplaintDetails.REVIEWED_BY]: "",
  [EComplaintDetails.REVIEWED_PRODUCTS]: []
};

export const initialRejectedValues: TComplaintRejected = {
  [EComplaintDetails.RESULT]: EComplaintResult.REJECTED,
  [EComplaintDetails.REVIEWED_BY]: "",
  [EComplaintDetails.REASON]: ""
};

export const headLabels = [
  { key: `product`, value: "Produkt" },
  {
    key: `${EProducts.DISPLAY_NAME}`,
    value: "Nazwa produktu"
  },
  { key: `${EProducts.BARCODE}`, value: "EAN produktu" },
  {
    key: `${EProducts.PRICE}.${EPrice.TOTAL}`,
    value: "Cena produktu"
  },
  {
    key: `${EProducts.PRICE}.${EPrice.CASH}`,
    value: "Kwota pobrana od klienta"
  },
  {
    key: `${EProducts.PRICE}.${EPrice.LOYALTY_POINTS}`,
    value: "Naliczone żappsy"
  },
  { key: `${EProducts.QUANTITY}`, value: "Liczba sztuk" }
];
