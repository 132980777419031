import { useCallback, useState } from "react";

export function useSearch(): {
  searchValue: string;
  changeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  setSearchValue: (value: string) => void;
} {
  const [searchValue, setSearchValue] = useState<string>("");

  const changeSearchValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchValue(event.target.value),
    []
  );

  const clearSearch = useCallback(() => {
    setSearchValue("");
  }, [setSearchValue]);

  return {
    searchValue,
    changeSearchValue,
    clearSearch,
    setSearchValue
  };
}
