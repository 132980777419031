import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Field, Formik } from "formik";

import { StoreStatus } from "../../types";

import { useStoreOpen } from "./useStoreOpen";

type OpenStoreModalProps = {
  onStateChange: (expectedState: StoreStatus, callback: () => void) => void;
  isLoadingState: boolean;
};

export function OpenStoreModal({
  onStateChange,
  isLoadingState
}: OpenStoreModalProps): JSX.Element {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure();

  const { openStoreWithToast, isLoading } = useStoreOpen(
    closeModal,
    onStateChange
  );

  return (
    <>
      <Button onClick={openModal} isLoading={isLoadingState} variant="solid">
        Otwórz sklep
      </Button>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Otwieranie sklepu</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              changeReason: "",
              complete: false
            }}
            onSubmit={values => {
              openStoreWithToast(values.changeReason, values.complete);
            }}
            validateOnBlur={false}
            validateOnChange={true}
          >
            {({ handleSubmit, errors, touched, isValid, dirty }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormControl
                    isInvalid={!!errors.changeReason && touched.changeReason}
                  >
                    <FormLabel htmlFor="changeReason">
                      Powód otwarcia sklepu
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="changeReason"
                      name="changeReason"
                      autoFocus={true}
                      validate={(value: string) =>
                        !value ? "Powód otwarcia jest wymagany" : undefined
                      }
                    />
                    <FormErrorMessage>{errors.changeReason}</FormErrorMessage>
                  </FormControl>

                  <FormControl mt="1.5rem">
                    <Field as={Checkbox} id="complete" name="complete">
                      Pełne otwarcie
                    </Field>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button mr="1rem" onClick={closeModal}>
                    Anuluj
                  </Button>
                  <Button
                    colorScheme="green"
                    variant="solid"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!dirty || !isValid}
                  >
                    Otwórz sklep
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}
