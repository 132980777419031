import { Flex } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import { cardStyles } from "pages/store/styles";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import MonitorFields from "./MonitorFields";
import StoreChainField from "./StoreChainField";

const Infokiosk = () => {
  const keyPart = `${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.HEARTBEAT_THRESHOLD}`}
        />
      </Flex>

      <Flex w="full" gap="1rem">
        <MonitorFields
          title="Konfiguracja monitora dostępności Infokiosku"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
        />
      </Flex>
    </>
  );
};

export default Infokiosk;
