import { useCallback, useState } from "react";
import { getIn, useFormikContext } from "formik";

import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { useStoreContext } from "contexts/StoreContext";
import { responseMapper as chainResponseMapper } from "pages/storeChain/mappers/response";
import { EStore, TStore } from "types/configFields";

interface IUseActiveValue {
  name: string;
}

const useActiveValue = ({ name }: IUseActiveValue) => {
  const { isReadOnly, isCreate, isEdit } = useFieldGenerationContext();
  const { values, touched, initialValues } = useFormikContext<TStore>();
  const { storeChains, templateStore } = useStoreContext();

  const value = getIn(values, name);

  const [shouldUseChainValue, setShouldUseChainValue] = useState(false);
  const [savedStoreValue, setSavedStoreValue] = useState<string | any[]>(value);

  const initialValue = getIn(initialValues, name);
  const storeChainId = getIn(values, EStore.CHAIN_ID);
  const currentChain =
    storeChains.find(chain => chain.chainId === storeChainId) || null;
  const mappedChain = chainResponseMapper(currentChain);
  const storeChainValue = getIn(mappedChain, name);

  const hasTemplateStore = Boolean(templateStore);
  const isTouched = getIn(touched, name);
  const isValueDefined = value !== undefined && value !== null && value !== "";
  const isStoreChainValueDefined =
    storeChainValue !== undefined &&
    storeChainValue !== null &&
    storeChainValue !== "";

  const getActiveValue = (): "store" | "chain" | undefined => {
    if (shouldUseChainValue) {
      return "chain";
    }

    // Store config VIEW
    if (isReadOnly) {
      if (isValueDefined) {
        return "store";
      }

      if (isStoreChainValueDefined) {
        return "chain";
      }

      return undefined;
    }

    // Store config CREATE
    if (isCreate) {
      const hasValueChanged = value !== initialValue;

      if (!isValueDefined && isStoreChainValueDefined) {
        return "chain";
      }

      if (isValueDefined) {
        if (hasTemplateStore) {
          return "store";
        } else {
          if (isStoreChainValueDefined) {
            return isTouched && hasValueChanged ? "store" : "chain";
          } else {
            return isTouched && hasValueChanged ? "store" : undefined;
          }
        }
      }
    }

    // Store config EDIT
    if (isEdit) {
      if (isValueDefined) {
        return "store";
      } else {
        if (isStoreChainValueDefined) {
          return "chain";
        } else {
          return undefined;
        }
      }
    }

    return undefined;
  };

  const getStoreBadgeClickability = useCallback(() => {
    if (isReadOnly) {
      return "default";
    }

    if (isValueDefined && isTouched && !shouldUseChainValue) {
      return "not-allowed";
    }

    return "pointer";
  }, [isReadOnly, isTouched, isValueDefined, shouldUseChainValue]);

  const getStoreChainBadgeClickability = useCallback(() => {
    if (isReadOnly) {
      return "default";
    }

    if (!isStoreChainValueDefined) {
      return "not-allowed";
    }

    if (!shouldUseChainValue && isValueDefined) {
      return "pointer";
    }

    if (isStoreChainValueDefined) {
      if ((isTouched && shouldUseChainValue) || !isTouched) {
        return "not-allowed";
      }
    }

    return "default";
  }, [
    isReadOnly,
    isStoreChainValueDefined,
    isTouched,
    isValueDefined,
    shouldUseChainValue
  ]);

  return {
    value,
    storeChainValue,
    shouldUseChainValue,
    setShouldUseChainValue,
    savedStoreValue,
    setSavedStoreValue,
    getActiveValue,
    getStoreBadgeClickability,
    getStoreChainBadgeClickability
  };
};

export default useActiveValue;
