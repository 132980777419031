// API/SSD/005

import { API_ROOT } from "consts";

export function closeStoreUrl(storeId: string) {
  return new URL(`${API_ROOT}/stores/${storeId}/state/close`);
}

export enum CloseStoreChangeType {
  MAINTENANCE = "MAINTENANCE",
  WORKING_HOURS = "WORKING_HOURS"
}

type CloseStorePayload = {
  userId: string;
  changeType: CloseStoreChangeType;
  reason: string;
};

export type CloseStoreResponse = {
  revisionId: string;
};

export function prepareCloseStorePayload(
  username: string,
  changeType: CloseStoreChangeType,
  reason: string
): CloseStorePayload {
  return { userId: username, changeType, reason };
}
