import { AppConfigKey, AppConfigKeysType } from "api/listAppConfigKeys";

import { dateFormat } from "utils/dateFormat";

export const getKeyFields = (type: AppConfigKeysType) => {
  switch (type) {
    case "AIFI":
      return ["apiKey", "baseUrl", "token"];
    case "ADYEN":
      return ["apiKey", "terminalUrl"];
    case "INFOKIOSK":
    case "ERP":
    case "SHORTENER":
    case "SYNERISE":
      return ["apiKey", "baseUrl"];
    case "POS":
      return ["apiKey", "posServiceUrl"];
    case "IO_CONTROLLER":
      return ["baseUrl"];
    default:
      return [];
  }
};

export const responseMapper = (data: AppConfigKey) => {
  const { createdAt, updatedAt, ...restdata } = data;

  return {
    createdAt: dateFormat(createdAt),
    updatedAt: dateFormat(updatedAt),
    ...restdata
  };
};
