import { reasons } from "./consts";
import { TUnavailableStoreReport, EUnavailableStoreReport } from "./types";

export const payloadMapper = (values: TUnavailableStoreReport) => {
  const {
    [EUnavailableStoreReport.REASON]: reason,
    [EUnavailableStoreReport.REASON_TYPE]: reasonType,
    ...restValues
  } = values;

  return {
    ...restValues,
    [EUnavailableStoreReport.REASON]:
      reasonType === "other"
        ? reason
        : reasons[reasonType as keyof typeof reasons]
  };
};
