import { useEffect } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { AppConfigKeysType } from "api/listAppConfigKeys";
import { Link } from "react-router-dom";

import { PathParts } from "routes";
import { QuerySelect } from "components/table/filter/QuerySelect";

import { availableAppConfigKeyTypes } from "../constants";
import {
  ANY_KEY_TYPE_KEYWORD,
  AppConfigKeysFilterType
} from "../useAppConfigKeysData";

const options = [
  {
    value: ANY_KEY_TYPE_KEYWORD,
    label: "Pokaż wszystkie"
  },
  ...availableAppConfigKeyTypes.map(keyType => ({
    value: keyType,
    label: keyType
  }))
];

type TProps = {
  fetchMethod: (type: AppConfigKeysFilterType) => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
};

export function TopSection({
  fetchMethod,
  searchQuery,
  setSearchQuery
}: TProps) {
  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    fetchMethod(searchQuery as AppConfigKeysType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Flex justifyContent="space-between">
      <QuerySelect
        value={searchQuery}
        onChange={onSelectChange}
        options={options}
        width="18.75rem"
      />
      <Button
        as={Link}
        leftIcon={<AddIcon />}
        to={PathParts.CREATE}
        p="0 15px"
        bg="white"
      >
        Nowy klucz
      </Button>
    </Flex>
  );
}
