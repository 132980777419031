import { useToast } from "@chakra-ui/react";
import { getConfigKeyByIdUrl } from "api/appConfigKey";
import { useLocation, useNavigate } from "react-router-dom";

import { commonToastSetup } from "consts";
import { Routes } from "routes";
import { useFetchRefactored } from "hooks/useFetchRefactored";

export const useDeleteAppConfigKey = (keyId: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const deleteFetch = useFetchRefactored({
    url: getConfigKeyByIdUrl(keyId),
    method: "DELETE"
  });

  const fetchData = async () => {
    const { error } = await deleteFetch.fetchData();

    if (error) {
      toast({
        title: "Błąd",
        description: `Nie udało się usunąć klucza ${keyId}`,
        status: "error",
        ...commonToastSetup
      });
    } else {
      toast({
        title: "Sukces",
        description: `Usunięto klucz ${keyId}`,
        status: "success",
        ...commonToastSetup
      });

      const isOnListPage = location.pathname === `${Routes.APP_CONFIG_KEYS}/`;

      if (isOnListPage) {
        navigate(0);
      } else {
        navigate(`${Routes.APP_CONFIG_KEYS}/`);
      }
    }
  };

  return { ...deleteFetch, fetchData };
};
