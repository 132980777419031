import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { Field } from "formik";

export type CustomInputProps = {
  isDisabled?: boolean;
  isMasked?: boolean;
  isTouched?: boolean;
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
};

export function CustomInput({
  isDisabled,
  isMasked,
  name,
  placeholder,
  type
}: CustomInputProps) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(prevIsVisible => !prevIsVisible);

  return (
    <InputGroup>
      <Field
        type={type || (isMasked && !isVisible ? "password" : "text")}
        placeholder={placeholder}
        as={Input}
        name={name}
        disabled={isDisabled}
      />
      {isMasked && (
        <InputRightElement>
          <IconButton
            onClick={toggleVisibility}
            backgroundColor="gray.medium"
            icon={isVisible ? <ViewOffIcon /> : <ViewIcon />}
            variant="solid"
            h="2rem"
            w="2rem"
            aria-label="toggle"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
