import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text
} from "@chakra-ui/react";
import { StoreChainConfigCreate } from "api/common/storeChainConfig";
import { FieldArray, useFormikContext } from "formik";
import isString from "lodash/isString";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import {
  cardIssuerActionTypeOptions,
  defaultChannelOptions,
  emptyCardIssuerAction,
  emptyPrefixConfig,
  getSmsFields,
  prefixChannelOptions,
  smsOptions
} from "pages/store/consts";
import {
  noDataStyles,
  stickyHeadingStyles,
  tilesContainerStyles,
  tileStyles
} from "pages/store/styles";
import {
  EParameters,
  EParameterProperty,
  EStore,
  EParameterSmsLanguage,
  ECLientPriority,
  PaymentMethodFields,
  EPrefixConfigFields,
  EPrefixFields,
  ECardIssuerActions,
  EIssuerActionType,
  EIssuerActionProperty
} from "types/configFields";

import DailyPeriodRules from "../components/DailyPeriodRules";
import OrdersRules from "../components/OrdersRules";
import PeriodRules from "../components/PeriodRules";
import StoreChainField from "../components/StoreChainField";
import StoreChainFieldArray from "../components/StoreChainFieldArray";
import {
  complaintHandlerOptions,
  emptyPaymentMethod,
  emptySmsLanguage,
  miniAccountTypeOptions,
  purchaseConfirmationDocTypesOptions,
  sectionHeaderStyles
} from "../consts";
import { headers } from "../labels";

const Parameters = () => {
  const { values, errors, setFieldValue } =
    useFormikContext<StoreChainConfigCreate>();
  const { isReadOnly } = useFieldGenerationContext();
  const smsLanguages = values?.parameters?.smsLanguages;
  const retryPaymentMethods = values?.parameters?.retryPaymentMethods;
  const smsLanguagesError = errors.parameters?.smsLanguages;
  const prefixes = values?.parameters?.sms?.prefixConfig?.prefixes || [];

  const cardIssuerActionsKey = `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`;

  const hasSmsLanugagesError = Boolean(
    smsLanguagesError && isString(smsLanguagesError)
  );

  const ordersRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.ORDERS_RULES
    ] || [];
  const dailyPeriodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.DAILY_PERIOD_RULES
    ] || [];
  const periodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.PERIOD_RULES
    ] || [];

  return (
    <AccordionItem id="parameters">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Parametry konfiguracyjne sieci sklepów
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja dotycząca dokumentu potwierdzającego dokonanie zakupów,
            wysyłanego do klienta
          </Text>
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`}
            options={purchaseConfirmationDocTypesOptions}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`}
          />
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.MINI_ACCOUNT_TYPE}`}
            options={miniAccountTypeOptions}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.CURRENCY}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`}
            {...sectionHeaderStyles}
          >
            Dane dotyczące aktywacji konta (w procesie na kartę)
          </Text>
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`}
            {...sectionHeaderStyles}
          >
            Lista języków komunikacji wraz z przyporządkowanymi prefixami
            numerów telefonów
          </Text>
          <FormControl isInvalid={hasSmsLanugagesError} position="static">
            <FieldArray
              name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`}
            >
              {({ push, remove }) => (
                <>
                  {!isReadOnly && (
                    <Button
                      onClick={() => push(emptySmsLanguage)}
                      variant="ghost"
                      leftIcon={<CirclePlusIcon size="1rem" />}
                      mb="1rem"
                      justifyContent="flex-start"
                    >
                      Dodaj nowy język
                    </Button>
                  )}

                  <Flex
                    {...tilesContainerStyles}
                    mb="1rem"
                    borderColor={hasSmsLanugagesError ? "red" : "transparent"}
                  >
                    {smsLanguages && smsLanguages.length > 0 ? (
                      smsLanguages.map((_, index) => (
                        <Flex {...tileStyles} mt="0" key={index}>
                          <StoreChainField
                            isSimpleField
                            fieldType={EFieldType.TEXT}
                            name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.${index}.${EParameterSmsLanguage.PREFIX}`}
                            labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.0.${EParameterSmsLanguage.PREFIX}`}
                          />
                          <StoreChainField
                            isSimpleField
                            fieldType={EFieldType.TEXT}
                            name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.${index}.${EParameterSmsLanguage.LANGUAGE}`}
                            labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.0.${EParameterSmsLanguage.LANGUAGE}`}
                          />
                          {!isReadOnly && (
                            <Button
                              onClick={() => remove(index)}
                              variant="ghost"
                              colorScheme="red"
                              color="red.dark"
                              width="min-content"
                              marginTop="auto"
                              marginLeft="auto"
                              leftIcon={<Trash2 size="1rem" />}
                            >
                              Usuń
                            </Button>
                          )}
                        </Flex>
                      ))
                    ) : (
                      <Text {...noDataStyles}>Brak języków komunikacji</Text>
                    )}
                  </Flex>
                  <FormErrorMessage>
                    {smsLanguagesError &&
                      isString(smsLanguagesError) &&
                      smsLanguagesError}
                  </FormErrorMessage>
                </>
              )}
            </FieldArray>
          </FormControl>
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`}
            fontSize="1rem"
            fontWeight="bold"
            mb="1rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`
              ]
            }
          </Text>
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.DEFAULT_CHANNEL}`}
            options={defaultChannelOptions}
          />

          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`}
            fontSize="0.8rem"
            fontWeight="bold"
            mb="1rem"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`
              ]
            }
          </Text>

          <FieldArray
            name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push(emptyPrefixConfig)}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    mb="1rem"
                  >
                    Dodaj nową konfigurację prefixów
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb="2em">
                  {prefixes.length > 0 ? (
                    prefixes.map((_, index) => (
                      <Flex {...tileStyles} mt="0" key={index}>
                        <StoreChainField
                          isSimpleField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.PREFIX}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.PREFIX}`}
                        />
                        <StoreChainField
                          isSimpleField
                          fieldType={EFieldType.SELECT}
                          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.CHANNEL}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.CHANNEL}`}
                          options={prefixChannelOptions}
                        />

                        {!isReadOnly && (
                          <Button
                            onClick={() => remove(index)}
                            variant="ghost"
                            colorScheme="red"
                            color="red.dark"
                            width="min-content"
                            marginTop="auto"
                            marginLeft="auto"
                            leftIcon={<Trash2 size="1rem" />}
                          >
                            Usuń
                          </Button>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Text {...noDataStyles}>Brak konfiguracji prefixów</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="1rem"
          >
            Konfiguracja wysyłki wiadomości SMS w danej sieci sklepów
          </Text>
          <Flex gap="1rem" columnGap="3rem" flexWrap="wrap">
            {getSmsFields().map(({ smsKey, thresholdKey }) => (
              <BorderedBox
                minWidth="20rem"
                maxWidth="20rem"
                key={smsKey}
                display="flex"
                flexDir="column"
                gap="2rem"
                border={thresholdKey ? undefined : "none"}
              >
                <StoreChainField
                  fieldType={EFieldType.SELECT}
                  name={`${EStore.PARAMETERS}.${EParameters.SMS}.${smsKey}`}
                  options={smsOptions[smsKey]}
                />
                {thresholdKey && (
                  <StoreChainField
                    fieldType={EFieldType.TEXT}
                    name={`${EStore.PARAMETERS}.${EParameters.SMS}.${thresholdKey}`}
                  />
                )}
              </BorderedBox>
            ))}
          </Flex>
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="1rem"
          >
            Konfiguracja importu produktów
          </Text>
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.IMPORT_IMAGES_FROM_PIM}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.AIFI_IMPORT_ENABLED}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text fontSize="1rem" fontWeight="bold" m="0" mb="1rem">
            {headers[cardIssuerActionsKey]}
          </Text>

          <StoreChainFieldArray
            name={cardIssuerActionsKey}
            addButtonText="Dodaj nową konfigurację akcji"
            emptyArrayText="Brak konfiguracji akcji"
            emptyItem={emptyCardIssuerAction}
            renderComponent={(item, index) => (
              <>
                <StoreChainField
                  isSimpleField
                  fieldType={EFieldType.TEXT}
                  name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BINS}`}
                  labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.BINS}`}
                  options={cardIssuerActionTypeOptions}
                />
                <StoreChainField
                  isSimpleField
                  fieldType={EFieldType.TEXT}
                  name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BRANDS}`}
                  labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.BRANDS}`}
                  options={cardIssuerActionTypeOptions}
                />
                <StoreChainField
                  isSimpleField
                  fieldType={EFieldType.TEXT}
                  name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.COUNTRY_CODES}`}
                  labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.COUNTRY_CODES}`}
                  options={cardIssuerActionTypeOptions}
                />

                <StoreChainField
                  isSimpleField
                  fieldType={EFieldType.SELECT}
                  name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
                  labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
                  options={cardIssuerActionTypeOptions}
                  extendOnChange={value => {
                    if (value === EIssuerActionType.DENY_ENTRY) {
                      setFieldValue(
                        `${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`,
                        ""
                      );
                    }
                  }}
                />

                {item.action.type === EIssuerActionType.OVERRIDE_PREAUTH && (
                  <StoreChainField
                    isSimpleField
                    fieldType={EFieldType.TEXT}
                    name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
                    labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
                  />
                )}
              </>
            )}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PHONE_BLACKLIST_ID}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.TIME_ZONE}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="2rem"
          >
            {headers[`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]}
          </Text>

          <OrdersRules ordersRules={ordersRules} />
          <DailyPeriodRules dailyPeriodRules={dailyPeriodRules} />
          <PeriodRules periodRules={periodRules} />

          <Text
            id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`}
            fontSize="0.8rem"
            fontWeight="bold"
            m="0"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`
              ]
            }
          </Text>
          <BorderedBox display="flex" flexDirection="column" gap="1rem">
            <StoreChainField
              isDisabled
              fieldType={EFieldType.CHECKBOX}
              name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`}
            />
          </BorderedBox>
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`}
            {...sectionHeaderStyles}
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`
              ]
            }
          </Text>
          <FieldArray
            name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push(emptyPaymentMethod)}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    mb="1rem"
                    justifyContent="flex-start"
                  >
                    Dodaj nową metodę płatności
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb="1rem">
                  {retryPaymentMethods && retryPaymentMethods.length > 0 ? (
                    retryPaymentMethods.map((_, index) => (
                      <Flex {...tileStyles} mt="0" key={index}>
                        <StoreChainField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.${index}.${PaymentMethodFields.NAME}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.0.${PaymentMethodFields.NAME}`}
                        />
                        <StoreChainField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.${index}.${PaymentMethodFields.TYPE}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.0.${PaymentMethodFields.TYPE}`}
                        />
                        {!isReadOnly && (
                          <Button
                            onClick={() => remove(index)}
                            variant="ghost"
                            colorScheme="red"
                            color="red.dark"
                            width="min-content"
                            marginTop="auto"
                            marginLeft="auto"
                            leftIcon={<Trash2 size="1rem" />}
                          >
                            Usuń
                          </Button>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Text {...noDataStyles}>Brak języków komunikacji</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}`]}
          </Text>
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}.${EParameterProperty.HANDLER}`}
            options={complaintHandlerOptions}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}.${EParameterProperty.TITLE_PREFIX}`}
          />
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Parameters;
