import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { AppConfigKey } from "api/listAppConfigKeys";
import { Link } from "react-router-dom";

import { useDeleteAppConfigKey } from "../methods/useDeleteAppConfigKey";

type RemoveKeyButtonProps = {
  keyId: AppConfigKey["id"];
  isIcon?: boolean;
};

export function RemoveKeyButton({ keyId, isIcon }: RemoveKeyButtonProps) {
  const removeKey = useDeleteAppConfigKey(keyId);
  const modal = useDisclosure();
  const handleConfirm = async () => {
    await removeKey.fetchData();

    modal.onClose();
  };

  return (
    <>
      <Button
        as={Link}
        onClick={modal.onOpen}
        variant="outlineRed"
        {...(isIcon && {
          leftIcon: <DeleteIcon />,
          minWidth: "6rem"
        })}
      >
        Usuń
      </Button>

      <Modal {...modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text as="h2">Usuwanie klucza.</Text>
          </ModalHeader>
          <ModalBody>
            <Text>Czy na pewno chcesz usunąć klucz {keyId}?</Text>
          </ModalBody>
          <ModalFooter display="flex" gap="1rem">
            <Button
              isLoading={removeKey.isLoading}
              onClick={handleConfirm}
              colorScheme="red"
              variant="solid"
              minWidth="6rem"
            >
              Tak, usuń
            </Button>
            <Button
              isDisabled={removeKey.isLoading}
              onClick={modal.onClose}
              variant="ghost"
              minWidth="6rem"
            >
              Anuluj
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
