import { getConfigKeyByIdUrl } from "api/appConfigKey";
import { Formik } from "formik";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { HeaderWithOptions } from "components/headerWithOptions/HeaderWithOptions";

import { Fields } from "./configKeysForm/components/Fields";
import { initialValues } from "./configKeysForm/formFields";
import { createConfigKeySchema } from "./configKeysForm/validator";
import { useCreateAppConfigKey } from "./methods/useCreateAppConfigKey";
import { FormDataType } from "./types";

export const AppConfigKeysCreate = () => {
  const createKey = useCreateAppConfigKey();

  const onSubmit = (values: FormDataType) => {
    const { id, ...body } = values;

    createKey.fetchData({
      url: getConfigKeyByIdUrl(id),
      body
    });
  };

  return (
    <>
      <CustomBreadcrumbs />

      <BorderedBox padding="2rem" mt=".5rem">
        <HeaderWithOptions title="Tworzenie nowego klucza konfiguracyjnego" />
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={createConfigKeySchema}
        >
          <Fields isLoading={createKey.isLoading} />
        </Formik>
      </BorderedBox>
    </>
  );
};
