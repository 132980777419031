import omit from "lodash/omit";

import {
  EPosType,
  MaintenanceBreak,
  TStoreComponents
} from "types/configFields";
import { posServiceDatetimeFormatter } from "utils/dateTime";

const posMapper = (type: EPosType, posValues: any) => {
  switch (type) {
    case EPosType.ERP:
      return omit(posValues, ["ipos", "posService"]);
    case EPosType.IPOS:
      return omit(posValues, ["posService"]);
    case EPosType.POS_SERVICE:
      const values = omit(posValues, ["ipos"]);
      const maintenanceBreaks = values.posService.maintenanceBreaks;
      const mappedMaintenanceBreaks =
        maintenanceBreaks && maintenanceBreaks.length > 0
          ? maintenanceBreaks.map((item: MaintenanceBreak) => ({
              startsAt: posServiceDatetimeFormatter(item.startsAt, item.type),
              endsAt: posServiceDatetimeFormatter(item.endsAt, item.type)
            }))
          : null;
      return {
        ...values,
        posService: {
          ...values.posService,
          maintenanceBreaks: mappedMaintenanceBreaks
        }
      };
    default:
      return omit(posValues, ["ipos", "posService"]);
  }
};

export const componentsMapper = (components: TStoreComponents) => {
  return {
    ...components,
    pos: components?.pos.type
      ? posMapper(components?.pos.type, components?.pos)
      : components?.pos
  };
};
