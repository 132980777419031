import { EUnavailableStoreReport, TUnavailableStoreReport } from "./types";

export const validate = (values: TUnavailableStoreReport) => {
  const errors = {} as {
    [key in EUnavailableStoreReport]: string;
  };

  const errorMessage = "pole wymagane";

  [
    EUnavailableStoreReport.STARTS_AT,
    EUnavailableStoreReport.ENDS_AT,
    EUnavailableStoreReport.REASON_TYPE
  ].forEach(key => {
    if (!values[key]) errors[key] = errorMessage;
  });

  if (
    values[EUnavailableStoreReport.REASON_TYPE] === "other" &&
    !values[EUnavailableStoreReport.REASON]
  )
    errors[EUnavailableStoreReport.REASON] = errorMessage;

  if (
    values[EUnavailableStoreReport.STARTS_AT] &&
    values[EUnavailableStoreReport.ENDS_AT]
  ) {
    if (
      new Date(values[EUnavailableStoreReport.STARTS_AT]).getTime() >
      new Date(values[EUnavailableStoreReport.ENDS_AT]).getTime()
    )
      errors[EUnavailableStoreReport.ENDS_AT] =
        "Data zakończenia musi być późniejsza niż data rozpoczęcia";
  }

  return errors;
};
