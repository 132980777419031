import { useCheckboxGroup } from "@chakra-ui/react";

export function useHidable<TColumn>({
  defaultVisibleColumns
}: {
  defaultVisibleColumns: TColumn[];
}) {
  const {
    value: visibleColumns,
    getCheckboxProps,
    setValue
  } = useCheckboxGroup({
    defaultValue: defaultVisibleColumns as (string | number)[]
  });

  const getIsVisible = (column: TColumn) =>
    (visibleColumns as TColumn[]).includes(column);

  return { getIsVisible, getCheckboxProps, setValue };
}
