import { Spinner } from "@chakra-ui/react";
import { GroupBase, OptionProps, chakraComponents } from "chakra-react-select";

export const SpinnerOption = {
  Option: (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    <chakraComponents.Option {...props}>
      <Spinner
        role="status"
        aria-label="loading indicator"
        color="base.primaryGreen"
        size="sm"
      />
    </chakraComponents.Option>
  )
};
