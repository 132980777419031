import { useEffect } from "react";
import { FailedFetchingAlert } from "components";
import { Formik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";
import { DataNotReadyAlert } from "components/alerts/Alerts";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { HeaderWithOptions } from "components/headerWithOptions/HeaderWithOptions";

import { Fields } from "./configKeysForm/components/Fields";
import { initialValues } from "./configKeysForm/formFields";
import { editConfigKeySchema } from "./configKeysForm/validator";
import { useGetAppConfigKey } from "./methods/useGetAppConfigKey";
import { useUpdateAppConfigKey } from "./methods/useUpdateAppConfigKey";
import { FormDataType } from "./types";

export const AppConfigKeysEdit = () => {
  const { [PathParameters.STORE_APP_CONFIG_KEY_ID]: keyId } = useParams();
  const getKey = useGetAppConfigKey(keyId);
  const updateKey = useUpdateAppConfigKey(keyId);
  const initialValuesEdit = merge(cloneDeep(initialValues), getKey.data);

  useEffect(() => {
    if (!keyId) return;

    getKey.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyId]);

  const onSubmit = (values: FormDataType) => {
    const { id, ...body } = values;

    updateKey.fetchData({ body });
  };

  if (!keyId) return <FailedFetchingAlert message="Brak ID klucza" />;

  if (getKey.isLoading) return <CustomSpinner />;

  if (getKey.error?.status === 500)
    return <DataNotReadyAlert onClick={() => getKey.fetchData()} />;

  if (getKey.error)
    return <FailedFetchingAlert message="Nieudane pobieranie klucza" />;

  return (
    <>
      <CustomBreadcrumbs />

      <BorderedBox padding="2rem" mt=".5rem">
        <HeaderWithOptions title="Edycja klucza konfiguracyjnego" />
        {getKey.data && (
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValuesEdit}
            validationSchema={editConfigKeySchema}
          >
            <Fields
              isLoading={updateKey.isLoading}
              disabledFields={["id", "key.type"]}
            />
          </Formik>
        )}
      </BorderedBox>
    </>
  );
};
