import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  Tag,
  Tooltip,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react";

import { EStoreState } from "types/configFields";

import { useValidateStoreConfig } from "./useValidateStoreConfig";
import { validationsFields } from "./validationFields";

interface ValidateStoreConfigModalProps {
  storeOpenedState: EStoreState;
}

export const ValidateStoreConfigModal = ({
  storeOpenedState
}: ValidateStoreConfigModalProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure();

  const { isLoading, validationResult, validateStoreConfig } =
    useValidateStoreConfig(openModal);
  return (
    <>
      <Button
        onClick={validateStoreConfig}
        isLoading={isLoading}
        isDisabled={storeOpenedState === EStoreState.ARCHIVED}
      >
        Sprawdź konfigurację
      </Button>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size="2xl"
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Walidacja konfiguracji sklepu</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Center mt="1rem" mb="1rem">
              {validationResult &&
                validationsFields.map(({ key, label, statuses }) => {
                  const value = validationResult[key] as keyof typeof statuses;

                  return (
                    <Tooltip
                      label={value ? statuses[value] : "(brak informacji)"}
                      placement="top"
                      hasArrow={true}
                    >
                      <Tag
                        colorScheme={
                          value ? (value === "OK" ? "green" : "red") : "gray"
                        }
                        mr="0.5rem"
                        mt="-0.125rem"
                        key={key}
                      >
                        {label}
                      </Tag>
                    </Tooltip>
                  );
                })}
            </Center>
            <Center flexDirection="column" gap="0.5rem">
              {validationResult &&
                validationsFields
                  .filter(({ key, statuses }) => {
                    const value = validationResult[
                      key
                    ] as keyof typeof statuses;
                    return value && value !== "OK";
                  })
                  .map(({ key, statuses, label }) => {
                    const value = validationResult[
                      key
                    ] as keyof typeof statuses;
                    return (
                      <Alert status="error" p="0.75rem" alignItems="flex-start">
                        <AlertIcon alignSelf="center" />
                        <AlertTitle>{label}</AlertTitle>
                        <AlertDescription>{statuses[value]}</AlertDescription>
                      </Alert>
                    );
                  })}
            </Center>
          </ModalBody>
          <ModalFooter mt="1rem">
            <Button
              onClick={closeModal}
              variant="solid"
              margin="auto"
              minW="7rem"
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
