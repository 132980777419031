import { useCallback, useEffect, useState } from "react";
import { StoreChainConfig } from "api/common";
import {
  GetStoreChainConfigResponse,
  getStoreChainConfigUrl
} from "api/getStoreChainConfig";
import { useFetch } from "hooks";

export function useStoreChainData(storeChainId?: string): {
  isLoading: boolean;
  storeChain: StoreChainConfig | null;
  error: boolean;
} {
  const { fetchWithAccessToken, error, isLoading } = useFetch();

  const [storeChain, setStoreChain] = useState<StoreChainConfig | null>(null);

  const fetchStoreChain = useCallback(
    async function fetchStoreChainAsync() {
      if (!storeChainId) {
        return;
      }

      setStoreChain(null);

      const url = getStoreChainConfigUrl(storeChainId);

      try {
        const response =
          await fetchWithAccessToken<GetStoreChainConfigResponse>(url);
        const storeChainData = await response.json();

        setStoreChain({
          ...storeChainData,
          updatedAt: new Date(storeChainData.updatedAt),
          createdAt: new Date(storeChainData.createdAt)
        });
      } catch {}
    },
    [storeChainId, fetchWithAccessToken]
  );

  useEffect(() => {
    fetchStoreChain();
  }, [fetchStoreChain]);

  return {
    isLoading,
    storeChain,
    error
  };
}
