import { useCallback, useEffect, useState } from "react";
import {
  AppConfigKey as AppConfigKeyApi,
  AppConfigKeysType,
  ListAppConfigKeysResponse,
  prepareUrlWithSearchParams
} from "api/listAppConfigKeys";
import { useFetch } from "hooks";

type AppConfigKeyData = Omit<AppConfigKeyApi, "key" | "updatedAt"> & {
  type: AppConfigKeysType;
  updatedAt: Date;
};

export const ANY_KEY_TYPE_KEYWORD = "__ANY__";
export type AppConfigKeysFilterType =
  | AppConfigKeysType
  | typeof ANY_KEY_TYPE_KEYWORD;

export function useAppConfigKeysData(): {
  isLoading: boolean;
  appConfigKeys: AppConfigKeyData[];
  error: boolean;
  isMoreKeys: boolean;
  fetchMoreKeys: () => void;
  filterAppConfigKeys: (type: AppConfigKeysFilterType) => void;
} {
  const { fetchWithAccessToken, isLoading, error } = useFetch();

  const [appConfigKeys, setAppConfigKeys] = useState<AppConfigKeyData[]>([]);
  const [continuationToken, setContinuationToken] = useState<string>();

  const fetchAppConfigKeys = useCallback(
    async function fetchAppConfigKeysAsync(
      continuationToken?: string,
      type?: AppConfigKeysFilterType
    ) {
      if (!continuationToken) {
        setAppConfigKeys([]);
      }

      const url = prepareUrlWithSearchParams({
        nextPageMarker: continuationToken,
        type: type === ANY_KEY_TYPE_KEYWORD ? undefined : type
      });

      try {
        const response =
          await fetchWithAccessToken<ListAppConfigKeysResponse>(url);
        const { nextPageMarker, appConfigKeys: chunkKeysApi } =
          await response.json();

        const chunkKeys: AppConfigKeyData[] = chunkKeysApi.map(chunkKey => ({
          ...chunkKey,
          updatedAt: new Date(chunkKey.updatedAt),
          type: chunkKey.key.type
        }));

        if (continuationToken) {
          setAppConfigKeys(keys => [...keys, ...chunkKeys]);
        } else {
          setAppConfigKeys(() => chunkKeys);
        }

        setContinuationToken(nextPageMarker);
      } catch {}
    },
    [fetchWithAccessToken]
  );

  useEffect(() => {
    fetchAppConfigKeys();
  }, [fetchAppConfigKeys]);

  return {
    isLoading,
    appConfigKeys,
    error,
    isMoreKeys: !!continuationToken,
    fetchMoreKeys: () => fetchAppConfigKeys(continuationToken),
    filterAppConfigKeys: (type: AppConfigKeysFilterType) =>
      fetchAppConfigKeys(undefined, type)
  };
}
