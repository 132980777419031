import {
  EClient,
  EComplaint,
  EComplaintContent,
  EComplaintPreview,
  EComplaintProduct,
  ECurrency,
  EIssuingStatus,
  EOrder,
  EPrice,
  EProductComplaintType,
  EProductInvalidPriceProblem,
  EProductProblemProblem,
  EProducts,
  EProperty,
  ERefundStatus,
  EReviewResult,
  ESource,
  EStatus,
  ETrack,
  TComplaintPreview
} from "pages/complaint/methods/useGetComplaint/types";

export const complaintMockPreview: TComplaintPreview = {
  [EComplaintPreview.ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
  [EComplaintPreview.ORDER_ID]: "b3e1eced-f2bd-4d8c-9765-fbc9d1d222d5",
  [EComplaintPreview.ORDER]: {
    [EOrder.HAS_COUPON]: true,
    [EOrder.AIFI_ID]: "b3e1eced-f2bd-4d8c-9765-fbc9d1d222d5",
    [EOrder.CASH_AMOUNT]: 100,
    [EOrder.CURRENCY]: ECurrency.PLN,
    [EOrder.PRE_AUTH_CAPTURED_AMOUNT]: 100,
    [EOrder.CAPTURED_AMOUNT]: 100
  },
  [EComplaintPreview.STORE_ID]: "3d07c219-0a88-45be-9cfc-91e9d095a1e9",
  [EComplaintPreview.STORE]: {
    [EProperty.EXTERNAL_ID]: "3d07c219-0a88-45be-9cfc-91e9d095a1e9"
  },
  [EComplaintPreview.USER_ID]: "3d07c219-0a88-45be-9cfc-91e9d095a1e9",
  [EComplaintPreview.CLIENT]: {
    [EClient.LOYAL_ID]: "7b9d0f7f-d80d-4cd5-a9d0-63b294da89b2",
    [EClient.PHONE]: "666777888",
    [EClient.EMAIL]: "test@pl.pl",
    [EClient.CRM_ID]: "d42291a8-1a59-4dc4-a70e-854ed5d9b901"
  },
  [EComplaintPreview.AIFI_COMPLAINT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
  [EComplaintPreview.CREATED_AT]: "2019-08-24T14:15:22Z",
  [EComplaintPreview.UPDATED_AT]: "2019-08-24T14:15:22Z",
  [EComplaintPreview.REVIEWED_AT]: "2019-08-24T14:15:22Z",
  [EComplaintPreview.STATUS]: EStatus.OPEN,
  [EComplaintPreview.TRACK]: ETrack.MANUAL,
  [EComplaint.CONTENT]: {
    [EComplaintContent.CONTACT]: {
      [EProperty.EMAIL]: "test@test.com"
    },
    [EComplaintContent.MESSAGE]: {
      [EProperty.BODY]:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      [EProperty.IMAGES]: []
    },
    [EComplaintContent.PRODUCTS]: [
      {
        [EComplaintProduct.PRODUCT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f09",
        [EComplaintProduct.COMPLAINT]: {
          [EProperty.TYPE]: EProductComplaintType.INVALID_QUANTITY,
          [EProperty.CORRECTED_QUANTITY]: 1
        }
      },
      {
        [EComplaintProduct.PRODUCT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f10",
        [EComplaintProduct.COMPLAINT]: {
          [EProperty.TYPE]: EProductComplaintType.PRODUCT_PROBLEM,
          [EProperty.PROBLEM]: EProductProblemProblem.DAMAGED,
          [EProperty.AFFECTED_QUANTITY]: 2
        }
      },
      {
        [EComplaintProduct.PRODUCT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f11",
        [EComplaintProduct.COMPLAINT]: {
          [EProperty.TYPE]: EProductComplaintType.INVALID_PRICE,
          [EProperty.PROBLEM]: EProductInvalidPriceProblem.DIFF_THEN_PRICE_TAG,
          [EProperty.AFFECTED_QUANTITY]: 3
        }
      },
      {
        [EComplaintProduct.PRODUCT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f12",
        [EComplaintProduct.COMPLAINT]: {
          [EProperty.TYPE]: EProductComplaintType.OTHER,
          [EProperty.DESCRIPTION]: "some other problem"
        }
      }
    ],
    [EComplaintContent.MISSING_PRODUCTS]: {
      [EProperty.DESCRIPTION]: "some product is missing"
    },
    [EComplaintContent.RECEIPT]: {
      [EProperty.DESCRIPTION]: "receipt test"
    },
    [EComplaintContent.REFUND_AMOUNT]: 321
  },
  [EComplaintContent.CONTACT]: {
    [EProperty.EMAIL]: "user@example.com"
  },
  [EComplaintPreview.REVIEW_RESULT]: {
    [EReviewResult.REVIEWED_PRODUCTS]: [
      {
        [EProperty.PRODUCT_ID]: "497f6eca-6276-4993-bfeb-53cbbbba6f09",
        [EProperty.QUANTITY]: 1,
        [EProperty.PRICE]: {
          [EPrice.TOTAL]: 100,
          [EPrice.SALE]: 20,
          [EPrice.CASH]: 200,
          [EPrice.REBATE]: 10
        }
      }
    ],
    [EReviewResult.CASH_AMOUNT]: 220,
    [EReviewResult.REFUND]: {
      [EProperty.AMOUNT]: 300,
      [EProperty.TOTAL_AMOUNT]: 400,
      [EProperty.STATUS]: ERefundStatus.SUCCESSFUL,
      [EProperty.ISSUING_STATUS]: EIssuingStatus.NONE,
      [EProperty.CONFIRMED_AT]: "2019-08-24T14:15:22Z"
    },
    [EReviewResult.REFUNDS]: [
      {
        [EProperty.AMOUNT]: 400,
        [EProperty.STATUS]: ERefundStatus.ISSUED,
        [EProperty.CONFIRMED_AT]: "2019-08-24T14:15:22Z"
      }
    ]
  },
  [EComplaintPreview.SOURCE]: ESource.MOBILE_APP,
  [EComplaintPreview.PRODUCTS]: [
    {
      [EProducts.DISPLAY_NAME]: "Product 1",
      [EProducts.BARCODE]: "1234567890",
      [EProducts.PRICE]: {
        [EPrice.TOTAL]: 100,
        [EPrice.CASH]: 50,
        [EPrice.LOYALTY_POINTS]: 10
      },
      [EProducts.QUANTITY]: 1
    },
    {
      [EProducts.DISPLAY_NAME]: "Product 2",
      [EProducts.BARCODE]: "2234567890",
      [EProducts.PRICE]: {
        [EPrice.TOTAL]: 200,
        [EPrice.CASH]: 30,
        [EPrice.LOYALTY_POINTS]: 5
      },
      [EProducts.QUANTITY]: 2
    }
  ],
  [EComplaintPreview.HAS_COUPON]: true
};
