import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text
} from "@chakra-ui/react";
import { StoreChainConfigCreate } from "api/common/storeChainConfig";
import { FieldArray, getIn, useFormikContext } from "formik";
import isString from "lodash/isString";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import {
  componentTypesOptions,
  emptyMaintenanceBreak,
  maintenanceBreakOptions
} from "pages/store/consts";
import {
  cardStyles,
  noDataStyles,
  tilesContainerStyles,
  tileStyles
} from "pages/store/styles";
import {
  EComponent,
  EComponentProperty,
  EPosType,
  EMaintenanceBreak,
  EMaintenanceBreakType,
  EStore
} from "types/configFields";

import { headers } from "../labels";

import MonitorFields from "./MonitorFields";
import StoreChainField from "./StoreChainField";

const getBreakFieldType = (type: EMaintenanceBreakType) => {
  switch (type) {
    case EMaintenanceBreakType.DAILY:
      return EFieldType.TIME;
    case EMaintenanceBreakType.YEARLY:
    case EMaintenanceBreakType.ONE_TIME:
      return EFieldType.DATETIME;
  }
};

const Pos = () => {
  const { values, errors, setFieldValue } =
    useFormikContext<StoreChainConfigCreate>();
  const { isReadOnly } = useFieldGenerationContext();

  const keyPart = `${EStore.COMPONENTS}.${EComponent.POS}`;
  const currentType =
    values[EStore.COMPONENTS]?.[EComponent.POS][EComponentProperty.TYPE];
  const isPosService = currentType === EPosType.POS_SERVICE;
  const isIpos = currentType === EPosType.IPOS;
  const maintenanceBreaks =
    values?.[EStore.COMPONENTS]?.[EComponent.POS]?.[
      EComponentProperty.POS_SERVICE
    ]?.[EComponentProperty.MAINTENANCE_BREAKS] || [];
  const maintenanceBreaksError = getIn(
    errors,
    `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`
  );
  const hasMaintenanceBreaksError = Boolean(
    maintenanceBreaksError && isString(maintenanceBreaksError)
  );

  return (
    <>
      <Flex {...cardStyles}>
        <StoreChainField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreChainField
          fieldType={EFieldType.SELECT}
          name={`${keyPart}.${EComponentProperty.TYPE}`}
          options={componentTypesOptions}
        />

        {isIpos && (
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IPOS}.${EComponentProperty.VAT_RATES}`}
          />
        )}
      </Flex>

      {isPosService && (
        <>
          <Text
            id={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`}
            fontSize="0.8rem"
            fontWeight="bold"
            mt="1rem"
          >
            {
              headers[
                `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`
              ]
            }
          </Text>

          <FormControl isInvalid={hasMaintenanceBreaksError} position="static">
            <FieldArray
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`}
            >
              {({ push, remove }) => (
                <>
                  {!isReadOnly && (
                    <Button
                      onClick={() => push(emptyMaintenanceBreak)}
                      variant="ghost"
                      leftIcon={<CirclePlusIcon size="1rem" />}
                      justifyContent="start"
                    >
                      Dodaj nową przerwę serwisową
                    </Button>
                  )}

                  <Flex
                    {...tilesContainerStyles}
                    mb="2em"
                    border={hasMaintenanceBreaksError ? "1px solid red" : ""}
                  >
                    {maintenanceBreaks.length > 0 ? (
                      maintenanceBreaks.map((item, index) => (
                        <Flex {...tileStyles} mt="0" key={index} minW="315px">
                          <StoreChainField
                            fieldType={EFieldType.SELECT}
                            name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.TYPE}`}
                            labelOverride={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.TYPE}`}
                            options={maintenanceBreakOptions}
                            extendOnChange={(value, prevValue) => {
                              if (value !== prevValue) {
                                setFieldValue(
                                  `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.STARTS_AT}`,
                                  ""
                                );
                                setFieldValue(
                                  `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.ENDS_AT}`,
                                  ""
                                );
                              }
                            }}
                          />
                          {!!item.type && (
                            <>
                              <StoreChainField
                                fieldType={getBreakFieldType(item.type)}
                                name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.STARTS_AT}`}
                                labelOverride={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.STARTS_AT}`}
                                isYearVisible={
                                  item.type === EMaintenanceBreakType.ONE_TIME
                                }
                              />
                              <StoreChainField
                                fieldType={getBreakFieldType(item.type)}
                                name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.ENDS_AT}`}
                                labelOverride={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.ENDS_AT}`}
                                isYearVisible={
                                  item.type === EMaintenanceBreakType.ONE_TIME
                                }
                              />
                            </>
                          )}

                          {!isReadOnly && (
                            <Button
                              onClick={() => remove(index)}
                              variant="ghost"
                              colorScheme="red"
                              color="red.dark"
                              width="min-content"
                              marginTop="auto"
                              marginLeft="auto"
                              leftIcon={<Trash2 size="1rem" />}
                            >
                              Usuń
                            </Button>
                          )}
                        </Flex>
                      ))
                    ) : (
                      <Text {...noDataStyles}>Brak przerw serwisowych</Text>
                    )}
                  </Flex>
                  <FormErrorMessage>
                    {maintenanceBreaksError &&
                      isString(maintenanceBreaksError) &&
                      maintenanceBreaksError}
                  </FormErrorMessage>
                </>
              )}
            </FieldArray>
          </FormControl>
          <MonitorFields
            title="Konfiguracja monitora dostępności sklepu - POS"
            monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
          />
          <MonitorFields
            title="Konfiguracja monitora stanu papieru w drukarce (tylko dla tradycyjnego POS-a Żabka)"
            monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.PAPER_STATE}`}
          />
        </>
      )}
    </>
  );
};

export default Pos;
