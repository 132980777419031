import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { PathParts } from "routes";
import { useRoles } from "hooks/roles/useRoles";

export function TopSection() {
  const { canEditStoreChains } = useRoles();

  return (
    <Flex justifyContent="flex-end" mb="1rem">
      {canEditStoreChains && (
        <Button
          as={Link}
          leftIcon={<AddIcon />}
          to={PathParts.CREATE}
          p="0 15px"
          bg="white"
        >
          Nowa sieć
        </Button>
      )}
    </Flex>
  );
}
