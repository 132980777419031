import { useToast } from "@chakra-ui/react";
import { ComponentPropertyStatus } from "api/common/storeComponentProperty";
import {
  SetComponentPropertiesPayload,
  SetComponentPropertiesResponse,
  setComponentPropertiesUrl,
  method
} from "api/setComponentProperties";

import { DEFAULT_TOAST_DURATION_MS } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

export function usePowerBouncerMode(
  storeId: string,
  status: ComponentPropertyStatus,
  onChange: (storeId: string, status: ComponentPropertyStatus) => void,
  closeModal: () => void,
  mpk?: string
) {
  const toast = useToast();

  const { fetchData, isLoading } = useFetchRefactored<
    SetComponentPropertiesPayload,
    SetComponentPropertiesResponse
  >({ method, body: { status } });

  async function changeMode() {
    const response = await fetchData({
      url: setComponentPropertiesUrl(storeId)
    });
    if (!response.data) {
      throw new Error(response.error);
    }

    closeModal();
    onChange(storeId, response.data.properties.status);
  }

  function changeModeWithToast() {
    toast.promise(changeMode(), {
      success: {
        title: "Poprawnie zmieniono stan",
        description: `w sklepie ${mpk}`,
        duration: DEFAULT_TOAST_DURATION_MS
      },
      error: {
        title: "Nieudana zmiana stanu",
        description: `w sklepie ${mpk}`,
        duration: DEFAULT_TOAST_DURATION_MS
      },
      loading: {
        title: "Trwa zmiana stanu",
        description: `w sklepie ${mpk}`
      }
    });
  }

  return { isLoading, changeModeWithToast };
}
