import "@fontsource/open-sauce-one";

export const colors = {
  WHITE: "#FFFFFF",
  BLACK: "#0B0B0B",
  GRAY_LIGHT: "#F1FBFE",
  GRAY_50: "#FAFAFA",
  GRAY_200: "#F4F5F5",
  GRAY_400: "#E8E8E8",
  GRAY_500: "#D3D3D3",
  GRAY_600: "#7F7F7F",
  GRAY_800: "#444444",
  GRAY_DARK: "#7F7F7F",
  GRAY_MEDIUM: "#95A2AC",
  BLUE_LIGHT: "#F1FBFE",
  BLUE_MEDIUM: "#75d7fa",
  BLUE_DARK: "#00BBFF",
  RED_LIGHT: "#FFD8DF",
  RED_DARK: "#FE3458",
  PURPLE_LIGHT: "#F9F2FC",
  PURPLE_DARK: "#9C3DCC",
  GREEN_LIGHT: "#F0FFF0",
  GREEN_DARK: "#22C91E",
  YELLOW_LIGHT: "#FFF4D7",
  YELLOW_DARK: "#A67803"
};

export const fonts = {
  heading: `'Open Sauce One', 'sans-serif'`,
  body: `'Open Sauce One', 'sans-serif'`
};
