import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const outline = defineStyle({
  height: "5.75rem",
  padding: ".75rem",
  border: "1px solid",
  borderColor: "gray.400 !important",
  borderRadius: 8,
  resize: "none",

  _focus: {
    border: "1px solid",
    borderColor: "blue.dark !important",
    shadow: "none"
  }
});

const Textarea = defineStyleConfig({
  variants: { outline }
});

export default Textarea;
