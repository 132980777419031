import * as Yup from "yup";

import { EStore } from "types/configFields";

import { componentsSchema } from "./componentsSchema";
import { emailFormat, requiredMessage } from "./consts";
import { openingScheduleSchema } from "./openingScheduleSchema";
import { parametersSchema } from "./parametersSchema";
import { servicesSchema } from "./servicesSchema";

export const validationSchema = Yup.object().shape({
  [EStore.STORE_ID]: Yup.string().required(requiredMessage),
  [EStore.CHAIN_ID]: Yup.string().required(requiredMessage),
  [EStore.EXTERNAL_STORE_ID]: Yup.string().required(requiredMessage),
  [EStore.NAME]: Yup.string().required(requiredMessage),
  [EStore.TYPE]: Yup.string().required(requiredMessage),
  [EStore.STATE]: Yup.string().required(requiredMessage),
  [EStore.ADDRESS]: Yup.string().required(requiredMessage),
  [EStore.MANAGER_EMAIL]: Yup.string()
    .email(emailFormat)
    .required(requiredMessage),
  [EStore.PARAMETERS]: parametersSchema,
  [EStore.SERVICES]: servicesSchema,
  [EStore.COMPONENTS]: componentsSchema,
  [EStore.OPENING_SCHEDULE]: openingScheduleSchema
});
