import { useToast } from "@chakra-ui/react";
import { getConfigKeyByIdUrl } from "api/appConfigKey";
import { AppConfigKey } from "api/listAppConfigKeys";
import { useNavigate } from "react-router-dom";

import { commonToastSetup } from "consts";
import { Routes } from "routes";
import { useFetchRefactored } from "hooks/useFetchRefactored";

export const useUpdateAppConfigKey = (keyId?: string) => {
  const navigate = useNavigate();
  const toast = useToast();
  const updateFetch = useFetchRefactored<AppConfigKey, AppConfigKey>({
    url: getConfigKeyByIdUrl(keyId),
    method: "PATCH"
  });

  const fetchData = async (body: any) => {
    const { data, error } = await updateFetch.fetchData(body);

    if (data) {
      toast({
        title: "Zapisano zmiany!",
        description: `Zaktualizowano klucz ${keyId}`,
        status: "success",
        ...commonToastSetup
      });

      navigate(`${Routes.APP_CONFIG_KEYS}/${keyId}`);
    }

    if (error) {
      toast({
        title: "Błąd",
        description: "Nie udało się zapisać zmian",
        status: "error",
        ...commonToastSetup
      });
    }
  };

  return { ...updateFetch, fetchData };
};
