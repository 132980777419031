import { Button, Flex, Text } from "@chakra-ui/react";
import { FieldArray } from "formik";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { emptyPeriodRule } from "pages/store/consts";
import {
  noDataStyles,
  tilesContainerStyles,
  tileStyles
} from "pages/store/styles";
import {
  ECLientPriority,
  EParameters,
  EStore,
  PeriodRule
} from "types/configFields";

import { headers } from "../labels";

import StoreChainField from "./StoreChainField";

type TPeriodRulesProps = {
  periodRules: PeriodRule[];
};

const PeriodRules = ({ periodRules }: TPeriodRulesProps) => {
  const { isReadOnly } = useFieldGenerationContext();

  return (
    <>
      <Text
        id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}`}
        fontSize="0.8rem"
        fontWeight="bold"
        mb="1rem"
      >
        {
          headers[
            `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}`
          ]
        }
      </Text>
      <FieldArray
        name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}`}
      >
        {({ push, remove }) => (
          <>
            {!isReadOnly && (
              <Button
                onClick={() => push(emptyPeriodRule)}
                variant="ghost"
                leftIcon={<CirclePlusIcon size="1rem" />}
                mb="1rem"
              >
                Dodaj nowe parametry reguły
              </Button>
            )}

            <Flex {...tilesContainerStyles} mb="2rem">
              {periodRules.length > 0 ? (
                periodRules.map((_, index) => (
                  <Flex {...tileStyles} mt="0" key={index}>
                    <StoreChainField
                      fieldType={EFieldType.DATETIME}
                      name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.${index}.${ECLientPriority.STARTS_AT}`}
                      labelOverride={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.STARTS_AT}`}
                    />
                    <StoreChainField
                      fieldType={EFieldType.DATETIME}
                      name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.${index}.${ECLientPriority.ENDS_AT}`}
                      labelOverride={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.ENDS_AT}`}
                    />
                    <StoreChainField
                      fieldType={EFieldType.TEXT}
                      name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.${index}.${ECLientPriority.PRIORITY}`}
                      labelOverride={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.PRIORITY}`}
                    />
                    {!isReadOnly && (
                      <Button
                        onClick={() => remove(index)}
                        variant="ghost"
                        colorScheme="red"
                        color="red.dark"
                        width="min-content"
                        marginTop="auto"
                        marginLeft="auto"
                        leftIcon={<Trash2 size="1rem" />}
                      >
                        Usuń
                      </Button>
                    )}
                  </Flex>
                ))
              ) : (
                <Text {...noDataStyles}>Brak reguł</Text>
              )}
            </Flex>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default PeriodRules;
