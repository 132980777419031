import { Dispatch, SetStateAction, useState } from "react";
import { Button, ButtonGroup, Checkbox, Flex } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import { TFilters } from "../EnergyDrinks";

type TProps = {
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
};

export function Filters({ filters, setFilters }: TProps) {
  const [isWithoutToken, setIsWithoutToken] = useState(
    () => filters.isWithoutToken
  );

  const handleSetFilters = () => {
    setFilters({
      ...filters,
      isWithoutToken
    });
  };
  return (
    <BorderedBox display="flex" gap="2rem" mt="1rem">
      <Flex>
        <FieldWrapper
          name="isWithoutToken"
          label="Ukryj urządzenia bez podanego tokena"
          w="100%"
          isCheckbox
        >
          <Checkbox
            name="isWithoutToken"
            isChecked={isWithoutToken}
            onChange={e => setIsWithoutToken(e.target.checked)}
          />
        </FieldWrapper>
      </Flex>

      <ButtonGroup mt="auto" ml="auto">
        <Button onClick={handleSetFilters} variant="solid" width="6rem">
          Filtruj
        </Button>
      </ButtonGroup>
    </BorderedBox>
  );
}
