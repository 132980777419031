import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { isProduction } from "utils";

import App from "./App";
import { msalConfig } from "./authConfig";
import theme from "./theme/theme";

const msalInstance = new PublicClientApplication(msalConfig);

if (!isProduction()) {
  document.title = `${process.env.REACT_APP_ENVIRONMENT}: ${document.title}`;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <MsalProvider instance={msalInstance}>
        <ChakraProvider
          resetCSS
          theme={theme}
          toastOptions={{
            defaultOptions: { isClosable: true }
          }}
        >
          <App />
        </ChakraProvider>
      </MsalProvider>
    </HashRouter>
  </React.StrictMode>
);
