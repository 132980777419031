import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    color: "black"
  },
  dialog: {
    color: "black",
    borderRadius: "1rem"
  }
});

const bottom = {
  dialogContainer: {
    alignItems: "flex-end",
    padding: 0
  },
  dialog: {
    maxWidth: "100%",
    marginBottom: 0,
    borderRadius: "1rem 1rem 0 0"
  }
};
const xs = {
  dialogContainer: {
    alignItems: "center",
    padding: "1rem"
  },
  dialog: {
    width: "full",
    maxWidth: "40rem"
  },
  header: {
    fontsize: "1.11rem"
  },
  overlay: {
    padding: "1rem"
  },
  body: {
    paddingBottom: "1rem"
  },
  footer: {
    padding: "1.5rem"
  }
};

const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: { bottom },
  sizes: { xs },
  defaultProps: { size: "xs" }
});

export default Modal;
