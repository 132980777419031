import { Icon, IconProps } from "@chakra-ui/react";

export function FilterIcon(props: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23564 8.92736C8.71822 9.30653 9 9.88628 9 10.5V17.1761L11 16.4724V10.5C11 9.88628 11.2818 9.30653 11.7644 8.92736L17.6178 4.32821C17.8591 4.13863 18 3.84875 18 3.54189V2H2V3.54189C2 3.84876 2.14089 4.13863 2.38218 4.32821L8.23564 8.92736ZM18.8535 5.90085L13 10.5V17.1806C13 17.605 12.7322 17.9831 12.3319 18.124L8.33191 19.5314C7.68145 19.7602 7 19.2776 7 18.5881V10.5L1.14654 5.90085C0.422664 5.33209 0 4.46248 0 3.54189V2C0 0.89543 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V3.54189C20 4.46248 19.5773 5.33209 18.8535 5.90085Z"
        fill="currentColor"
      />
    </Icon>
  );
}
