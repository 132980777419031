import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";

import { initialValues } from "./consts";
import Fields from "./Fields";
import { payloadMapper } from "./mappers";
import { TUnavailableStoreReport } from "./types";
import useSetStoreUnavailable from "./useSetStoreUnavailable";
import { validate } from "./validator";

type OpenStoreModalProps = {
  isLoadingState: boolean;
};

export function UnavailableStoreReportModal({
  isLoadingState
}: OpenStoreModalProps): JSX.Element {
  const { [PathParameters.STORE_ID]: storeId } = useParams();
  const disclosure = useDisclosure();

  const { isLoading, fetchData } = useSetStoreUnavailable({
    storeId
  });
  const toast = useToast();

  const handleOnSubmit = async (values: TUnavailableStoreReport) => {
    const body = payloadMapper(values);

    const { error, data } = await fetchData({ body });

    if (data) {
      toast({
        title: "Niedostępność sklepu została zapisana",
        status: "success",
        isClosable: true
      });

      return disclosure.onClose();
    }

    if (error)
      return toast({
        title: "Nie udało się zapisać niedostępności sklepu",
        status: "error",
        isClosable: true
      });
  };

  return (
    <>
      <Button onClick={disclosure.onOpen} isLoading={isLoading}>
        Dodaj niedostępność
      </Button>

      <Modal {...disclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dodaj niedostępność sklepu</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form>
              <ModalBody display="flex" flexDirection="column" gap="1rem">
                <Fields />
              </ModalBody>

              <ModalFooter>
                <Button mr="1rem" onClick={disclosure.onClose}>
                  Anuluj
                </Button>
                <Button
                  colorScheme="green"
                  variant="solid"
                  type="submit"
                  isLoading={isLoading}
                >
                  Zapisz
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}
