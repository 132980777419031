import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import {
  OpenStoreResponse,
  openStoreUrl,
  prepareOpenStorePayload
} from "api/openStore";
import { useFetch, useUserData } from "hooks";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";

import { StoreStatus } from "../../types";

export function useStoreOpen(
  closeModal: () => void,
  onStateChange: (expectedState: StoreStatus, callback: () => void) => void
): {
  openStoreWithToast: (changeReason: string, complete: boolean) => void;
  isLoading: boolean;
} {
  const { postWithAccessToken, isLoading } = useFetch();
  const { username } = useUserData();
  const { [PathParameters.STORE_ID]: storeId } = useParams();

  const toast = useToast();

  const openStore = useCallback(
    async function openStoreAsync(changeReason: string, complete: boolean) {
      if (!storeId) {
        return;
      }

      const url = openStoreUrl(storeId);
      const payload = prepareOpenStorePayload(username, changeReason, complete);
      postWithAccessToken<OpenStoreResponse>(url, payload);
    },
    [username, postWithAccessToken, storeId]
  );

  const openStoreWithToast = useCallback(
    function openStoreWithToast(changeReason: string, complete: boolean) {
      const closeRequest = new Promise<void>(async (resolve, reject) => {
        try {
          await openStore(changeReason, complete);
          closeModal();
          onStateChange(StoreStatus.OPEN, resolve);
        } catch (error) {
          reject();
        }
      });

      toast.promise(closeRequest, {
        success: { title: "Otwarto sklep" },
        error: { title: "Nieudane otwarcie sklepu" },
        loading: {
          title: "Trwa otwieranie sklepu",
          description: "może to potrwać kilkadziesiąt sekund"
        }
      });
    },
    [openStore, toast, closeModal, onStateChange]
  );

  return { isLoading, openStoreWithToast };
}
