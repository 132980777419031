import { useCallback, useEffect, useState } from "react";
import {
  BatchGetComponentStateResponse,
  batchGetComponentStateUrl,
  getStoresPosPaperStateMap,
  preparePosPaperStatePayload
} from "api/batchGetComponentState";
import {
  StoreStateResponse,
  batchGetStoreStateUrl,
  prepareStoreStatePayload
} from "api/batchGetStoreState";
import { getStoreAvailabilityState } from "api/common/storeState";
import {
  ListStoreConfigsResponse,
  SearchQueryParam,
  prepareUrlWithSearchParams
} from "api/listStoreConfigs";
import { useFetch } from "hooks";

import { StoreData } from "pages/common/storeState/types";

export function useStoresData(): {
  isLoading: boolean;
  stores: StoreData[];
  error: boolean;
  searchStores: (query: SearchQueryParam, value: string) => void;
} {
  const { fetchWithAccessToken, postWithAccessToken, error, isLoading } =
    useFetch();

  const [stores, setStores] = useState<StoreData[]>([]);

  const fetchPOSPaperState = useCallback(
    async function fetchPOSPaperStateAsync(storesId: string[]) {
      setStores(stores =>
        stores.map(store => {
          if (storesId.includes(store.storeId)) {
            return {
              ...store,
              isLoadingPOSPaperState: true
            };
          }
          return store;
        })
      );

      const payload = preparePosPaperStatePayload(storesId);

      try {
        const response = await postWithAccessToken<
          BatchGetComponentStateResponse[]
        >(batchGetComponentStateUrl, payload);
        const storesComponentsStateResponse = await response.json();

        const storesPosPaperState = getStoresPosPaperStateMap(
          storesComponentsStateResponse
        );

        setStores(stores =>
          stores.map(store => {
            const posPaperState = storesPosPaperState.get(store.storeId);
            if (posPaperState) {
              return {
                ...store,
                isLoadingPOSPaperState: false,
                posPaperState
              };
            }
            return store;
          })
        );
      } catch {}
    },
    [postWithAccessToken]
  );

  const fetchStoresState = useCallback(
    async function fetchStoresStateAsync(storesId: string[]) {
      setStores(stores =>
        stores.map(store => {
          if (storesId.includes(store.storeId)) {
            return { ...store, isLoadingState: true };
          }
          return store;
        })
      );

      const payload = prepareStoreStatePayload(storesId);

      try {
        const response = await postWithAccessToken<StoreStateResponse[]>(
          batchGetStoreStateUrl,
          payload
        );
        const storeStatesResponse = await response.json();

        const storeStates = new Map(
          storeStatesResponse.map(store => [store.storeId, store.result])
        );

        setStores(stores =>
          stores.map(store => {
            const storeState = storeStates.get(store.storeId);
            if (storeState) {
              return {
                ...store,
                isLoadingState: false,
                storeAvailability: getStoreAvailabilityState(storeState),
                stateUpdatedAt: new Date(storeState.updatedAt),
                healthchecks: storeState.components
              };
            }
            return store;
          })
        );
      } catch {}
    },
    [postWithAccessToken]
  );

  const fetchStores = useCallback(
    async function fetchStoresAsync(query?: SearchQueryParam, value?: string) {
      let continuationToken;

      do {
        const storesUrl = prepareUrlWithSearchParams(
          {
            nextPageMarker: continuationToken || undefined
          },
          query,
          value
        );

        try {
          const response =
            await fetchWithAccessToken<ListStoreConfigsResponse>(storesUrl);
          const { nextPageMarker, stores: chunkStores } = await response.json();

          if (continuationToken) {
            setStores(stores => [...stores, ...chunkStores]);
          } else {
            setStores(() => chunkStores);
          }

          if (chunkStores.length) {
            const storesIds: string[] = chunkStores.map(store => store.storeId);
            fetchStoresState(storesIds);
            fetchPOSPaperState(storesIds);
          }

          continuationToken = nextPageMarker;
        } catch {}
      } while (continuationToken);
    },
    [fetchWithAccessToken, fetchStoresState, fetchPOSPaperState]
  );

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  return {
    isLoading,
    stores,
    error,
    searchStores: (query: SearchQueryParam, value: string) =>
      fetchStores(query, value)
  };
}
