export const DEFAULT_TOAST_DURATION_MS = 9000;
export const commonToastSetup = {
  duration: DEFAULT_TOAST_DURATION_MS,
  isClosable: true
};

export const API_ROOT = `${process.env.REACT_APP_API_URL}/system/v1`;

export const DEFAULT_RESULTS_PER_PAGE = "10";

export enum StateColorScheme {
  OK = "green",
  ERROR = "red",
  WARNING = "yellow",
  OTHER = "gray"
}

export const defaultResultsPerPage = [30, 50, 100];
