import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  header: {
    fontFamily: "Open Sauce One",
    borderBottom: "1px solid",
    borderColor: "gray.200",
    padding: "1rem 1.25rem"
  },
  container: {
    borderRadius: "12px",
    borderColor: "gray.200",
    backgroundColor: "white",
    color: "black"
  },
  footer: {
    paddingTop: 0
  }
});

const Card = defineMultiStyleConfig({
  baseStyle,
  defaultProps: { variant: "outline" }
});

export default Card;
