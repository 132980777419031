import { Flex, Textarea } from "@chakra-ui/react";
import { Field, useFormikContext } from "formik";

import { FieldCustomDatePicker } from "components/customDatePicker/FieldCustomDatePicker";
import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import { reasonsOptions } from "./consts";
import { EUnavailableStoreReport, TUnavailableStoreReport } from "./types";

const Fields = () => {
  const { values, errors } = useFormikContext<TUnavailableStoreReport>();

  return (
    <>
      <Flex gap="1rem">
        <FieldWrapper
          name={EUnavailableStoreReport.STARTS_AT}
          label="Początek"
          w="100%"
          errorMessage={errors[EUnavailableStoreReport.STARTS_AT]}
        >
          <FieldCustomDatePicker
            name={EUnavailableStoreReport.STARTS_AT}
            isDetailedTime
            maxDate={values[EUnavailableStoreReport.ENDS_AT]}
          />
        </FieldWrapper>
        <FieldWrapper
          name={EUnavailableStoreReport.ENDS_AT}
          label="Koniec"
          w="100%"
          errorMessage={errors[EUnavailableStoreReport.ENDS_AT]}
        >
          <FieldCustomDatePicker
            name={EUnavailableStoreReport.ENDS_AT}
            isDetailedTime
            minDate={values[EUnavailableStoreReport.STARTS_AT]}
          />
        </FieldWrapper>
      </Flex>

      <FieldWrapper
        name={EUnavailableStoreReport.REASON_TYPE}
        label="Powód niedostępności"
        errorMessage={errors[EUnavailableStoreReport.REASON_TYPE]}
      >
        <FieldCustomSelect
          name={EUnavailableStoreReport.REASON_TYPE}
          options={reasonsOptions}
          placeholder="wybierz"
        />
      </FieldWrapper>

      {values[EUnavailableStoreReport.REASON_TYPE] === "other" && (
        <FieldWrapper
          name={EUnavailableStoreReport.REASON}
          label="Opisz"
          errorMessage={errors[EUnavailableStoreReport.REASON]}
        >
          <Field
            as={Textarea}
            name={EUnavailableStoreReport.REASON}
            autoFocus={true}
          />
        </FieldWrapper>
      )}
    </>
  );
};

export default Fields;
