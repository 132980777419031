import { PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type BorderedBoxProps = PropsWithChildren<BoxProps>;

export function BorderedBox({
  children,
  ...boxProps
}: BorderedBoxProps): JSX.Element {
  return (
    <Box
      padding="0.75rem"
      mt="1rem"
      borderWidth="1px"
      borderColor="gray.400"
      borderRadius="0.5rem"
      bg="white"
      {...boxProps}
    >
      {children}
    </Box>
  );
}
