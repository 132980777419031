import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { EUnavailableStoreReport, TUnavailableStoreReport } from "./types";

type TProps = {
  storeId?: string;
};

const useSetStoreUnavailable = ({ storeId }: TProps) => {
  const setStoreUnavailable = useFetchRefactored<
    Omit<TUnavailableStoreReport, EUnavailableStoreReport.REASON_TYPE>,
    Omit<TUnavailableStoreReport, EUnavailableStoreReport.REASON_TYPE>
  >({
    url: `${API_ROOT}/stores/${storeId}/state/unavailable`,
    method: "POST"
  });
  return setStoreUnavailable;
};

export default useSetStoreUnavailable;
