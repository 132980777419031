import { TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { NavigationTab } from "./NavigationTab";
import { PageLink } from "./PageLink";
import { useNavigation } from "./useNavigation";

export function Navigation() {
  const {
    availableLinks,
    selectedTab,
    shouldDisplayLink,
    switchPage,
    saveLocation
  } = useNavigation();

  return (
    <Tabs
      size="md"
      variant="unstyled"
      width="100%"
      background="gray.50"
      paddingTop="0.5rem"
      onChange={switchPage}
      index={selectedTab}
    >
      <TabList
        borderBottom="1px solid"
        borderBottomColor="gray.400"
        padding={{ base: "0 0.5rem", xl: "0 2rem" }}
        justifyContent={{
          base: "center",
          lg: "flex-start"
        }}
      >
        {availableLinks.map(tab => (
          <NavigationTab
            key={tab.label}
            icon={tab.icon}
            label={tab.label}
            to={"to" in tab ? tab.to : undefined}
            children={"children" in tab ? tab.children : undefined}
          />
        ))}
      </TabList>

      <TabPanels background="white">
        {availableLinks.map(tab => (
          <TabPanel
            key={tab.label}
            padding={{ base: "0 0.75rem", xl: "0 3rem" }}
            display="flex"
            flexDirection="row"
            justifyContent={{
              base: "center",
              lg: "flex-start"
            }}
            alignItems="center"
            minHeight="2.5rem"
            gap="1rem"
          >
            {"children" in tab &&
              tab.children.map(link =>
                !shouldDisplayLink(link) ? null : (
                  <PageLink
                    key={link.label}
                    name={link.label}
                    icon={link.icon}
                    to={link.to}
                    fontSize="0.875rem"
                    onClick={() => saveLocation(tab.label, link.label)}
                  />
                )
              )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
