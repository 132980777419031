import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import {
  CloseStoreChangeType,
  CloseStoreResponse,
  closeStoreUrl,
  prepareCloseStorePayload
} from "api/closeStore";
import { useFetch, useUserData } from "hooks";
import { useParams } from "react-router-dom";

import { PathParameters } from "routes";

import { StoreStatus } from "../../types";

export const useStoreClose = (
  closeModal: () => void,
  onStateChange: (expectedState: StoreStatus, callback: () => void) => void
) => {
  const { postWithAccessToken, isLoading } = useFetch();
  const { username } = useUserData();
  const { [PathParameters.STORE_ID]: storeId } = useParams();

  const toast = useToast();

  const closeStore = useCallback(
    async function closeStoreAsync(
      changeType: CloseStoreChangeType,
      changeReason: string
    ) {
      if (!storeId) {
        return;
      }

      const url = closeStoreUrl(storeId);
      const payload = prepareCloseStorePayload(
        username,
        changeType,
        changeReason
      );
      postWithAccessToken<CloseStoreResponse>(url, payload);
    },
    [username, postWithAccessToken, storeId]
  );

  const closeStoreWithToast = useCallback(
    function closeStoreWithToast(
      changeType: CloseStoreChangeType,
      changeReason: string
    ) {
      const closeRequest = new Promise<void>(async (resolve, reject) => {
        try {
          await closeStore(changeType, changeReason);
          closeModal();
          onStateChange(StoreStatus.CLOSED, resolve);
        } catch (error) {
          reject();
        }
      });

      toast.promise(closeRequest, {
        success: { title: "Zamknięto sklep" },
        error: { title: "Nieudane zamknięcie sklepu" },
        loading: {
          title: "Trwa zamykanie sklepu",
          description: "może to potrwać kilkadziesiąt sekund"
        }
      });
    },
    [closeStore, toast, closeModal, onStateChange]
  );

  return { isLoading, closeStoreWithToast };
};
