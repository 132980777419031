export const defaultIntervalInMinutes = 60;

export const hourOptions = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, "0")
).map(hour => ({ value: hour, label: hour }));

export const minutesOptions = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, "0")
).map(minute => ({ value: minute, label: minute }));

export const commonProps = {
  size: "sm",
  isSearchable: true,
  isChevronHidden: true,
  placeholder: "00"
};
