import { Flex, Skeleton, Tag } from "@chakra-ui/react";
import { ComponentPropertyStatus } from "api/common/storeComponentProperty";

import {
  UnknownPowerBouncerStatus,
  powerBouncerStatusLabels
} from "./powerBouncerStatus";

type MaintenanceModeProps = {
  currentStatus?: ComponentPropertyStatus;
  isLoading?: boolean;
};

export function MaintenanceMode({
  currentStatus,
  isLoading: isLoadingState
}: MaintenanceModeProps) {
  return (
    <Skeleton
      isLoaded={isLoadingState === false}
      minWidth="16rem"
      minHeight="1.5rem"
    >
      <Flex gap="0.5rem" minWidth="16rem">
        <Tag
          colorScheme={
            (currentStatus
              ? powerBouncerStatusLabels[currentStatus]
              : UnknownPowerBouncerStatus
            ).color
          }
        >
          {
            (currentStatus
              ? powerBouncerStatusLabels[currentStatus]
              : UnknownPowerBouncerStatus
            ).label
          }
        </Tag>
      </Flex>
    </Skeleton>
  );
}
