// currently only `powerBouncer` is supported on BE
export const managedComponentId = "powerBouncer";

export enum ComponentPropertyStatus {
  ACTIVE = "ACTIVE",
  MAINTENANCE = "MAINTENANCE",
  ALWAYS_OPEN = "ALWAYS_OPEN"
}

export type ComponentPropertiesResponse = {
  properties: {
    status: ComponentPropertyStatus;
  };
  revisionId: string;
  updatedAt: string;
};
