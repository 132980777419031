import { FormDataType } from "../types";

export const initialValues: FormDataType = {
  id: "",
  name: "",
  key: {
    type: "ADYEN",
    apiKey: "",
    terminalUrl: "",
    token: "",
    baseUrl: "",
    posServiceUrl: ""
  }
};

export const requiredFields = ["id", "name", "type"];
export const requiredFieldsByTypeCreate = {
  AIFI: ["apiKey", "baseUrl", "token"],
  ADYEN: ["apiKey", "terminalUrl"],
  INFOKIOSK: ["apiKey", "baseUrl"],
  ERP: ["apiKey", "baseUrl"],
  SHORTENER: ["apiKey", "baseUrl"],
  SYNERISE: ["apiKey", "baseUrl"],
  POS: ["posServiceUrl"],
  IO_CONTROLLER: ["baseUrl"]
};
export const requiredFieldsByTypeUpdate = {
  AIFI: ["baseUrl"],
  ADYEN: ["terminalUrl"],
  INFOKIOSK: ["baseUrl"],
  ERP: ["baseUrl"],
  SHORTENER: ["baseUrl"],
  SYNERISE: ["baseUrl"],
  POS: ["posServiceUrl"],
  IO_CONTROLLER: ["baseUrl"]
};
