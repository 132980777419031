import { useCallback, useMemo } from "react";
import { useMsal } from "@azure/msal-react";

import { definedPermissions } from "./definedRoles";
import { EditablePermissions, ReadOnlyPermissions, Roles } from "./enums";

type TPermissions = {
  [T in keyof typeof definedPermissions]: boolean;
};

export function permitsEverything() {
  return {
    ...Object.fromEntries(
      Object.keys(definedPermissions).map(permission => [permission, true])
    )
  } as TPermissions;
}

export function permitsForRoles(userRoles: string[]) {
  return {
    ...Object.fromEntries(
      Object.entries(definedPermissions).map(([permission, requiredRoles]) => [
        permission,
        requiredRoles.some(requiredRole => userRoles.includes(requiredRole))
      ])
    )
  } as TPermissions;
}

export function useRoles() {
  const { accounts } = useMsal();
  const userRoles = useMemo(() => {
    return accounts[0]?.idTokenClaims?.roles || [];
  }, [accounts]);

  const permissions = useMemo(() => {
    if (userRoles.includes(Roles.ADMIN)) {
      return permitsEverything();
    }

    return permitsForRoles(userRoles);
  }, [userRoles]);

  const shouldDisplay = useCallback(
    (requiredPermissions: (ReadOnlyPermissions | EditablePermissions)[]) => {
      if (userRoles.includes(Roles.ADMIN)) {
        return true;
      }

      return requiredPermissions.some(requiredPermission =>
        definedPermissions[requiredPermission].some(permission =>
          userRoles.includes(permission)
        )
      );
    },
    [userRoles]
  );
  return { ...permissions, shouldDisplay, userRoles };
}
