import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { CloseStoreChangeType } from "api/closeStore";
import { Field, Formik } from "formik";

import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import { StoreStatus } from "../../types";

import {
  initialValues,
  reasonsOptions,
  reasonsOptionsForWorkingHours,
  storeCloseReasons,
  validationSchema
} from "./consts";
import { payloadMapper } from "./mappers";
import { ECloseStoreReport, TCloseStoreReport } from "./types";
import { useStoreClose } from "./useStoreClose";

type CloseStoreModalProps = {
  onStateChange: (expectedState: StoreStatus, callback: () => void) => void;
  isLoadingState: boolean;
};

export function CloseStoreModal({
  onStateChange,
  isLoadingState
}: CloseStoreModalProps): JSX.Element {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure();
  const { closeStoreWithToast, isLoading } = useStoreClose(
    closeModal,
    onStateChange
  );

  const handleSubmit = (values: TCloseStoreReport) => {
    const { reason, changeType } = payloadMapper(values);
    closeStoreWithToast(changeType, reason);
  };

  return (
    <>
      <Button onClick={openModal} isLoading={isLoadingState} variant="solid">
        Zamknij sklep
      </Button>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Zamykanie sklepu</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormControl>
                    <FormLabel htmlFor="changeType">
                      Rodzaj zamknięcia sklepu
                    </FormLabel>
                    <Field as={Select} id="changeType" name="changeType">
                      {Object.entries(storeCloseReasons).map(([key, label]) => (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      ))}
                    </Field>
                  </FormControl>
                  <FieldWrapper
                    name={ECloseStoreReport.REASON}
                    label="Powód zamknięcia sklepu"
                    labelSize="1rem"
                    mt=".8rem"
                    errorMessage={errors[ECloseStoreReport.REASON]}
                  >
                    <>
                      <FieldCustomSelect
                        name={ECloseStoreReport.REASON}
                        options={
                          values["changeType"] ===
                          CloseStoreChangeType.WORKING_HOURS
                            ? reasonsOptionsForWorkingHours
                            : reasonsOptions
                        }
                        placeholder="wybierz"
                      />
                    </>
                  </FieldWrapper>

                  {values[ECloseStoreReport.REASON] === "other" && (
                    <FieldWrapper
                      name={ECloseStoreReport.REASON_DESCRIPTION}
                      label="Opisz"
                      labelSize="1rem"
                      mt=".8rem"
                      errorMessage={
                        errors[ECloseStoreReport.REASON_DESCRIPTION]
                      }
                    >
                      <Field
                        as={Textarea}
                        name={ECloseStoreReport.REASON_DESCRIPTION}
                        autoFocus={true}
                      />
                    </FieldWrapper>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button mr="1rem" onClick={closeModal}>
                    Anuluj
                  </Button>
                  <Button
                    colorScheme="red"
                    variant="solid"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Zamknij sklep
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}
