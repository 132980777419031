import {
  EProductDetails,
  TProductDetails
} from "pages/complaint/methods/useGetProducts/types";

export const productDetailsMock: TProductDetails = {
  [EProductDetails.ID]: "40e68847-6362-4de3-8f49-495ed89df0bf",
  [EProductDetails.AIFI_ID]: "ec9eb1bc-de5a-48f1-9d6b-a1cdfcef3167",
  [EProductDetails.CREATED_AT]: "2021-09-29T09:00:00.000Z",
  [EProductDetails.UPDATED_AT]: "2021-09-29T09:00:00.000Z",
  [EProductDetails.BARCODE]: "1234567890123",
  [EProductDetails.EXTERNAL_PRODUCT_ID]: "24555bb8-c3b2-4e18-99e9-93877f4ffb20",
  [EProductDetails.DEFAULT_NAME]: "Product name",
  [EProductDetails.WEIGHT]: "0.5",
  [EProductDetails.DEFAULT_PRICE]: 100,
  [EProductDetails.LOCALIZED_NAMES]: {
    [EProductDetails.LANGUAGE]: "pl",
    [EProductDetails.NAME]: "Nazwa produktu"
  },
  [EProductDetails.IMAGE_URL]: "https://example.com/image.jpg",
  [EProductDetails.RESTRICTIONS]: {
    [EProductDetails.MAX_COUNT_IN_CART]: "10"
  }
};

export const productDetailssMock: TProductDetails[] = [productDetailsMock];
