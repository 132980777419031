import { EStoreAvailability } from "api/common/storeState";

import { StoreData } from "pages/common/storeState/types";
import { EStoreState } from "types/configFields";

export enum Columns {
  EXTERNAL_STORE_ID = "externalStoreId",
  UPDATED_AT = "updatedAt",
  HEALTHCHECKS = "healthchecks",
  STORE_ID = "storeId",
  NAME = "name",
  CHAIN_ID = "chainId",
  CHAIN_NAME = "chainName",
  STATE = "state",
  STORE_AVAILABILITY = "storeAvailability",
  AIFI_ID = "aifiId",
  SCOPE_NANOVO = "scopeNanovo",
  ADDRESS = "address"
}

export enum EPatternType {
  EXTERNAL_STORE_ID = "externalStoreId",
  ADDRESS = "address",
  CHAIN_ID = "chainId",
  SCOPE_NANOVO = "scopeNanovo",
  AIFI_ID = "aifiId"
}

export type TSearchQueryProperties = {
  displayName: string;
  format: "NUMBER" | "STRING";
};

export type TFilters = {
  state: EStoreState | "ALL";
  storeAvailability: EStoreAvailability | "ALL";
};

export type StoreWithChainName = StoreData & {
  chainName: string;
};
