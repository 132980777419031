import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: ".625rem",
    padding: "1rem",

    ...defineStyle({
      '&[data-status="error"]': {
        borderWidth: "1px",
        borderColor: "red.600",
        backgroundColor: "red.100",
        color: "red.800",
        svg: {
          color: "red.800"
        }
      }
    })
  },
  description: {}
});

const Alert = defineMultiStyleConfig({
  baseStyle
});

export default Alert;
