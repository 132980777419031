import { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Collapse, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";

import { FilterIcon } from "components/icons/FilterIcon";
import { SearchInput } from "components/table/searchInput/SearchInput";

import { TFilters } from "../EnergyDrinks";

import { Filters } from "./Filters";

type TProps = {
  searchValue: string;
  changeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
};

export function TopSection({
  searchValue,
  changeSearchValue,
  clearSearch,
  filters,
  setFilters
}: TProps) {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleToggleFilters = () =>
    setIsFiltersOpen(prevIsFiltersOpen => !prevIsFiltersOpen);

  return (
    <Flex direction="column" gap=".5rem">
      <Formik onSubmit={() => {}} initialValues={{}}>
        <Form>
          <Flex gap="1rem">
            <SearchInput
              value={searchValue}
              onChange={changeSearchValue}
              onClear={clearSearch}
              placeholder="Szukaj sklepu"
            />
            <Button
              onClick={handleToggleFilters}
              p="0 15px"
              leftIcon={<FilterIcon w=".75rem" />}
              variant="ghost"
            >
              Filtry
            </Button>
          </Flex>

          <Box
            as={Collapse}
            in={isFiltersOpen}
            animateOpacity
            overflow="visible !important"
          >
            <Filters {...{ filters, setFilters }} />
          </Box>
        </Form>
      </Formik>
    </Flex>
  );
}
