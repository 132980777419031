import { StoreChainParameters } from "api/common/storeChainConfig";

import {
  cardIssuerActionsMapper,
  replaceWithInitial
} from "pages/store/mappers/helpers";
import { initialStoreChainParameters } from "pages/storeChain/consts";

export const parametersMapper = (parameters: StoreChainParameters | null) => {
  if (!parameters) {
    return initialStoreChainParameters;
  }

  const mappedCardIssuerActions = cardIssuerActionsMapper(
    parameters?.cardIssuerActions,
    true
  );

  return {
    ...parameters,
    sms: {
      ...parameters?.sms,
      prefixConfig: replaceWithInitial(
        { defaultChannel: "", prefixes: [] },
        parameters?.sms?.prefixConfig
      )
    },
    cardIssuerActions: mappedCardIssuerActions
  };
};
