import { ChakraStyledOptions } from "@chakra-ui/react";
import { ChakraStylesConfig, Select } from "chakra-react-select";

import { getCustomStyles, getValue, optionMapper } from "./helpers";
import { SpinnerOption } from "./SpinnerOption";

export type TOption = {
  value: string | number | boolean | any[];
  label: string;
};
type TProps = {
  isLoading?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  isChevronHidden?: boolean;
  value?: TOption["value"];
  onChange: (value?: TOption["value"]) => void;
  options: TOption[];
  name?: string;
  additionalStyles?: ChakraStylesConfig;
} & ChakraStyledOptions;

export function CustomSelect({
  isLoading,
  isSearchable = false,
  isMulti,
  isChevronHidden,
  value,
  onChange,
  options,
  name,
  additionalStyles,
  ...restProps
}: TProps) {
  const handleChange = (option: unknown) =>
    onChange(optionMapper({ option, isMulti }));

  return (
    <Select
      isSearchable={isSearchable}
      isMulti={isMulti}
      components={isLoading ? SpinnerOption : undefined}
      options={options}
      onChange={handleChange}
      value={getValue({ options, isMulti, value })}
      name={name}
      instanceId={name}
      chakraStyles={{
        ...additionalStyles,
        ...getCustomStyles({ isChevronHidden })
      }}
      {...restProps}
    />
  );
}
