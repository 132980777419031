import { useCallback } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useFetch } from "hooks";

import { DEFAULT_TOAST_DURATION_MS } from "consts";

function useLockFridge(): {
  lockFridge: (id: string) => Promise<void>;
  isLoading: boolean;
} {
  const { postWithAccessToken, isLoading } = useFetch();

  async function lockFridge(id: string) {
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/admin/v1/stores/${id}/access-sessions/reject`
    );
    const response = await postWithAccessToken(url);
    if (response.status !== 200) {
      throw response;
    }
  }

  return { lockFridge, isLoading };
}

type LockFridgeButtonProps = {
  isDisabled?: boolean;
  storeId: string;
  mpk: string;
};

export function LockFridgeButton({
  isDisabled,
  storeId,
  mpk
}: LockFridgeButtonProps): JSX.Element {
  const { lockFridge, isLoading } = useLockFridge();

  const toast = useToast();

  const lockFridgeWithToast = useCallback(
    function lockFridgeWithToast() {
      toast.promise(lockFridge(storeId), {
        success: {
          title: "Zablokowono lodówkę",
          description: `w sklepie ${mpk}`,
          duration: DEFAULT_TOAST_DURATION_MS
        },
        error: {
          title: "Nieudane blokowanie lodówki",
          description: `w sklepie ${mpk}`,
          duration: DEFAULT_TOAST_DURATION_MS
        },
        loading: {
          title: "Trwa blokowanie lodówki",
          description: `w sklepie ${mpk}`
        }
      });
    },
    [storeId, lockFridge, mpk, toast]
  );
  return (
    <Button
      colorScheme="red"
      isLoading={isLoading}
      onClick={lockFridgeWithToast}
      minWidth="5rem"
      isDisabled={isDisabled}
    >
      Przerwij
    </Button>
  );
}
