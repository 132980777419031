import { useFetchRefactored } from "./useFetchRefactored";

export type TResult<ResponseType> = {
  data?: ResponseType;
  error?: any;
};

type TQueryParams = Record<string, string | number | undefined>;

type TOptions<T extends TQueryParams> = {
  url?: string;
  queryParams?: T;
  headers?: HeadersInit;
};

const clearQueryParams = (params: {
  [key: string]: string | number | undefined;
}) =>
  Object.fromEntries(
    Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value + ""])
  );

export function useFetchWithQueryParams<
  TRequest extends TQueryParams,
  TResponse
>(initialOptions?: TOptions<TRequest>) {
  const { fetchData: genericFetchData, ...rest } = useFetchRefactored<
    TRequest,
    TResponse
  >({});

  function fetchData(
    options?: TOptions<TRequest>
  ): Promise<TResult<TResponse>> {
    const mergedOptions = {
      ...initialOptions,
      ...options,
      queryParams: clearQueryParams({
        ...initialOptions?.queryParams,
        ...options?.queryParams
      })
    };

    return genericFetchData(mergedOptions);
  }

  return { fetchData, ...rest };
}
