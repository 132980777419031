import { defineStyleConfig } from "@chakra-ui/react";

export const Spinner = defineStyleConfig({
  defaultProps: {
    size: "lg",
    variant: "bold"
  }
});

export default Spinner;
