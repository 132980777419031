import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text
} from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { stickyHeadingStyles } from "pages/store/styles";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import Adyen from "../components/Adyen";
import Aifi from "../components/Aifi";
import Infokiosk from "../components/Infokiosk";
import Pos from "../components/Pos";
import PowerBouncer from "../components/PowerBouncer";
import StoreChainField from "../components/StoreChainField";
import { sectionHeaderStyles } from "../consts";
import { headers } from "../labels";

const Components = () => {
  return (
    <AccordionItem id="components">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Konfiguracja komponentów w sklepie
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.AIFI}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.AIFI}`]}
          </Text>
          <Aifi />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.ADYEN}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.ADYEN}`]}
          </Text>
          <Adyen />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.POS}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.POS}`]}
          </Text>
          <Pos />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]}
          </Text>
          <Infokiosk />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]}
          </Text>
          <PowerBouncer />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.COMPONENTS}.${EComponent.TOTEM}`}
            {...sectionHeaderStyles}
          >
            {headers[`${EStore.COMPONENTS}.${EComponent.TOTEM}`]}
          </Text>
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.COMPONENTS}.${EComponent.TOTEM}.${EComponentProperty.AIFI_ZONE_ID}`}
          />
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Components;
