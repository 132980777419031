import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure
} from "@chakra-ui/react";
import { ComponentPropertyStatus } from "api/common/storeComponentProperty";

import { powerBouncerStatusLabels } from "./powerBouncerStatus";
import { usePowerBouncerMode } from "./usePowerBouncerMode";

type PowerBouncerModeModalProps = {
  currentStatus?: ComponentPropertyStatus;
  storeId: string;
  label: string;
  onChange: (storeId: string, status: ComponentPropertyStatus) => void;
  mpk?: string;
};

export function PowerBouncerModeModal({
  currentStatus,
  storeId,
  label,
  onChange,
  mpk
}: PowerBouncerModeModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [state, setState] = useState<ComponentPropertyStatus>(
    ComponentPropertyStatus.MAINTENANCE
  );

  useEffect(() => {
    setState(
      currentStatus === ComponentPropertyStatus.MAINTENANCE
        ? ComponentPropertyStatus.ACTIVE
        : ComponentPropertyStatus.MAINTENANCE
    );
  }, [currentStatus]);

  const { changeModeWithToast, isLoading } = usePowerBouncerMode(
    storeId,
    state,
    onChange,
    onClose,
    mpk
  );

  return (
    <>
      <Button isLoading={isLoading} onClick={onOpen}>
        {label}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Zmień stan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Zastosuj stan:
            <Select
              value={state}
              onChange={event =>
                setState(event.target.value as ComponentPropertyStatus)
              }
            >
              {Object.entries(powerBouncerStatusLabels)
                .filter(([status]) => status !== currentStatus)
                .map(([status, { label }]) => (
                  <option value={status} key={status}>
                    {label}
                  </option>
                ))}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button mr="1rem" onClick={onClose}>
              Anuluj
            </Button>

            <Button
              colorScheme="green"
              variant="solid"
              isLoading={isLoading}
              onClick={changeModeWithToast}
            >
              Zastosuj
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
