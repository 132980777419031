import { PosServicePosComponent } from "api/common/storeChainConfig";
import { updateMultiple } from "utils";
import { v4 as uuidv4 } from "uuid";

import { responseMapper } from "pages/store/mappers/response";
import { TStore } from "types/configFields";

const updaters = {
  name: () => "",
  storeId: () => uuidv4(),
  externalStoreId: () => "",
  address: () => "",
  state: () => "PRE_LIVE",
  "components.aifi.aifiId": () => "",
  "components.adyen.terminalId": () => "",
  "components.infokiosk.scope": () => "",
  "components.pos.posService": (value: PosServicePosComponent) => {
    if (!value) {
      return null;
    }

    return {
      ...value,
      posIpAddress: ""
    };
  },
  "components.powerBouncer.token": () => ""
};

export const prepareTemplateStore = (chosenStore: TStore): TStore => {
  const cleanedStore = updateMultiple(chosenStore, updaters);
  return responseMapper(cleanedStore);
};
