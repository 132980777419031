import { useToast } from "@chakra-ui/react";
import { getConfigKeyByIdUrl } from "api/appConfigKey";
import { AppConfigKey } from "api/listAppConfigKeys";
import { useNavigate } from "react-router-dom";

import { commonToastSetup } from "consts";
import { Routes } from "routes";
import { useFetchRefactored } from "hooks/useFetchRefactored";

export const useCreateAppConfigKey = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const createFetch = useFetchRefactored<AppConfigKey, AppConfigKey>({
    url: getConfigKeyByIdUrl(),
    method: "PUT"
  });

  const fetchData = async (options: any) => {
    const { data, error } = await createFetch.fetchData(options);

    if (data) {
      toast({
        title: "Zapisano!",
        description: "Utworzono nowy klucz konfiguracyjny",
        status: "success",
        ...commonToastSetup
      });

      const keyId = data.id;

      navigate(`${Routes.APP_CONFIG_KEYS}/${keyId}`);
    }

    if (error) {
      toast({
        title: "Błąd",
        description: "Nie udało się utworzyć nowego klucza",
        status: "error",
        ...commonToastSetup
      });
    }
  };

  return { ...createFetch, fetchData };
};
