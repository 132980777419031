import { Checkbox } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { getValue } from "utils";

type Tprops = { name: string };

export function FieldCustomCheckbox({ name }: Tprops) {
  const { values, setFieldValue, setFieldTouched, setFieldError } =
    useFormikContext<{
      [key: string]: boolean;
    }>();

  return (
    <Checkbox
      name={name}
      isChecked={!!getValue({ values, name })}
      onChange={e => {
        setFieldError(name, undefined);
        setFieldValue(name, e.target.checked);
        setFieldTouched(name, true);
      }}
    />
  );
}
