import { useState } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import { useAuthentication } from "./useAuthentication";

type TQueryParams = {
  [key: string]: string | undefined | boolean | number;
};

export type TOptions<TRequestBody> = {
  url?: string;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  body?: TRequestBody;
  queryParams?: TQueryParams;
  headers?: HeadersInit;
};

const clearQueryParams = (params: TQueryParams) =>
  Object.entries(params).filter(([, val]) => val !== undefined) as string[][];

const getRequestSetup = (options: TOptions<any>) => {
  const { url, ...restOptions } = options;

  if (!url) {
    throw new Error("No url provided");
  }

  if (!restOptions) return { url };

  const { method, headers, body, queryParams } = restOptions;

  const setup: TOptions<any> = {
    url,
    method,
    headers
  };

  if (!method || method === "GET") {
    if (!queryParams) return setup;

    const params = clearQueryParams(queryParams);
    const urlSearchParams = new URLSearchParams(params).toString();
    const urlInstance = new URL(url);
    urlInstance.search = urlSearchParams;

    setup.url = urlInstance.toString();
    return setup;
  }

  if (!body) return setup;

  setup.body = JSON.stringify(body);
  return setup;
};

export function useFetchRefactored<TRequestBody extends {} | null, TResponse>(
  initialOptions?: TOptions<TRequestBody>,
  isJsonResponse = true
) {
  const { getAuthorizationHeader, callSignIn } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TResponse | null>(null);
  const [error, setError] = useState<any>(null);

  const fetchData = async (
    updatedOptions?: TOptions<TRequestBody>
  ): Promise<{ error?: any; data?: TResponse }> => {
    try {
      setIsLoading(true);
      const authorizationHeader = await getAuthorizationHeader();

      const composedOptions = {
        ...initialOptions,
        ...updatedOptions,
        headers: {
          ...(isJsonResponse && {
            "Content-Type": "application/json"
          }),
          ...initialOptions?.headers,
          ...updatedOptions?.headers,
          ...authorizationHeader
        },
        body: {
          ...initialOptions?.body,
          ...updatedOptions?.body
        },
        queryParams: {
          ...initialOptions?.queryParams,
          ...updatedOptions?.queryParams
        }
      };

      const { url, ...options } = getRequestSetup(composedOptions);

      if (!url) return { error: "No url provided" };

      const response = await fetch(url, options);

      if (!response.ok) {
        const error = {
          status: response.status,
          statusText: response.statusText,
          content: await response?.json()
        };
        setError(error);

        return {
          error
        };
      }

      const result = await response.json();
      setError(null);
      setData(result);

      return { data: result };
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        callSignIn();
      }

      setError(error);

      return { error };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    fetchData
  };
}
