import { createContext, useState } from "react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Collapse, useCheckboxGroup } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { Columns } from "pages/stores/types";

type ChakraUICheckboxProps = ReturnType<
  typeof useCheckboxGroup
>["getCheckboxProps"];

export const SwitchableColumnsContext = createContext<{
  visibleColumns: Columns[];
  chakraUICheckboxProps?: ChakraUICheckboxProps;
}>({
  visibleColumns: []
});

type ColumnSwitcherProps = {
  isButtonVisible?: boolean;
  isOpen?: boolean;
  options: { value: string; label: string }[];
  getCheckboxProps: ChakraUICheckboxProps;
};

export const gridStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(18.75rem, 1fr))",
  gap: "1rem"
} as const;
export const flexStyles = {
  display: "flex",
  flexWrap: "wrap",
  gap: "1.875rem"
} as const;

export function ColumnSwitcher({
  isButtonVisible = true,
  isOpen,
  options,
  getCheckboxProps
}: ColumnSwitcherProps): JSX.Element {
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(
    isButtonVisible ? false : true
  );

  const boxStyles = options.length > 10 ? gridStyles : flexStyles;

  return (
    <>
      {isButtonVisible && (
        <Button
          onClick={() => setIsColumnSelectorOpen(isOpen => !isOpen)}
          p="0 15px"
          justifySelf="end"
          bg="white"
          leftIcon={isColumnSelectorOpen ? <ViewOffIcon /> : <ViewIcon />}
        >
          Wybierz kolumny
        </Button>
      )}

      <Box
        width="100%"
        as={Collapse}
        in={isButtonVisible ? isColumnSelectorOpen : isOpen}
        animateOpacity
      >
        <BorderedBox p="5" mt="0.25rem">
          <Box {...boxStyles}>
            {options.map(option => (
              <Checkbox
                key={option.value}
                {...getCheckboxProps?.({
                  value: option.value
                })}
              >
                {option.label}
              </Checkbox>
            ))}
          </Box>
        </BorderedBox>
      </Box>
    </>
  );
}
