import { StoreChainConfigCreate } from "api/common/storeChainConfig";

import { openingScheduleMapper } from "../common/openingScheduleMapper";

import { componentsMapper } from "./components";
import { parametersMapper } from "./parameters";

export const payloadMapper = (payload: StoreChainConfigCreate) => {
  const { openingSchedule, components, parameters, ...restPayload } = payload;

  const mappedParameters = parametersMapper(parameters);
  const mappedSchedule = openingScheduleMapper(openingSchedule);
  const mappedComponents = componentsMapper(components);

  return {
    ...restPayload,
    parameters: mappedParameters,
    components: mappedComponents,
    openingSchedule: mappedSchedule
  };
};
