import { useCallback, useState } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";

type Headers = {
  "Content-Type"?: "application/json";
  Authorization?: string;
};

export type RequestOptions = {
  method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE" | "PATCH";
  headers?: Headers;
  body?: string;
};

type TypedResponse<ResponseType> = Omit<Response, "json"> & {
  json: () => Promise<ResponseType>;
};

export function useFetch(): {
  fetchWithAccessToken: <T>(
    url: URL,
    options?: RequestOptions
  ) => Promise<TypedResponse<T>>;

  postWithAccessToken: <T>(
    url: URL,
    body?: Record<string, any>,
    method?: "PUT" | "POST",
    otherOptions?: RequestOptions
  ) => Promise<TypedResponse<T>>;

  putWithAccessToken: <T>(
    url: URL,
    body?: Record<string, any>,
    otherOptions?: RequestOptions
  ) => Promise<TypedResponse<T>>;

  isLoading: boolean;
  error: boolean;
} {
  const { instance: msalInstance, accounts } = useMsal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchWithAccessToken = useCallback(
    async function fetchWithAccessToken<ResponseType>(
      url: URL,
      options: RequestOptions = {}
    ): Promise<TypedResponse<ResponseType>> {
      setIsLoading(true);
      setError(false);

      try {
        const { accessToken } = await msalInstance.acquireTokenSilent({
          scopes: [process.env.REACT_APP_SCOPE],
          account: accounts[0]
        });

        const response = await fetch(url, {
          ...options,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ...options.headers
          }
        });

        if (!response.ok) {
          throw response.status;
        }

        return response;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          const loginRequest = {
            scopes: [process.env.REACT_APP_SCOPE],
            loginHint: accounts[0].username
          };
          msalInstance.acquireTokenRedirect(loginRequest);
        }

        console.error(error);
        setError(true);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [msalInstance, accounts]
  );

  const postWithAccessToken = useCallback(
    async function postWithAccessToken<ResponseType>(
      url: URL,
      body?: Record<string, string>,
      method: "POST" | "PUT" = "POST",
      otherOptions?: RequestOptions
    ): Promise<TypedResponse<ResponseType>> {
      const options: RequestOptions = {
        method,
        headers: { "Content-Type": "application/json" },
        ...otherOptions
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      return fetchWithAccessToken(url, options);
    },
    [fetchWithAccessToken]
  );

  const putWithAccessToken = useCallback(
    async function putWithAccessToken<ResponseType>(
      url: URL,
      body?: Record<string, string>,
      otherOptions?: RequestOptions
    ): Promise<TypedResponse<ResponseType>> {
      return postWithAccessToken(url, body, "PUT", otherOptions);
    },
    [postWithAccessToken]
  );

  return {
    fetchWithAccessToken,
    postWithAccessToken,
    putWithAccessToken,
    isLoading,
    error
  };
}
