import { StoreChainConfig } from "api/common";

import { openingScheduleMapper } from "../common/openingScheduleMapper";

import { componentsMapper } from "./components";
import { parametersMapper } from "./parameters";

export const responseMapper = (storeChain: StoreChainConfig | null) => {
  if (!storeChain) {
    return {};
  }

  const { openingSchedule, components, parameters, ...restResponse } =
    storeChain;

  const mappedParameters = parametersMapper(parameters);
  const mappedComponents = componentsMapper(components);
  const mappedOpeningSchedule = openingScheduleMapper(openingSchedule, true);

  return {
    ...restResponse,
    parameters: mappedParameters,
    components: mappedComponents,
    openingSchedule: mappedOpeningSchedule
  };
};
