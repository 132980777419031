import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: ".75rem",
    color: "gray.800"
  },
  control: {
    height: "1.125rem",
    width: "1.125rem",
    marginRight: ".5rem",
    border: "1px solid",
    borderColor: "gray.500",
    borderRadius: ".125rem",

    _focus: {
      boxShadow: "0 0 0 1px blue.dark",
      outline: "4px solid",
      outlineColor: "blue.medium"
    },

    _checked: {
      backgroundColor: "blue.dark",
      borderColor: "blue.dark",
      _hover: {
        backgroundColor: "blue.dark",
        borderColor: "blue.dark"
      }
    },
    _indeterminate: {
      backgroundColor: "blue.dark",
      borderColor: "blue.dark"
    },

    _disabled: {
      borderColor: "gray.500 !important",
      backgroundColor: "white",

      _checked: {
        backgroundColor: "gray.500",
        color: "white"
      },
      _indeterminate: {
        backgroundColor: "gray.500"
      }
    },

    _invalid: {
      borderColor: "red.dark",

      _hover: { borderColor: "red.dark" },

      _checked: {
        backgroundColor: "red.dark"
      },
      _indeterminate: {
        backgroundColor: "red.dark"
      }
    }
  }
});

const Checkbox = defineMultiStyleConfig({ baseStyle });

export default Checkbox;
