import { Dispatch, SetStateAction, useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { CustomSelect } from "components/customSelect/CustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import {
  INITIAL_FILTERS,
  STORE_AVAILABILITY_OPTIONS,
  STORE_STATE_OPTIONS
} from "../consts";
import { TFilters } from "../types";

type TProps = {
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
};

export const Filters = ({ filters, setFilters }: TProps) => {
  const [filtersToChange, setFiltersToChange] = useState({
    state: filters.state,
    storeAvailability: filters.storeAvailability
  });

  const handleSetFilters = () => {
    setFilters({
      ...filters,
      state: filtersToChange.state,
      storeAvailability: filtersToChange.storeAvailability
    });
  };

  const handleResetFilters = () => {
    setFiltersToChange(INITIAL_FILTERS);
    setFilters(INITIAL_FILTERS);
  };

  return (
    <BorderedBox display="flex" gap="2rem" mt="1rem">
      <FieldWrapper name="state" label="Stan sklepu" maxW="10em">
        <CustomSelect
          value={filtersToChange.state}
          onChange={(val: any) =>
            setFiltersToChange(prev => ({
              ...prev,
              state: val
            }))
          }
          options={STORE_STATE_OPTIONS}
        />
      </FieldWrapper>
      <FieldWrapper name="state" label="Dostępność sklepu" maxW="12rem">
        <CustomSelect
          value={filtersToChange.storeAvailability}
          onChange={(val: any) =>
            setFiltersToChange(prev => ({
              ...prev,
              storeAvailability: val
            }))
          }
          options={STORE_AVAILABILITY_OPTIONS}
        />
      </FieldWrapper>
      <ButtonGroup mt="auto" ml="auto">
        <Button onClick={handleSetFilters} variant="solid">
          Filtruj
        </Button>
        <Button onClick={handleResetFilters} variant="outline">
          Resetuj filtry
        </Button>
      </ButtonGroup>
    </BorderedBox>
  );
};
