export const datePickerCustomStyles = {
  ".react-datepicker": {
    width: "max-content",
    border: "1px solid",
    borderColor: "gray.400",
    borderRadius: ".5rem",
    boxShadow: "0 0 20px 10px #00000020"
  },
  ".react-datepicker-wrapper": {
    width: "100%"
  },
  ".react-datepicker__triangle": {
    fill: "blue.dark !important",
    color: "blue.dark !important",
    stroke: "transparent !important"
  },
  ".react-datepicker__header": {
    color: "white",
    backgroundColor: "blue.dark",
    border: "none"
  },
  ".react-datepicker-time__header": {
    color: "white"
  },
  ".react-datepicker__time-list": {
    width: "99% !important"
  },
  ".react-datepicker__current-month": { color: "white" },
  ".react-datepicker__day-name": { color: "white" },
  ".react-datepicker__navigation-icon::before": {
    borderColor: "white"
  },
  ".react-datepicker__day--selected": {
    backgroundColor: "blue.dark",
    fontWeight: "bold"
  },
  ".react-datepicker__time-list-item": {
    height: "1.55rem !important"
  },
  ".react-datepicker__time-list-item--selected": {
    backgroundColor: "blue.dark !important"
  },
  ":has(.react-datepicker-popper)": {
    zIndex: 1000
  },
  ".react-datepicker__input-time-container": {
    borderTop: "1px solid",
    borderColor: "gray.400",
    margin: 0,
    padding: ".5rem 1rem",
    width: "100%"
  },
  ".react-datepicker-time__input-container": {
    width: "100%"
  },
  ".react-datepicker-time__input": {
    margin: "0 !important",
    width: "100%"
  },
  ".react-datepicker-time__caption": {
    display: "none"
  }
};
