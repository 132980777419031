import { useCallback, useEffect, useState } from "react";
import {
  ComponentNotFoundValue,
  StoreChainConfig,
  StoreStatus
} from "api/common";
import { getStoreAvailabilityState } from "api/common/storeState";
import {
  GetComponentStateResponse,
  preparePosPaperStateUrl
} from "api/getComponentState";
import {
  GetStoreChainConfigResponse,
  getStoreChainConfigUrl
} from "api/getStoreChainConfig";
import { GetStoreConfigResponse, getStoreConfigUrl } from "api/getStoreConfig";
import { getStoreStateUrl, GetStoreStateResponse } from "api/getStoreState";
import {
  SetStoreConfigPayload,
  SetStoreConfigResponse,
  setStoreConfigUrl
} from "api/setStoreConfig";
import { useFetch } from "hooks";

import { PosPaperState, StoreData, StoreState } from "../types";

const REFETCH_STORE_STATE_AFTER = 5000;

export function useStoreData(storeId?: string): {
  isLoading: boolean;
  store: StoreData | null;
  error: boolean;
  storeState: StoreState | null;
  posPaperState: PosPaperState | null;
  refetchStoresState: (
    expectedState: StoreStatus,
    callback: () => void
  ) => void;
  saveStore: (_: SetStoreConfigPayload) => void;
  storeChain: StoreChainConfig | null;
} {
  const { fetchWithAccessToken } = useFetch();

  const [store, setStore] = useState<StoreData | null>(null);
  const [storeState, setStoreState] = useState<StoreState | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [posPaperState, setPosPaperState] = useState<PosPaperState | null>(
    null
  );

  const [storeChain, setStoreChain] = useState<StoreChainConfig | null>(null);
  const { fetchWithAccessToken: fetchStoreChainConfig } = useFetch();

  const { putWithAccessToken } = useFetch();

  const fetchPosPaperState = useCallback(
    async function fetchPosPaperStateAsync() {
      if (!storeId) {
        return;
      }

      setPosPaperState({
        isLoadingPOSPaperState: true,
        posPaperState: undefined
      });

      try {
        const url = preparePosPaperStateUrl(storeId);
        const response =
          await fetchWithAccessToken<GetComponentStateResponse>(url);
        const componentState = await response.json();

        setPosPaperState({
          isLoadingPOSPaperState: false,
          posPaperState: componentState.state
        });
      } catch {
        setPosPaperState({
          isLoadingPOSPaperState: false,
          posPaperState: ComponentNotFoundValue.NOT_FOUND
        });
      }
    },
    [fetchWithAccessToken, storeId]
  );

  const fetchStoreState = useCallback(
    async function fetchStoreStateAsync(shouldApplyChanges = true) {
      if (!storeId) {
        return;
      }

      setStoreState({
        isLoadingState: true,
        storeAvailability: undefined,
        manual: undefined,
        isClosedManually: undefined,
        healthchecks: undefined,
        stateUpdatedAt: undefined
      });

      const url = getStoreStateUrl(storeId);

      try {
        const response = await fetchWithAccessToken<GetStoreStateResponse>(url);
        const storeState = await response.json();

        const newStoreState: StoreState = {
          isLoadingState: false,
          storeAvailability: getStoreAvailabilityState(storeState),
          manual: storeState.manual,
          isClosedManually: storeState.manual?.status === StoreStatus.CLOSED,
          healthchecks: storeState.components,
          stateUpdatedAt: new Date(storeState.updatedAt)
        };

        if (shouldApplyChanges) {
          setStoreState(newStoreState);
        }
        return newStoreState;
      } catch {
        const newStoreState: StoreState = {
          isLoadingState: false,
          storeAvailability: undefined,
          manual: undefined,
          isClosedManually: undefined,
          healthchecks: undefined,
          stateUpdatedAt: undefined
        };
        if (shouldApplyChanges) {
          setStoreState(newStoreState);
        }
        return newStoreState;
      }
    },
    [fetchWithAccessToken, storeId]
  );

  const fetchStoreChain = useCallback(
    async function fetchStoreChain(chainId: string) {
      const storeChainUrl = getStoreChainConfigUrl(chainId);

      try {
        const response =
          await fetchStoreChainConfig<GetStoreChainConfigResponse>(
            storeChainUrl
          );
        const storeChainData = await response.json();
        setStoreChain(storeChainData);
      } catch {}
    },
    [fetchStoreChainConfig]
  );

  const fetchStore = useCallback(
    async function fetchStoresAsync() {
      if (!storeId) {
        return;
      }

      setStore(null);
      setError(false);
      setIsLoading(true);

      const storesUrl = getStoreConfigUrl(storeId);

      try {
        const response =
          await fetchWithAccessToken<GetStoreConfigResponse>(storesUrl);
        const storeData = await response.json();
        setStore({
          ...storeData,
          configUpdatedAt: new Date(storeData.updatedAt)
        });

        fetchStoreChain(storeData.chainId);

        setError(false);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchWithAccessToken, storeId, fetchStoreChain]
  );

  const refetchStoresState = useCallback(
    async function refetchStoresState(
      expectedState: StoreStatus,
      callback: () => void
    ) {
      setPosPaperState(state => ({
        ...state,
        isLoadingPOSPaperState: true
      }));
      setStoreState(state => ({
        ...state,
        isLoadingState: true
      }));
      setTimeout(async function refetchStoresStateAsync() {
        const newState = await fetchStoreState(false);

        if (newState?.manual?.status !== expectedState) {
          return refetchStoresState(expectedState, callback);
        }

        callback();
        setStoreState(newState || null);
        setPosPaperState(state => ({
          ...state,
          isLoadingPOSPaperState: false
        }));
      }, REFETCH_STORE_STATE_AFTER);
    },
    [fetchStoreState]
  );

  const saveStore = useCallback(
    async function saveStoreAsync(data: SetStoreConfigPayload) {
      if (!storeId) {
        return;
      }

      const url = setStoreConfigUrl(storeId);
      try {
        const response = await putWithAccessToken<SetStoreConfigResponse>(
          url,
          data
        );
        const store = await response.json();
        setStore({
          ...store,
          configUpdatedAt: new Date(store.updatedAt)
        });
      } catch (e) {
        console.error(e);
      }
    },
    [storeId, putWithAccessToken]
  );

  useEffect(() => {
    fetchStore();
    fetchStoreState();
    fetchPosPaperState();
  }, [fetchStore, fetchPosPaperState, fetchStoreState]);

  return {
    isLoading,
    store,
    error,
    storeState,
    posPaperState,
    refetchStoresState,
    saveStore,
    storeChain
  };
}
