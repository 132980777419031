import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    padding: ".75rem",
    borderRadius: ".5rem",
    border: "1px solid",
    borderColor: "gray.400 !important",
    background: "white",

    _focus: {
      borderColor: "blue.dark !important",
      boxShadow: "none"
    }
  }
});

const Input = defineMultiStyleConfig({
  variants: { outline }
});

export default Input;
