import { Button, Flex } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import { Trash2 } from "lucide-react";

import { FieldCustomDatePicker } from "components/customDatePicker/FieldCustomDatePicker";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { Property } from "components/property";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { EProperty, ESchedule, EStore, TStore } from "types/configFields";

import { tileStyles } from "../../styles";

type TProps = {
  index: number;
  onRemove: () => void;
};

const AdjustDayTile = ({ index, onRemove }: TProps) => {
  const { errors, values } = useFormikContext<TStore>();
  const { isReadOnly } = useFieldGenerationContext();
  const adjustedDeyKey = `${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.ADJUSTED_DAYS}.${index}`;

  if (isReadOnly) {
    return (
      <Flex key={index} {...tileStyles}>
        <Property id={`${adjustedDeyKey}.${EProperty.DAY}`} label="data">
          {getIn(values, `${adjustedDeyKey}.${EProperty.DAY}`)}
        </Property>
        <Property
          id={`${adjustedDeyKey}.${EProperty.OPENS_AT}`}
          label="otwarcie"
        >
          {getIn(values, `${adjustedDeyKey}.${EProperty.OPENS_AT}`)}
        </Property>
        <Property
          id={`${adjustedDeyKey}.${EProperty.CLOSES_AT}`}
          label="zamknięcie"
        >
          {getIn(values, `${adjustedDeyKey}.${EProperty.CLOSES_AT}`)}
        </Property>
      </Flex>
    );
  }

  return (
    <Flex key={index} {...tileStyles}>
      <FieldWrapper
        name={`${adjustedDeyKey}.${EProperty.DAY}`}
        label="data"
        w="100%"
        errorMessage={getIn(errors, `${adjustedDeyKey}.${EProperty.DAY}`)}
      >
        <FieldCustomDatePicker
          key={`${adjustedDeyKey}.${EProperty.DAY}`}
          name={`${adjustedDeyKey}.${EProperty.DAY}`}
        />
      </FieldWrapper>
      <FieldWrapper
        name={`${adjustedDeyKey}.${EProperty.OPENS_AT}`}
        label="otwarcie"
        w="100%"
        errorMessage={getIn(errors, `${adjustedDeyKey}.${EProperty.OPENS_AT}`)}
      >
        <FieldCustomDatePicker
          isTime
          isDate={false}
          key={`${adjustedDeyKey}.${EProperty.OPENS_AT}`}
          name={`${adjustedDeyKey}.${EProperty.OPENS_AT}`}
        />
      </FieldWrapper>
      <FieldWrapper
        name={`${adjustedDeyKey}.${EProperty.CLOSES_AT}`}
        label="zamknięcie"
        w="100%"
        errorMessage={getIn(errors, `${adjustedDeyKey}.${EProperty.CLOSES_AT}`)}
      >
        <FieldCustomDatePicker
          isTime
          isDate={false}
          key={`${adjustedDeyKey}.${EProperty.CLOSES_AT}`}
          name={`${adjustedDeyKey}.${EProperty.CLOSES_AT}`}
        />
      </FieldWrapper>

      <Button
        onClick={onRemove}
        variant="ghost"
        colorScheme="red"
        color="red.dark"
        width="min-content"
        marginLeft="auto"
        leftIcon={<Trash2 size="1rem" />}
      >
        Usuń
      </Button>
    </Flex>
  );
};

export default AdjustDayTile;
