import { reasons } from "./consts";
import { ECloseStoreReport, TCloseStoreReport } from "./types";

export const payloadMapper = (values: TCloseStoreReport) => {
  const {
    [ECloseStoreReport.REASON]: reason,
    [ECloseStoreReport.REASON_DESCRIPTION]: reasonDescription,
    ...restValues
  } = values;

  return {
    ...restValues,
    [ECloseStoreReport.REASON]:
      reason === "other"
        ? reasonDescription
        : reasons[reason as keyof typeof reasons]
  };
};
