import { useEffect } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  useToast
} from "@chakra-ui/react";
import { FailedFetchingAlert } from "components";
import { Form, Formik, FormikHelpers, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { commonToastSetup } from "consts";
import { PathParameters, Routes } from "routes";
import { DataNotReadyAlert } from "components/alerts/Alerts";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { useStoreContext } from "contexts/StoreContext";
import { EStore, TStore } from "types/configFields";

import SearchPanel from "./components/searchPanel/SearchPanel";
import { initialStore } from "./consts";
import Fields from "./Fields";
import { useGetStore } from "./hooks/useGetStore";
import { useUpdateStore } from "./hooks/useUpdateStore";
import { payloadMapper } from "./mappers/payload";
import { responseMapper } from "./mappers/response";
import { requiredMessage } from "./validationSchema/consts";
import { validationSchema } from "./validationSchema/validationSchema";

const StoreEdit = () => {
  const { [PathParameters.STORE_ID]: storeId } = useParams();
  const navigate = useNavigate();
  const { storeChains } = useStoreContext();
  const getStore = useGetStore(storeId);
  const updateStore = useUpdateStore(storeId);
  const toast = useToast();
  useEffect(() => {
    if (!storeId) return;

    getStore.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  const handleGoToError = () => {
    const errorMessage = document.querySelector('[class*="error-message"]');

    if (errorMessage) {
      errorMessage.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  };

  const onSubmit = async (
    values: TStore,
    formikHelpers: FormikHelpers<TStore>
  ) => {
    const storeChainId = getIn(values, EStore.CHAIN_ID);
    const currentChain =
      storeChains.find(chain => chain.chainId === storeChainId) || null;

    if (!currentChain) {
      return;
    }

    const { data, error } = await updateStore.fetchData({
      body: payloadMapper(values)
    });

    if (data) {
      toast({
        title: "Zapisano!",
        description: "Zaktualizowano sklep",
        status: "success",
        ...commonToastSetup
      });

      navigate(`${Routes.STORES}/`);
    }

    if (error) {
      if (!error.content) {
        toast({
          title: "Wystąpił błąd!",
          description: error.statusText,
          status: "error",
          ...commonToastSetup
        });
        return;
      } else {
        toast({
          title: "Wystąpił błąd!",
          render: () => (
            <Alert status="error" padding=".5rem 1rem">
              <Flex flexDir="column" alignItems="center">
                <Flex>
                  <AlertIcon />
                  Formularz zawiera błędy.
                </Flex>
                {error.content.detail && (
                  <Button
                    onClick={handleGoToError}
                    variant="ghost"
                    colorScheme="red"
                  >
                    Sprawdź
                  </Button>
                )}
                {error.content.errorMessage && (
                  <pre>
                    {JSON.stringify(error.content.errorMessage, null, 2)}
                  </pre>
                )}
              </Flex>
            </Alert>
          ),
          duration: 8000,
          isClosable: true
        });

        const errors: Record<string, any> = {};

        error.content.detail.forEach((error: any) => {
          const { loc, msg, type } = error;
          let currentLevel = errors;

          loc.forEach((key: any, index: number) => {
            if (index === loc.length - 1) {
              currentLevel[key] =
                type === "type_error.none.not_allowed" ||
                type === "value_error.missing"
                  ? requiredMessage
                  : msg;
            } else {
              if (!currentLevel[key]) {
                currentLevel[key] = {};
              }
              currentLevel = currentLevel[key];
            }
          });
        });

        const finalErrors = errors.body || {};
        const plainObject = JSON.parse(JSON.stringify(finalErrors));
        formikHelpers.setErrors(plainObject);
      }
    }
  };

  if (!storeId) return <FailedFetchingAlert message="Brak ID sklepu" />;

  if (getStore.isLoading || !getStore.data) return <CustomSpinner />;

  if (getStore.error?.status === 500)
    return (
      <DataNotReadyAlert
        onClick={() => {
          getStore.fetchData();
        }}
      />
    );

  if (getStore.error)
    return <FailedFetchingAlert message="Nieudane pobieranie sklepu" />;

  const parsedData = responseMapper(getStore.data);

  return (
    <>
      <CustomBreadcrumbs />

      <BorderedBox padding={{ base: "1rem", lg: "1.5rem" }}>
        <Heading textAlign="center" fontSize="1.5rem">
          {`Edycja konfiguracji sklepu: ${parsedData.name}`}
        </Heading>
      </BorderedBox>

      <Box mt="1rem">
        <Flex
          maxHeight="80vh"
          overflow="auto"
          position="relative"
          id="store-wrapper"
        >
          <SearchPanel />
          {!!getStore.data && (
            <Box w="80%">
              <Formik
                onSubmit={onSubmit}
                initialValues={parsedData || initialStore}
                validationSchema={validationSchema}
              >
                <Form>
                  <Fields isLoading={updateStore.isLoading} isEdit />
                </Form>
              </Formik>
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default StoreEdit;
