import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { StoreChainConfig } from "api/common";

import useGetStoreChains from "pages/store/hooks/useGetStoreChains";
import { TStore } from "types/configFields";

type TProps = {
  children: React.ReactNode;
};

interface IContext {
  isLoading: boolean;
  storeChains: StoreChainConfig[];
  templateStore: Nullable<TStore>;
  setTemplateStore: Dispatch<SetStateAction<Nullable<TStore>>>;
}

const StoreContext = createContext<IContext | undefined>(undefined);

export const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error(
      "useStoreContext hook must be used within a StoreContextProvider"
    );
  }

  return context;
};

export const StoreContextProvider = ({ children }: TProps) => {
  const [templateStore, setTemplateStore] = useState<Nullable<TStore>>(null);
  const { fetchData, isLoading, data } = useGetStoreChains();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StoreContext.Provider
      value={{
        isLoading,
        storeChains: data?.storeChains || [],
        templateStore,
        setTemplateStore
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
