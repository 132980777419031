import { ComponentPropertyStatus } from "api/common/storeComponentProperty";

import { StateColorScheme } from "consts";

export type TStatus = {
  label: string;
  color: StateColorScheme;
};

export const powerBouncerStatusLabels: Record<
  ComponentPropertyStatus,
  TStatus
> = {
  [ComponentPropertyStatus.ACTIVE]: {
    label: "Aktywny",
    color: StateColorScheme.OK
  },
  [ComponentPropertyStatus.MAINTENANCE]: {
    label: "Przerwa serwisowa",
    color: StateColorScheme.WARNING
  },
  [ComponentPropertyStatus.ALWAYS_OPEN]: {
    label: "Zawsze otwarte",
    color: StateColorScheme.OK
  }
};

export const UnknownPowerBouncerStatus: TStatus = {
  label: "Stan nieznany",
  color: StateColorScheme.OTHER
};
