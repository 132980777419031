import { useCheckboxGroup } from "@chakra-ui/react";

type TProps = {
  rows: (string | number)[];
};

export function useRowsCheck({ rows }: TProps) {
  const { setValue, getCheckboxProps, value } = useCheckboxGroup({
    defaultValue: []
  });
  const isAllChecked = value.length === rows.length;

  const toggleCheckAllRows = () => {
    if (isAllChecked) return setValue([]);

    return setValue(rows);
  };

  return {
    getRowsCheckboxProps: getCheckboxProps,
    toggleCheckAllRows,
    checkedRows: value,
    isAllChecked
  };
}
