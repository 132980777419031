// API/SSD/032

import { API_ROOT } from "consts";

import {
  managedComponentId,
  ComponentPropertiesResponse
} from "./common/storeComponentProperty";

export const getComponentPropertiesUrl = (storeId: string) =>
  `${API_ROOT}/stores/${storeId}/components/${managedComponentId}/properties`;

export type GetComponentPropertiesResponse = ComponentPropertiesResponse;
