import { Flex } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import { cardStyles } from "../../styles";

import MonitorFields from "./MonitorFields";

const Aifi = () => {
  const keyPart = `${EStore.COMPONENTS}.${EComponent.AIFI}`;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.AIFI_ID}`}
        />

        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.ENTRY_ID}`}
        />
      </Flex>

      <Flex w="full" gap="1rem">
        <MonitorFields
          title="State monitor"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.STATE}`}
        />
      </Flex>
    </>
  );
};

export default Aifi;
