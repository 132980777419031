// API/SSD/023

import { API_ROOT } from "consts";

import {
  PosPaperStateValue,
  ComponentNotFoundValue,
  AifiStoreOpenStateValue,
  GenericComponentStateValue,
  StoreComponentId,
  StoreComponentState
} from "./common";

export const batchGetComponentStateUrl = new URL(
  `${API_ROOT}/stores/state/components/state`
);

export type BatchGetComponentStatePayload = {
  storeIdList: string[];
  componentIdList: StoreComponentId[];
};

type StoreComponentNotFound = {
  error: ComponentNotFoundValue;
};

type StoreComponent = {
  componentId: StoreComponentId;
  result:
    | StoreComponentState<
        | PosPaperStateValue
        | AifiStoreOpenStateValue
        | GenericComponentStateValue
      >
    | StoreComponentNotFound;
};

export type BatchGetComponentStateResponse = {
  storeId: string;
  components: StoreComponent[];
};

export function preparePosPaperStatePayload(
  stores: string[]
): BatchGetComponentStatePayload {
  return {
    storeIdList: stores,
    componentIdList: [StoreComponentId.POS_PAPER_STATE]
  };
}

function getPosPaperState(
  components: StoreComponent[]
): PosPaperStateValue | ComponentNotFoundValue {
  const posComponent = components.find(
    component => component.componentId === StoreComponentId.POS_PAPER_STATE
  );

  if (!posComponent || "error" in posComponent.result) {
    return ComponentNotFoundValue.NOT_FOUND;
  }

  return posComponent.result.state as PosPaperStateValue;
}

export function getStoresPosPaperStateMap(
  storesComponentsStateResponse: BatchGetComponentStateResponse[]
): Map<string, PosPaperStateValue | ComponentNotFoundValue> {
  const storesPosPaperState = new Map(
    storesComponentsStateResponse.map(store => [
      store.storeId,
      getPosPaperState(store.components)
    ])
  );
  return storesPosPaperState;
}
