import { EditIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { PathParts } from "routes";

type HeaderWithOptionsProps = {
  isEdit?: boolean;
  title: string;
  additionalButtons?: React.ReactNode[];
  pl?: string;
};

export function HeaderWithOptions({
  isEdit,
  title,
  additionalButtons,
  pl = "0"
}: HeaderWithOptionsProps): JSX.Element {
  return (
    <Flex minWidth="max-content" alignItems="center" gap="2" mb="2rem">
      <Heading fontSize="1.5rem" pl={pl}>
        {title}
      </Heading>
      <Spacer />
      {isEdit && (
        <Button
          as={Link}
          to={PathParts.EDIT}
          leftIcon={<EditIcon />}
          minW="6rem"
        >
          Edytuj
        </Button>
      )}
      {additionalButtons || null}
    </Flex>
  );
}
