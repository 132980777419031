import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const outline = definePartsStyle({
  field: {
    height: "auto",
    fontsize: ".875rem",
    padding: "9px 16px 9px",
    border: "1px solid",
    borderColor: "gray.400 !important",
    borderRadius: ".5rem",
    background: "white",

    _active: {
      borderColor: "blue.dark"
    },

    _focus: {
      borderColor: "blue.dark !important",
      boxShadow: "none  !important"
    },

    _focusVisible: {
      borderColor: "blue.dark !important",
      boxShadow: "none !important"
    }
  },
  icon: {
    fontSize: "1.75rem",
    width: "1.75rem",
    height: "1.75rem",
    fill: "gray.600",
    color: "gray.600"
  }
});

const Select = defineMultiStyleConfig({
  variants: { outline }
});

export default Select;
