import { As, Box } from "@chakra-ui/react";

import { farLeftCellStyle } from "utils/commonStyles";

type TProps = {
  as: As;
  isVisible?: boolean;
  children: React.ReactNode;
};

export function HidableCell({ as, children, isVisible, ...restProps }: TProps) {
  if (isVisible) {
    return (
      <Box as={as} _first={farLeftCellStyle} {...restProps}>
        {children}
      </Box>
    );
  }

  return null;
}
