import { Icon, Link, LinkProps } from "@chakra-ui/react";
import { LucideIcon } from "lucide-react";
import { NavLink } from "react-router-dom";

export const anchorStyles: LinkProps = {
  transition: "color 0.3s",
  _hover: {
    color: "blue.dark",
    svg: { color: "blue.dark" }
  },
  _activeLink: {
    color: "blue.dark",
    svg: { color: "blue.dark" }
  },
  alignItems: "center",
  display: "flex"
} as const;

export type PageLinkProps = LinkProps & {
  name: string;
  icon: LucideIcon;
  to: string;
  onClick?: () => void;
};

export function PageLink({
  name,
  icon,
  to,
  onClick,
  ...otherProps
}: PageLinkProps) {
  return (
    <Link
      as={NavLink}
      to={to}
      fontSize="1rem"
      {...anchorStyles}
      {...otherProps}
      onClick={onClick}
    >
      <Icon as={icon} marginRight="0.25rem" />
      {name}
    </Link>
  );
}
