import { StoreChainConfig } from "api/common";

import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

const useGetStoreChains = () => {
  const getStoreChains = useFetchRefactored<
    null,
    { storeChains: StoreChainConfig[] }
  >({
    url: `${API_ROOT}/storeChains`,
    method: "GET",
    queryParams: {
      maxResults: "100"
    }
  });

  return getStoreChains;
};

export default useGetStoreChains;
