import { TDate } from "components/customDatePicker/CustomDatePicker";

export enum EUnavailableStoreReport {
  STARTS_AT = "startsAt",
  ENDS_AT = "endsAt",
  REASON = "reason",
  REASON_TYPE = "reasonType"
}

export type TUnavailableStoreReport = {
  [EUnavailableStoreReport.STARTS_AT]: TDate;
  [EUnavailableStoreReport.ENDS_AT]: TDate;
  [EUnavailableStoreReport.REASON]: string;
  [EUnavailableStoreReport.REASON_TYPE]: string;
};
