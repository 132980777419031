import { ChangeEventHandler } from "react";
import { ChakraStyledOptions, Select } from "@chakra-ui/react";

type TProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  options: { value: string; label: string }[];
} & ChakraStyledOptions;

export function QuerySelect({
  value,
  onChange,
  options,
  ...restProps
}: TProps) {
  return (
    <Select
      value={value}
      onChange={onChange}
      mr="auto"
      width="165px"
      flexGrow="0"
      alignItems="stretch"
      {...restProps}
    >
      {options.map(({ value, label }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Select>
  );
}
