// API/SSD/024

import { API_ROOT } from "consts";

export function validateStoreConfigUrl(storeId: string): URL {
  return new URL(`${API_ROOT}/stores/${storeId}/validate`);
}

type ValidationResult =
  | "OK"
  | "APP_CONFIG_KEY_MISSING"
  | "CONNECTION_ERROR"
  | "AUTH_FAILED"
  | "ERROR";

type PosValidationResult =
  | "MISCONFIGURATION"
  | "STORE_NOT_FOUND"
  | "POS_INOPERATIVE";

type PowerBouncerValidationResult =
  | "OK"
  | "MISCONFIGURATION"
  | "TOKEN_MISSING"
  | "ERROR";

export type ValidateStoreConfigResponse = {
  shortener: ValidationResult;
  synerise: ValidationResult;
  erp?: ValidationResult | "STORE_NOT_FOUND";
  aifi: ValidationResult | "STORE_NOT_FOUND";
  adyen: ValidationResult | "TERMINAL_NOT_FOUND" | "TERMINAL_OFFLINE";
  pos: ValidationResult | PosValidationResult;
  infokiosk: ValidationResult | "SCOPE_NOT_FOUND";
  powerBouncer?: PowerBouncerValidationResult;
};
