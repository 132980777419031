import { labels } from "./constants";
import { LabelsType } from "./types";

export const getLabel = (fragment: string, labels: LabelsType) =>
  labels[fragment] || fragment;

export const getBreadCrumbs = (
  fragments: string[],
  uniqueLabels?: LabelsType
) =>
  fragments.map((fragment, index, fragments) => {
    return {
      isCurrentPage: index === fragments.length - 1,
      label: getLabel(fragment, {
        ...labels,
        ...uniqueLabels
      }),
      path: fragments.slice(0, index + 1).join("/")
    };
  });
