// API/SSD/025

import { API_ROOT, DEFAULT_RESULTS_PER_PAGE } from "consts";

import { StoreChainConfig } from "./common";

const listStoreChainConfigsUrl = new URL(`${API_ROOT}/storeChains`);

type ListStoreChainConfigsQueryParams = {
  maxResults?: string;
  nextPageMarker?: string;
};

export type ListStoreChainConfigsResponse = {
  nextPageMarker?: string;
  storeChains: StoreChainConfig[];
};

export function prepareUrlWithSearchParams({
  maxResults = DEFAULT_RESULTS_PER_PAGE,
  nextPageMarker
}: ListStoreChainConfigsQueryParams): URL {
  const params: ListStoreChainConfigsQueryParams = {
    maxResults
  };

  if (nextPageMarker) {
    params.nextPageMarker = nextPageMarker;
  }

  listStoreChainConfigsUrl.search = new URLSearchParams(params).toString();

  return listStoreChainConfigsUrl;
}
