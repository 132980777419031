import { useState } from "react";
import { EditIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { useField } from "formik";

import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";

import { CustomInput, CustomInputProps } from "./CustomInput";

type TEditableField = CustomInputProps & {
  errorMessage?: string;
  labelSize?: string;
};

export const EditableField = (props: TEditableField) => {
  const [isEditable, setIsEditable] = useState(false);
  const [, { touched: isTouched }] = useField(props.name);

  const handleEdit = () => setIsEditable(true);

  if (isEditable || !props.placeholder)
    return (
      <FieldWrapper {...props}>
        <CustomInput {...props} isMasked />
      </FieldWrapper>
    );

  return (
    <FormControl isInvalid={isTouched && !!props.errorMessage}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <InputGroup>
        <Input readOnly value={props.placeholder} />
        <InputRightElement>
          <IconButton
            onClick={handleEdit}
            icon={<EditIcon />}
            variant="solid"
            h="2rem"
            w="2rem"
            aria-label="edycja"
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};
