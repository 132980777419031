import { useCallback, useEffect, useState } from "react";
import { StoreChainConfig } from "api/common";
import {
  ListStoreChainConfigsResponse,
  prepareUrlWithSearchParams
} from "api/listStoreChainConfigs";
import { useFetch } from "hooks";

export function useStoreChainsData(): {
  isLoading: boolean;
  storeChains: StoreChainConfig[];
  error: boolean;
  isMoreStoreChains: boolean;
  fetchMoreStoreChains: () => void;
} {
  const { fetchWithAccessToken, error, isLoading } = useFetch();

  const [storeChains, setStoreChains] = useState<StoreChainConfig[]>([]);
  const [continuationToken, setContinuationToken] = useState<string>();

  const fetchStoreChains = useCallback(
    async function fetchStoreChainsAsync(continuationToken?: string) {
      const url = prepareUrlWithSearchParams({
        nextPageMarker: continuationToken || undefined
      });

      try {
        const response =
          await fetchWithAccessToken<ListStoreChainConfigsResponse>(url);
        const { nextPageMarker, storeChains: chunkStoreChainsApi } =
          await response.json();

        const chunkStoreChains: StoreChainConfig[] = chunkStoreChainsApi.map(
          storeChain => ({
            ...storeChain,
            updatedAt: new Date(storeChain.updatedAt),
            createdAt: new Date(storeChain.createdAt)
          })
        );

        if (continuationToken) {
          setStoreChains(storeChains => [...storeChains, ...chunkStoreChains]);
        } else {
          setStoreChains(() => chunkStoreChains);
        }

        setContinuationToken(nextPageMarker);
      } catch {}
    },
    [fetchWithAccessToken]
  );

  useEffect(() => {
    fetchStoreChains();
  }, [fetchStoreChains]);

  return {
    isLoading,
    storeChains,
    error,
    isMoreStoreChains: !!continuationToken,
    fetchMoreStoreChains: () => fetchStoreChains(continuationToken)
  };
}
