import {
  EHelperQueryParams,
  EQueryParams,
  ERefundRequest,
  ESource,
  EStatus,
  TQueryParams
} from "./methods/useGetComplaints/types";
import { EComplaint, EOrder } from "./methods/useGetComplaints/types";

export const labels = {
  [EComplaint.ID]: "ID Reklamacji",
  [EComplaint.STATUS]: "Status Reklamacji",
  [EComplaint.REFUND_ISSUING_STATUS]: "Zlecono zwrot środków",
  [EComplaint.USER_ID]: "Barcode klienta",
  [EComplaint.EMAIL]: "Email",
  [EComplaint.STORE_ID]: "Sklep",
  [`${EComplaint.ORDER}.${EOrder.AIFI_ID}`]: "AiFi ID zamówienia",
  [`${EComplaint.ORDER}.${EOrder.CASH_AMOUNT}`]: "Kwota pobrana od klienta",
  [EComplaint.CORRECTED_ORDER_CASH_AMOUNT]: "Poprawna kwota rachunku",
  [EComplaint.SOURCE]: "Źródło reklamacji",
  [`${EComplaint.ORDER}.${EOrder.CURRENCY}`]: "Waluta",
  [`${EComplaint.ORDER}.${EOrder.HAS_COUPON}`]: "Naliczona promocja",
  [ESource.MOBILE_APP]: "Aplikacja",
  [ESource.INSTA]: "Formularz",
  [ESource.BACKOFFICE]: "Reklamacja manualna",
  [EQueryParams.COMPLAINT_ID]: "ID reklamacji",
  [EQueryParams.CLIENT_EMAIL]: "Email",
  [EQueryParams.ORDER_AIFI_ID]: "AiFi ID zamówienia",
  [EQueryParams.BARCODE]: "Barcode",
  [EStatus.OPEN]: "Oczekuje",
  [EStatus.IN_PROGRESS]: "W toku",
  [EStatus.APPROVED]: "Zaakceptowana",
  [EStatus.REJECTED]: "Odrzucona",
  [ERefundRequest.ALL]: "Wszystko",
  [ERefundRequest.TRUE]: "Tak",
  [ERefundRequest.FALSE]: "Nie"
};

export const refoundRequestedOptions = [
  {
    value: ERefundRequest.ALL,
    label: labels[ERefundRequest.ALL]
  },
  {
    value: true,
    label: labels[ERefundRequest.TRUE]
  },
  {
    value: false,
    label: labels[ERefundRequest.FALSE]
  }
];

// TODO: this is placeholder - replace it with available stores from API: https://netguru.atlassian.net/browse/ZN-5687
export const storesOptions = [1, 2, 3, 4, 5, 6, 8, 9].map(el => ({
  value: `Store${el}`,
  label: `Sklep #${el}`
}));

export const sourceOptions = [
  ESource.MOBILE_APP,
  ESource.INSTA,
  ESource.BACKOFFICE
].map(key => ({
  value: key,
  label: labels[key]
}));

export const searchOptions = (
  [
    EQueryParams.COMPLAINT_ID,
    EQueryParams.CLIENT_EMAIL,
    EQueryParams.ORDER_AIFI_ID,
    EQueryParams.BARCODE
  ] as const
).map(key => ({ value: key, label: labels[key] }));

export const statusOptions = [
  EStatus.OPEN,
  EStatus.IN_PROGRESS,
  EStatus.APPROVED,
  EStatus.REJECTED
].map(key => ({ value: key, label: labels[key] }));

export const OFFSET = 1;
export const MAX_RESULTS = 30;

export const initialValues: TQueryParams = {
  [EQueryParams.CREATED_AFTER]: "",
  [EQueryParams.CREATED_BEFORE]: "",
  [EQueryParams.STATUS]: undefined,
  [EQueryParams.STORE_ID]: undefined,
  [EQueryParams.REFUND_ISSUING_STATUS]: undefined,
  [EQueryParams.SOURCE]: undefined,
  [EQueryParams.HAS_COUPON]: false,
  [EQueryParams.CLIENT_EMAIL]: "",
  [EQueryParams.ORDER_AIFI_ID]: undefined,
  [EQueryParams.CLIENT_LOYAL_ID]: "",
  [EQueryParams.MAX_RESULTS]: MAX_RESULTS,
  [EQueryParams.OFFSET]: OFFSET,
  [EHelperQueryParams.SEARCH_KEY]: "",
  [EHelperQueryParams.SEARCH_VALUE]: ""
};

export const columns = [
  EComplaint.ID,
  EComplaint.STATUS,
  EComplaint.REFUND_ISSUING_STATUS,
  EComplaint.USER_ID,
  EComplaint.EMAIL,
  EComplaint.STORE_ID,
  `${EComplaint.ORDER}.${EOrder.AIFI_ID}`,
  `${EComplaint.ORDER}.${EOrder.CASH_AMOUNT}`,
  EComplaint.CORRECTED_ORDER_CASH_AMOUNT,
  EComplaint.SOURCE,
  `${EComplaint.ORDER}.${EOrder.CURRENCY}`,
  `${EComplaint.ORDER}.${EOrder.HAS_COUPON}`
];

export const columnsOptions = columns.map(column => ({
  value: column,
  label: labels[column as keyof typeof labels]
}));
