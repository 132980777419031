// API/SSD/033

import { API_ROOT } from "consts";

import {
  managedComponentId,
  ComponentPropertiesResponse,
  ComponentPropertyStatus
} from "./common/storeComponentProperty";

export const method = "PUT";

export const setComponentPropertiesUrl = (storeId: string) =>
  `${API_ROOT}/stores/${storeId}/components/${managedComponentId}/properties`;

export type SetComponentPropertiesPayload = {
  status: ComponentPropertyStatus;
};

export type SetComponentPropertiesResponse = ComponentPropertiesResponse;
