import * as Yup from "yup";

import { EStoreService, EStoreServiceProperty } from "types/configFields";

export const servicesSchema = Yup.object()
  .optional()
  .nullable()
  .shape({
    [EStoreService.ERP]: Yup.object()
      .nullable()
      .shape({
        [EStoreServiceProperty.STORE_ID]: Yup.string().test(
          "required-only-if-system-is-erp",
          function (value, context) {
            const topLevelValues = context.from ? context.from[2] : null;
            const posType = topLevelValues?.value?.components?.pos.type;

            if (posType === "ERP" && !value) {
              return this.createError({
                message:
                  "To pole jest wymagane (typ POS'a został ustawiony jako ERP)",
                path: `${this.path}`
              });
            }

            return true;
          }
        )
      })
  });
