import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { UseCheckboxGroupReturn, useCheckboxGroup } from "@chakra-ui/react";

import { useRowsCheck } from "components/table/rowsSelector/useRowsCheck";

import { useHidable } from "../components/table/columnSwitcher/useHidable";

type ChakraUICheckboxProps = ReturnType<
  typeof useCheckboxGroup
>["getCheckboxProps"];

export const TableContext = createContext<{
  getIsVisible: (column: string) => boolean;
  getCheckboxProps: ChakraUICheckboxProps;
  getRowsCheckboxProps: ChakraUICheckboxProps;
  toggleCheckAllRows: () => void;
  checkedRows: (string | number)[];
  isAllChecked: boolean;
  setValue: Dispatch<SetStateAction<(string | number)[]>>;
}>({
  getIsVisible: () => true,
  getCheckboxProps: () => ({}) as UseCheckboxGroupReturn,
  getRowsCheckboxProps: () => ({}) as UseCheckboxGroupReturn,
  toggleCheckAllRows: () => {},
  checkedRows: [],
  isAllChecked: false,
  setValue: () => {}
});
export const useTableContext = () => useContext(TableContext);

type TProps = {
  children: React.ReactNode;
  defaultVisibleColumns?: string[];
  rows?: string[];
};

export function TableContextProvider({
  children,
  defaultVisibleColumns = [],
  rows = []
}: TProps) {
  const { getCheckboxProps, getIsVisible, setValue } = useHidable({
    defaultVisibleColumns
  });
  const {
    getRowsCheckboxProps,
    toggleCheckAllRows,
    checkedRows,
    isAllChecked
  } = useRowsCheck({
    rows
  });

  return (
    <TableContext.Provider
      value={{
        getIsVisible,
        getCheckboxProps,
        getRowsCheckboxProps,
        toggleCheckAllRows,
        checkedRows,
        isAllChecked,
        setValue
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
