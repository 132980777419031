import { Flex } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { EComponent, EComponentProperty, EStore } from "types/configFields";

import MonitorFields from "./MonitorFields";

const Adyen = () => {
  const keyPart = `${EStore.COMPONENTS}.${EComponent.ADYEN}`;

  return (
    <>
      <Flex maxWidth="40rem" direction="column">
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.TERMINAL_ID}`}
        />
      </Flex>

      <Flex w="full" gap="1rem">
        <MonitorFields
          title="Availability monitor"
          monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
        />
      </Flex>
    </>
  );
};

export default Adyen;
