import { ValidateStoreConfigResponse } from "api/validateStoreConfig";

type ValidationStatuses<T extends keyof ValidateStoreConfigResponse> = Record<
  Required<ValidateStoreConfigResponse>[T],
  string
>;

const shortenerValidationStatuses: ValidationStatuses<"shortener"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "shortener jest poprawnie skonfigurowany i udało się nawiązać połączenie"
};

const syneriseValidationStatuses: ValidationStatuses<"synerise"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "Synerise jest poprawnie skonfigurowany i udało się nawiązać połączenie"
};

const erpValidationStatuses: ValidationStatuses<"erp"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "Synerise jest poprawnie skonfigurowany i udało się nawiązać połączenie",
  STORE_NOT_FOUND: "nie znaleziono sklepu o podanym AiFi ID"
};

const aifiValidationStatuses: ValidationStatuses<"aifi"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "AiFi jest poprawnie skonfigurowany i udało się nawiązać połączenie",
  STORE_NOT_FOUND: "nie znaleziono sklepu o podanym AiFi ID"
};

const adyenValidationStatuses: ValidationStatuses<"adyen"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "Adyen jest poprawnie skonfigurowany i udało się nawiązać połączenie z terminalem",
  TERMINAL_NOT_FOUND: "nie znaleziono terminalu o podanym identyfikatorze",
  TERMINAL_OFFLINE: "nie udało się nawiązać połączenia z terminalem"
};

const posValidationStatuses: ValidationStatuses<"pos"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  MISCONFIGURATION: "Typ POSa nie odpowiada konfiguracji danego komponentu",
  OK: "POS jest poprawnie skonfigurowany i udało się nawiązać połączenie",
  POS_INOPERATIVE:
    "nawiązano połączenie z system POS, natomiast POS znajduje się w nieprawidłowym stanie",
  STORE_NOT_FOUND: "nie znaleziono sklepu w systemie POS"
};

const infokioskValidationStatuses: ValidationStatuses<"infokiosk"> = {
  APP_CONFIG_KEY_MISSING:
    "nie znaleziono podanego klucza w App Config / Key Vault",
  AUTH_FAILED: "niepoprawne dane autoryzacyjne podane w konfiguracji",
  CONNECTION_ERROR:
    "nie udało się nawiązać połączenia pod podanym adresem (lub nastąpił timeout)",
  ERROR: "nieokreślony błąd",
  OK: "Infokiosk jest poprawnie skonfigurowany i udało się nawiązać połączenie",
  SCOPE_NOT_FOUND: "nierozpoznany identyfikator infokiosku"
};

const powerBouncerValidationStatuses: ValidationStatuses<"powerBouncer"> = {
  ERROR: "nieokreślony błąd",
  MISCONFIGURATION: "Brakuje danych konfiguracyjnych",
  OK: "power-bouncer jest poprawnie skonfigurowany",
  TOKEN_MISSING:
    "nie znaleziono tokena komunikacyjnego z aplikacją -> aplikacja nie dokonała rejestracji"
};

type ValidationField = {
  [T in keyof Required<ValidateStoreConfigResponse>]: {
    key: T;
    statuses: ValidationStatuses<T>;
    label: string;
  };
}[keyof ValidateStoreConfigResponse];

export const validationsFields: ValidationField[] = [
  {
    key: "shortener",
    label: "Shortener",
    statuses: shortenerValidationStatuses
  },
  {
    key: "synerise",
    label: "Synerise",
    statuses: syneriseValidationStatuses
  },
  {
    key: "erp",
    label: "ERP",
    statuses: erpValidationStatuses
  },
  {
    key: "aifi",
    label: "AiFi",
    statuses: aifiValidationStatuses
  },
  {
    key: "adyen",
    label: "Adyen",
    statuses: adyenValidationStatuses
  },
  {
    key: "pos",
    label: "POS",
    statuses: posValidationStatuses
  },
  {
    key: "infokiosk",
    label: "Infokiosk",
    statuses: infokioskValidationStatuses
  },
  {
    key: "powerBouncer",
    label: "Power-Bouncer",
    statuses: powerBouncerValidationStatuses
  }
];
