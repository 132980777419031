import { Dispatch, SetStateAction, useEffect } from "react";
import { Accordion, Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { isProduction } from "utils";

import FieldGenerationContextProvider from "contexts/FieldGenerationContext";

import FieldsFooter from "./components/FieldsFooter";
import { labels } from "./labels";
import Components from "./sections/Components";
import GeneralInfo from "./sections/GeneralInfo";
import OpeningSchedule from "./sections/OpeningSchedule";
import Parameters from "./sections/Parameters";
import Services from "./sections/Services";

export const sectionStyles = {
  direction: "column",
  gap: "2rem",
  marginBottom: "2rem"
} as const;

export const stickyHeadingStyles = {
  width: "100%",
  fontSize: "1rem",
  fontWeight: "bold",
  padding: "1rem",
  position: "sticky",
  top: "0",
  backgroundColor: "white",
  borderRadius: "5px",
  zIndex: 9999,
  boxShadow: "10px 10px 10px 0px #00000011"
} as const;

interface IFieldsProps {
  isReadOnly: boolean;
  isEdit?: boolean;
  isLoading: boolean;
  expandedIndices: number[];
  setExpandedIndices: Dispatch<SetStateAction<number[]>>;
}

const Fields = ({
  isReadOnly,
  isEdit,
  isLoading,
  expandedIndices,
  setExpandedIndices
}: IFieldsProps) => {
  const { errors } = useFormikContext();

  // Temp console to detect legacy validaiton errors sometimes
  // preventing submission on different envs. To remove when it's resolved.
  useEffect(() => {
    if (!isProduction()) {
      console.log(errors);
    }
  }, [errors]);

  const handleAccordionChange = (indices: number[] | number) => {
    if (typeof indices === "number") {
      return;
    }

    setExpandedIndices(indices);
  };

  return (
    <FieldGenerationContextProvider
      isReadOnly={isReadOnly}
      labels={labels}
      isEdit={isEdit}
    >
      <Flex direction="column" gap="1rem">
        <Accordion
          allowMultiple
          onChange={indices => handleAccordionChange(indices)}
          index={expandedIndices}
        >
          <GeneralInfo />
          <Parameters />
          <Services />
          <Components />
          <OpeningSchedule />
        </Accordion>

        {!isReadOnly && (
          <FieldsFooter
            isLoading={isLoading}
            setExpandedIndices={setExpandedIndices}
          />
        )}
      </Flex>
    </FieldGenerationContextProvider>
  );
};

export default Fields;
