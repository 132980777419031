import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text
} from "@chakra-ui/react";

import { menuItemStyles, navigationStructure } from "../consts";
import { headers, labels } from "../labels";

import FormattedText from "./FormattedText";

const accordionMap = new Map([
  ["parameters", 0],
  ["services", 1],
  ["components", 2],
  ["openingSchedule", 3]
]);

type TSearchPanelProps = {
  setExpandedIndices: Dispatch<SetStateAction<number[]>>;
};

const SearchPanel = ({ setExpandedIndices }: TSearchPanelProps) => {
  const [search, setSearch] = useState("");
  const [fields, setFields] = useState({});

  const handleClickClear = () => setSearch("");

  const handleAccordionOpen = (index: number) => {
    setExpandedIndices(prevIndices =>
      prevIndices.includes(index) ? prevIndices : [...prevIndices, index]
    );
  };

  const handleShowFields = (name: string) => {
    let field: HTMLElement;
    field =
      (document.getElementById(name) as HTMLElement) ||
      (document.getElementById(`accordion-button-${name}`) as HTMLElement);

    const fieldsWrapper = document.getElementById("storeChain-wrapper");
    const isAccordionItem = field?.id.includes("accordion-button");

    if (isAccordionItem) {
      const currentAccordionPanel = document.getElementById(
        `accordion-panel-${name}`
      );
      const firstFieldInCurrentAccordionPanel = currentAccordionPanel
        ?.children[0] as HTMLElement;

      if (firstFieldInCurrentAccordionPanel) {
        field = firstFieldInCurrentAccordionPanel;
      }
    }

    if (!field) {
      field = document.querySelector(
        `[data-searchid="${name}"]`
      ) as HTMLElement;
    }

    if (!field) {
      return;
    }

    setTimeout(() => {
      if (fieldsWrapper) {
        fieldsWrapper.scrollTo({
          top: field.offsetTop - (isAccordionItem ? 75 : 90),
          behavior: "smooth"
        });
      }
    }, 100);
  };

  const handleSearchPanelItemClick = (name: string) => {
    const section = name.split(".")[0];
    const accordionIndex = accordionMap.get(section);

    if (accordionIndex !== undefined && accordionIndex >= 0) {
      handleAccordionOpen(accordionIndex);
    }
    handleShowFields(name);
  };

  useEffect(() => {
    if (!search) {
      setFields({});
      return;
    }
    const fields = Object.fromEntries(
      Object.entries(labels).filter(([key, value]) => {
        const isVisible = document.querySelector(
          `[data-searchid="${key}"]`
        ) as HTMLElement;

        return (
          isVisible &&
          (key.toLowerCase().includes(search.toLowerCase()) ||
            value.toLowerCase().includes(search.toLowerCase()))
        );
      })
    );

    setFields(fields);
  }, [search]);

  return (
    <Box
      width="20%"
      minWidth="20rem"
      position="sticky"
      top="0"
      overflow="auto"
      border="1px solid "
      borderColor="gray.400"
      marginRight="1rem"
      backgroundColor="white"
      borderRadius=".5rem"
    >
      <Flex direction="column" padding="1rem">
        <Accordion
          allowToggle
          border="1px solid"
          borderColor="gray.400"
          borderRadius=".5rem"
        >
          <AccordionItem>
            <AccordionButton padding=".5rem">
              <Text>Sekcje</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel padding="0">
              <Box>
                {Object.entries(navigationStructure).map(([key, value]) => {
                  return (
                    <Box key={key}>
                      <Text
                        {...menuItemStyles}
                        onClick={() => handleSearchPanelItemClick(key)}
                      >
                        {(headers as any)[key]}
                      </Text>
                      {(value as any).subSections &&
                        Object.entries((value as any).subSections).map(
                          ([key, value]) => (
                            <Text
                              {...menuItemStyles}
                              pl="1.5rem"
                              key={key}
                              onClick={() => handleSearchPanelItemClick(key)}
                            >
                              {(headers as any)[key]}
                            </Text>
                          )
                        )}
                    </Box>
                  );
                })}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Divider marginBlock="1rem" />

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.dark" w="1.125rem" />
          </InputLeftElement>
          <Input
            paddingLeft="2.5rem"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Szukaj"
            marginBottom="1rem"
          />
          <InputRightElement width="3rem">
            {!!search && (
              <CloseButton
                onClick={handleClickClear}
                size="sm"
                color="red.600"
              />
            )}
          </InputRightElement>
        </InputGroup>
        <Box as="ul">
          {Object.entries(fields).map(([key, value]) => (
            <Flex
              key={key}
              direction="column"
              fontSize=".8rem"
              onClick={() => handleSearchPanelItemClick(key)}
              padding=".5rem"
              _hover={{
                cursor: "pointer",
                backgroundColor: "gray.200"
              }}
            >
              <FormattedText
                {...{ search, text: key }}
                fontSize=".7rem"
                color="gray.600"
              />
              <FormattedText {...{ search, text: value as string }} />
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default SearchPanel;
