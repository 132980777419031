import { Flex } from "@chakra-ui/react";
import { AppConfigKey } from "api/listAppConfigKeys";

import { Property } from "components/property";

import { fieldLabels } from "../constants";

type PreviewContentProps = {
  appConfigKey: AppConfigKey;
  isLoading: boolean;
};

export function PreviewContent({
  isLoading,
  appConfigKey
}: PreviewContentProps) {
  const { createdAt, updatedAt, key: configKey, ...restProps } = appConfigKey;

  return (
    <Flex gap="4rem">
      <Flex direction="column" gap="1rem">
        {Object.entries(restProps).map(([key, value]) => (
          <Property key={key} label={fieldLabels[key]} isLoading={isLoading}>
            {value}
          </Property>
        ))}

        {Object.entries(configKey).map(([key, value]) => (
          <Property key={key} label={fieldLabels[key]} isLoading={isLoading}>
            {value}
          </Property>
        ))}
      </Flex>
      <Flex direction="column" gap="1rem">
        {Object.entries({ createdAt, updatedAt }).map(([key, value]) => (
          <Property key={key} label={fieldLabels[key]} isLoading={isLoading}>
            {value}
          </Property>
        ))}
      </Flex>
    </Flex>
  );
}
