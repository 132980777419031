import { ComponentStyleConfig } from "@chakra-ui/react";

const Badge: ComponentStyleConfig = {
  defaultProps: {
    variant: "outline"
  },
  baseStyle: {
    fontSize: "0.75rem",
    borderRadius: "0.25rem",
    lineHeight: "1.5rem",
    textAlign: "center"
  },
  variants: {
    red: {
      color: "red.dark",
      backgroundColor: "red.light"
    },
    green: {
      color: "green.dark",
      backgroundColor: "green.light"
    },
    purple: {
      color: "purple.dark",
      backgroundColor: "purple.light"
    },
    gray: {
      color: "gray.dark",
      backgroundColor: "gray.200"
    }
  }
};

export default Badge;
