export enum EProperty {
  IDENTIFIER = "identifier",
  IDENTIFIERS = "identifiers",
  RESULT = "result",
  ERROR = "error"
}

export enum EIdentifier {
  PRODUCT_ID = "productId",
  BARCODE = "barcode",
  AIFI_ID = "aifiId",
  EXTERNAL_ID = "externalId"
}

export type TRequest = {
  [EProperty.IDENTIFIERS]: (
    | { [EIdentifier.PRODUCT_ID]: string }
    | { [EIdentifier.BARCODE]: string }
    | { [EIdentifier.AIFI_ID]: string }
    | { [EIdentifier.EXTERNAL_ID]: string }
  )[];
};

export enum EProductDetails {
  ID = "id",
  AIFI_ID = "aifiId",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  BARCODE = "barcode",
  EXTERNAL_PRODUCT_ID = "externalProductId",
  DEFAULT_NAME = "defaultName",
  WEIGHT = "weight",
  DEFAULT_PRICE = "defaultPrice",
  LOCALIZED_NAMES = "localizedNames",
  LANGUAGE = "language",
  NAME = "name",
  IMAGE_URL = "imageUrl",
  RESTRICTIONS = "restrictions",
  MAX_COUNT_IN_CART = "maxCountInCart"
}

export type TProductDetails = {
  [EProductDetails.ID]: string;
  [EProductDetails.AIFI_ID]: string;
  [EProductDetails.CREATED_AT]: string;
  [EProductDetails.UPDATED_AT]: string;
  [EProductDetails.BARCODE]: string;
  [EProductDetails.EXTERNAL_PRODUCT_ID]: string;
  [EProductDetails.DEFAULT_NAME]: string;
  [EProductDetails.WEIGHT]: string;
  [EProductDetails.DEFAULT_PRICE]: number;
  [EProductDetails.LOCALIZED_NAMES]: {
    [EProductDetails.LANGUAGE]: string;
    [EProductDetails.NAME]: string;
  };
  [EProductDetails.IMAGE_URL]: string;
  [EProductDetails.RESTRICTIONS]: {
    [EProductDetails.MAX_COUNT_IN_CART]: string;
  };
};

export type TResponse = {
  [EProperty.IDENTIFIER]: EIdentifier;
  [EProperty.RESULT]: TProductDetails & {
    [EProperty.ERROR]?: string;
  };
};
