import { testIsTime } from "utils/dateTime";

import { EFieldType } from "./FormField";

const formatTimeString = (value?: string) => {
  if (!value) {
    return "";
  }
  let date;

  if (testIsTime(value)) {
    date = new Date();
    const [isoHours, isoMinutes, isoSeconds] = value.split(":").map(Number);
    date.setHours(isoHours, isoMinutes, isoSeconds ? isoSeconds : 0, 0);
  } else {
    date = new Date(value);
  }

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  if (testIsTime(value)) {
    return `${hours}:${minutes}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};

const formatDateString = (value: string, isYearVisible?: boolean) => {
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    const options: Intl.DateTimeFormatOptions = {
      year: isYearVisible ? "numeric" : undefined,
      day: "numeric",
      month: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    };

    return date.toLocaleString("en-GB", options);
  }
  return value;
};

export const getDisplayValue = (
  value: unknown,
  fieldType: EFieldType,
  options?: { isYearVisible?: boolean }
) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === "boolean" || typeof value === "number") {
    return String(value);
  }

  if (value instanceof Date && !isNaN(value.valueOf())) {
    return value.toLocaleString();
  }

  if (typeof value === "string") {
    if (fieldType === EFieldType.DATE || fieldType === EFieldType.DATETIME) {
      return formatDateString(value, options?.isYearVisible);
    }

    if (fieldType === EFieldType.TIME) {
      return formatTimeString(value);
    }
  }

  return String(value);
};
