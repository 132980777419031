import { LabelsType } from "./types";

export const labels: LabelsType = {
  stores: "Sklepy",
  "app-config-keys": "Klucze konfiguracyjne",
  "store-chains": "Sieci sklepów",
  complaints: "Reklamacje",
  create: "Nowy",
  edit: "Edycja"
};
