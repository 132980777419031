import { toggleCommaStringToArray } from "utils";

import { CardIssuerAction } from "types/configFields";

export const cardIssuerActionsMapper = (
  cardIssuerActions?: CardIssuerAction[],
  isResponse?: boolean
) => {
  if (!cardIssuerActions) {
    return [];
  }

  return cardIssuerActions.map(({ bins, brands, countryCodes, action }) => {
    const toggledBins = toggleCommaStringToArray(bins);
    const toggledBrands = toggleCommaStringToArray(brands);
    const toggledCountryCodes = toggleCommaStringToArray(countryCodes);

    if (isResponse) {
      return {
        bins: bins ? toggledBins : "",
        brands: brands ? toggledBrands : "",
        countryCodes: countryCodes ? toggledCountryCodes : "",
        action
      };
    } else {
      return {
        bins: bins ? toggledBins : [],
        brands: brands ? toggledBrands : [],
        countryCodes: countryCodes ? toggledCountryCodes : [],
        action
      };
    }
  });
};
