export enum StoreComponentId {
  POS_AVAILABILITY = "posAvailability",
  POS_PAPER_STATE = "posPaperState",
  TERMINAL_AVAILABILITY = "terminalAvailability",
  INFOKIOSK_AVAILABILITY = "infokioskAvailability",
  POWER_BOUNCER_AVAILABILITY = "powerBouncerAvailability",
  POWER_BOUNCER_QR_READER_AVAILABILITY = "powerBouncerQrReaderAvailability",
  AIFI_STORE_OPEN = "aifiStoreOpen"
}

export type StoreComponentState<
  StateValue extends
    | PosPaperStateValue
    | AifiStoreOpenStateValue
    | GenericComponentStateValue
> = {
  state: StateValue;
  revisionId: string;
  updatedAt: string;
  probedAt: string;
  heartbeatAt: string;
};

export enum ComponentNotFoundValue {
  NOT_FOUND = "NOT_FOUND"
}

export enum PosPaperStateValue {
  OK = "OK",
  NEAR_END = "NEAR_END",
  NO_PAPER = "NO_PAPER"
}

export enum AifiStoreOpenStateValue {
  OPEN = "OPEN",
  CLOSED = "CLOSED"
}

export enum GenericComponentStateValue {
  OK = "OK",
  OUTAGE = "OUTAGE"
}
