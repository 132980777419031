import {
  ComponentNotFoundValue,
  PosPaperStateValue,
  StoreComponentId
} from "api/common/storeComponentState";
import { ComponentStateValue, ComponentsState } from "api/common/storeState";

import { StateColorScheme } from "consts";

export const posPaperStateNames: Record<
  PosPaperStateValue | ComponentNotFoundValue,
  {
    stateLabel: string;
    colorScheme: StateColorScheme;
  }
> = {
  [PosPaperStateValue.OK]: {
    stateLabel: "OK",
    colorScheme: StateColorScheme.OK
  },
  [PosPaperStateValue.NEAR_END]: {
    stateLabel: "Koniec papieru",
    colorScheme: StateColorScheme.WARNING
  },
  [PosPaperStateValue.NO_PAPER]: {
    stateLabel: "Brak papieru",
    colorScheme: StateColorScheme.ERROR
  },
  [ComponentNotFoundValue.NOT_FOUND]: {
    stateLabel: "Brak komponentu",
    colorScheme: StateColorScheme.OTHER
  }
};

const visibleHealthchecks = [
  StoreComponentId.AIFI_STORE_OPEN,
  StoreComponentId.INFOKIOSK_AVAILABILITY,
  StoreComponentId.POS_AVAILABILITY,
  StoreComponentId.TERMINAL_AVAILABILITY
] as const;

const healthchecksNames: {
  [K in (typeof visibleHealthchecks)[number]]: string;
} = {
  [StoreComponentId.AIFI_STORE_OPEN]: "AiFi",
  [StoreComponentId.INFOKIOSK_AVAILABILITY]: "Nanovo",
  [StoreComponentId.POS_AVAILABILITY]: "POS",
  [StoreComponentId.TERMINAL_AVAILABILITY]: "Adyen"
};

type StoreComponentProperty = {
  stateLabel: string;
  colorScheme: StateColorScheme;
};

const healthchecksStateNames: Record<
  ComponentStateValue | ComponentNotFoundValue,
  StoreComponentProperty
> = {
  [ComponentStateValue.OK]: {
    stateLabel: "OK",
    colorScheme: StateColorScheme.OK
  },
  [ComponentStateValue.NOT_OK]: {
    stateLabel: "Błąd",
    colorScheme: StateColorScheme.ERROR
  },
  [ComponentNotFoundValue.NOT_FOUND]: {
    stateLabel: "Brak komponentu",
    colorScheme: StateColorScheme.OTHER
  }
};

export function getStoreComponentsProperties(
  components: ComponentsState
): (StoreComponentProperty & {
  name: string;
})[] {
  return visibleHealthchecks.map(healthcheck => {
    if (!components?.[healthcheck]) {
      return {
        name: healthchecksNames[healthcheck],
        ...healthchecksStateNames[ComponentNotFoundValue.NOT_FOUND]
      };
    }
    return {
      name: healthchecksNames[healthcheck],
      ...healthchecksStateNames[components[healthcheck] as ComponentStateValue]
    };
  });
}
