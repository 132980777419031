// API/SSD/014

import { API_ROOT } from "consts";

import { StoreChainConfig } from "./common";

export function getStoreChainConfigUrl(chainId: string): URL {
  return new URL(`${API_ROOT}/storeChains/${chainId}`);
}

export type GetStoreChainConfigResponse = StoreChainConfig;
