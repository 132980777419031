import { Flex, Text } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import { EProperty, ESchedule, EStore } from "types/configFields";

import { labels } from "../labels";

import StoreChainField from "./StoreChainField";

type TProps = { day: string };

const OpeningHoursTile = ({ day }: TProps) => {
  const key = `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`;

  return (
    <Flex key={key} direction="column" gap="1rem">
      <Text mb="0">{labels[`day.${day}` as keyof typeof labels]}</Text>

      <StoreChainField
        fieldType={EFieldType.TIME}
        name={`${key}.${EProperty.OPENS_AT}`}
      />

      <StoreChainField
        fieldType={EFieldType.TIME}
        name={`${key}.${EProperty.CLOSES_AT}`}
      />
    </Flex>
  );
};

export default OpeningHoursTile;
