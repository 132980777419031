type AnyObject = Record<string, any>;

export const convertNullsToEmptyStrings = (
  obj: AnyObject | null
): AnyObject => {
  if (obj === null) {
    return {};
  }

  if (typeof obj !== "object" || Array.isArray(obj)) {
    return obj;
  }

  const result: AnyObject = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      result[key] = "";
    } else if (typeof value === "object" && !Array.isArray(value)) {
      result[key] = convertNullsToEmptyStrings(value);
    } else {
      result[key] = value;
    }
  }

  return result;
};
