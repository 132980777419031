// API/SSD/006

import { API_ROOT } from "consts";

export function openStoreUrl(storeId: string) {
  return new URL(`${API_ROOT}/stores/${storeId}/state/open`);
}

type OpenStorePayload = {
  userId: string;
  reason: string;
  complete: boolean;
};

export type OpenStoreResponse = {
  revisionId: string;
};

export function prepareOpenStorePayload(
  username: string,
  reason: string,
  complete: boolean
): OpenStorePayload {
  return { userId: username, reason, complete };
}
