import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { FieldArray, useFormikContext } from "formik";
import { CirclePlusIcon } from "lucide-react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import AdjustDay from "pages/store/sections/openingSchedule/AdjustDayTile";
import CloseDayTile from "pages/store/sections/openingSchedule/CloseDayTile";
import {
  noDataStyles,
  stickyHeadingStyles,
  tilesContainerStyles
} from "pages/store/styles";
import { EDay, ESchedule, EStore, TStore } from "types/configFields";

import OpeningHoursTile from "../components/OpeningHoursTile";
import StoreChainField from "../components/StoreChainField";
import { emptyAdjustedDay } from "../consts";

const OpeningSchedule = () => {
  const { values } = useFormikContext<TStore>();
  const { labels, isReadOnly } = useFieldGenerationContext();

  const adjustedDays =
    values?.[EStore.OPENING_SCHEDULE]?.[ESchedule.EXCEPTIONS]?.[
      ESchedule.ADJUSTED_DAYS
    ] || [];

  const closeDays =
    values?.[EStore.OPENING_SCHEDULE]?.[ESchedule.EXCEPTIONS]?.[
      ESchedule.CLOSED_DAYS
    ] || [];

  return (
    <AccordionItem id="openingSchedule">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Harmonogram otwarcia sklepu
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox p="1rem">
          <StoreChainField
            name={`${EStore.OPENING_SCHEDULE}.${ESchedule.IS_ENABLED}`}
            fieldType={EFieldType.CHECKBOX}
          />

          <Text
            id={`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mt="2rem"
            mb="1rem"
          >
            Bazowy tygodniowy harmonogram
          </Text>

          <SimpleGrid gap="2rem" columns={{ sm: 1, md: 2, lg: 3 }}>
            {Object.values(EDay).map(day => (
              <OpeningHoursTile key={day} day={day} />
            ))}
          </SimpleGrid>

          <Text
            id={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mt="4rem"
          >
            Wyjątki w bazowym harmonogramie
          </Text>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mt="1rem"
            minH="2.5rem"
          >
            <Text
              fontSize=".8rem"
              fontWeight="bold"
              m="0"
              id={`${EStore.OPENING_SCHEDULE}.${ESchedule.ADJUSTED_DAYS}`}
            >
              {labels[`${EStore.OPENING_SCHEDULE}.${ESchedule.ADJUSTED_DAYS}`]}
            </Text>
          </Flex>
          <FieldArray
            name={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.ADJUSTED_DAYS}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push(emptyAdjustedDay)}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    m="0"
                    mb="1rem"
                  >
                    Dodaj inne godziny
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb="0.5rem">
                  {adjustedDays.map((_exception, index) => (
                    <AdjustDay
                      index={index}
                      key={index}
                      onRemove={() => remove(index)}
                    />
                  ))}
                  {!adjustedDays.length && (
                    <Text {...noDataStyles}>Brak innych godzin otwarcia</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            mt="1rem"
            minH="2.5rem"
          >
            <Text
              fontSize=".8rem"
              fontWeight="bold"
              m="0"
              id={`${EStore.OPENING_SCHEDULE}.${ESchedule.CLOSED_DAYS}`}
            >
              {labels[`${EStore.OPENING_SCHEDULE}.${ESchedule.CLOSED_DAYS}`]}
            </Text>
          </Flex>

          <FieldArray
            name={`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.CLOSED_DAYS}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push("")}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    m="0"
                    mb="1rem"
                  >
                    Dodaj inne godziny
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb=".5rem">
                  {closeDays.map((_exception, index) => (
                    <CloseDayTile
                      index={index}
                      key={index}
                      onRemove={() => remove(index)}
                    />
                  ))}
                  {!closeDays.length && (
                    <Text {...noDataStyles}>Brak dni zamknięcia</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default OpeningSchedule;
