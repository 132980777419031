export enum ESource {
  MOBILE_APP = "MOBILE_APP",
  INSTA = "INSTA",
  BACKOFFICE = "BACKOFFICE"
}

export enum EStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export enum ETrack {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC"
}

export enum ERefundIssuingStatus {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
  NONE = "NONE"
}

export enum EOrder {
  HAS_COUPON = "hasCoupon",
  AIFI_ID = "aifiId",
  CASH_AMOUNT = "cashAmount",
  CURRENCY = "currency",
  PRE_AUTH_CAPTURED_AMOUNT = "preAuthCapturedAmount",
  CAPTURED_AMOUNT = "capturedAmount"
}

export enum ECurrency {
  PLN = "PLN",
  EUR = "EUR"
}

export type TOrder = {
  [EOrder.HAS_COUPON]: boolean;
  [EOrder.AIFI_ID]: string;
  [EOrder.CASH_AMOUNT]: number;
  [EOrder.CURRENCY]: ECurrency;
  [EOrder.PRE_AUTH_CAPTURED_AMOUNT]: number;
  [EOrder.CAPTURED_AMOUNT]: number;
};

export enum EClient {
  LOYAL_ID = "loyalId",
  PHONE = "phone",
  EMAIL = "email",
  CRM_ID = "crmId"
}

export enum EComplaintPreview {
  AIFI_COMPLAINT_ID = "aifiComplaintId",
  CLIENT = "client",
  REVIEWED_AT = "reviewedAt",
  CONTENT = "content",
  CONTACT = "contact",
  REVIEW_RESULT = "reviewResult",
  USER_ID = "userId",
  ID = "id",
  ORDER_ID = "orderId",
  ORDER = "order",
  STORE_ID = "storeId",
  STORE = "store",
  LOYAL_ID = "loyalId",
  EMAIL = "email",
  SOURCE = "source",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  STATUS = "status",
  TRACK = "track",
  REFUND_ISSUING_STATUS = "refundIssuingStatus",
  CORRECTED_ORDER_CASH_AMOUNT = "correctedOrderCashAmount",
  PRODUCTS = "products",
  HAS_COUPON = "hasCoupon"
}

export enum EProductComplaintType {
  INVALID_QUANTITY = "INVALID_QUANTITY",
  PRODUCT_PROBLEM = "PRODUCT_PROBLEM",
  INVALID_PRICE = "INVALID_PRICE",
  OTHER = "OTHER"
}

export enum EContent {
  PRODUCTS = "products",
  MISSING_PRODUCTS = "missingProducts",
  RECEIPT = "receipt",
  MESSAGE = "message"
}

export enum EReviewResult {
  REVIEWED_PRODUCTS = "reviewedProducts",
  CASH_AMOUNT = "cashAmount",
  REFUND = "refund",
  REFUNDS = "refunds"
}

export enum ERefundStatus {
  ISSUED = "ISSUED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED"
}

export enum EIssuingStatus {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
  NONE = "NONE"
}

export enum EProperty {
  PRODUCT_ID = "productId",
  COMPLAINT = "complaint",
  DESCRIPTION = "description",
  BODY = "body",
  EMAIL = "email",
  QUANTITY = "quantity",
  AMOUNT = "amount",
  TOTAL_AMOUNT = "totalAmount",
  STATUS = "status",
  ISSUING_STATUS = "issuingStatus",
  CONFIRMED_AT = "confirmedAt",
  PRICE = "price",
  TYPE = "type",
  CORRECTED_QUANTITY = "correctedQuantity",
  PROBLEM = "problem",
  AFFECTED_QUANTITY = "affectedQuantity",
  IMAGES = "images",
  EXTERNAL_ID = "externalId"
}

export enum EPrice {
  TOTAL = "total",
  SALE = "sale",
  CASH = "cash",
  REBATE = "rebate",
  LOYALTY_POINTS = "loyaltyPoints"
}

export enum EProducts {
  DISPLAY_NAME = "displayName",
  BARCODE = "barcode",
  PRICE = "price",
  QUANTITY = "quantity"
}

export enum EComplaint {
  COMPLAINT_ID = "complaintId",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  REVIEWED_AT = "reviewedAt",
  STATUS = "status",
  CONTENT = "content"
}

export enum EComplaintContent {
  PRODUCTS = "products",
  MISSING_PRODUCTS = "missingProducts",
  RECEIPT = "receipt",
  MESSAGE = "message",
  CONTACT = "contact",
  REFUND_AMOUNT = "refundAmount"
}

export enum EComplaintProduct {
  PRODUCT_ID = "productId",
  COMPLAINT = "complaint"
}

export enum EProductProblemProblem {
  DAMAGED = "DAMAGED",
  EXPIRED = "EXPIRED",
  INVALID_PREPARATION = "INVALID_PREPARATION"
}

export enum EProductInvalidPriceProblem {
  DIFF_THEN_PRICE_TAG = "DIFF_THEN_PRICE_TAG",
  DIFF_THEN_PROMOTION = "DIFF_THEN_PROMOTION",
  MULTI_PROMOTION_NOT_APPLIED = "MULTI_PROMOTION_NOT_APPLIED"
}

export type TProductComplaint =
  | {
      [EProperty.TYPE]: EProductComplaintType.INVALID_QUANTITY;
      [EProperty.CORRECTED_QUANTITY]: number;
    }
  | {
      [EProperty.TYPE]: EProductComplaintType.PRODUCT_PROBLEM;
      [EProperty.PROBLEM]: EProductProblemProblem;
      [EProperty.AFFECTED_QUANTITY]: number;
    }
  | {
      [EProperty.TYPE]: EProductComplaintType.INVALID_PRICE;
      [EProperty.PROBLEM]: EProductInvalidPriceProblem;
      [EProperty.AFFECTED_QUANTITY]: number;
    }
  | {
      [EProperty.TYPE]: EProductComplaintType.OTHER;
      [EProperty.DESCRIPTION]: string;
    };

export type TProduct = {
  [EProducts.DISPLAY_NAME]: string;
  [EProducts.BARCODE]: string;
  [EProducts.PRICE]: {
    [EPrice.TOTAL]: number;
    [EPrice.CASH]: number;
    [EPrice.LOYALTY_POINTS]: number;
  };
  [EProducts.QUANTITY]: number;
};

export type TComplaintPreview = {
  [EComplaintPreview.ID]: string;
  [EComplaintPreview.ORDER_ID]: string;
  [EComplaintPreview.ORDER]: TOrder;
  [EComplaintPreview.STORE_ID]: string;
  [EComplaintPreview.STORE]: {
    [EProperty.EXTERNAL_ID]: string;
  };
  [EComplaintPreview.USER_ID]: string;
  [EComplaintPreview.CLIENT]: {
    [EClient.LOYAL_ID]: string;
    [EClient.PHONE]: string;
    [EClient.EMAIL]: string;
    [EClient.CRM_ID]: string;
  };
  [EComplaintPreview.AIFI_COMPLAINT_ID]: string;
  [EComplaintPreview.CREATED_AT]: string;
  [EComplaintPreview.UPDATED_AT]: string;
  [EComplaintPreview.REVIEWED_AT]: string;
  [EComplaintPreview.STATUS]: EStatus;
  [EComplaintPreview.TRACK]: ETrack;
  [EComplaint.CONTENT]: {
    [EComplaintContent.PRODUCTS]?: {
      [EComplaintProduct.PRODUCT_ID]: string;
      [EComplaintProduct.COMPLAINT]: TProductComplaint;
    }[];
    [EComplaintContent.MISSING_PRODUCTS]?: {
      [EProperty.DESCRIPTION]: string;
    };
    [EComplaintContent.RECEIPT]?: {
      [EProperty.DESCRIPTION]: string;
    };
    [EComplaintContent.MESSAGE]?: {
      [EProperty.BODY]: string;
      [EProperty.IMAGES]?: string[];
    };
    [EComplaintContent.CONTACT]: {
      [EProperty.EMAIL]: string;
    };
    [EComplaintContent.REFUND_AMOUNT]: number;
  };
  [EComplaintPreview.CONTACT]: {
    [EProperty.EMAIL]: string;
  };
  [EComplaintPreview.REVIEW_RESULT]: {
    [EReviewResult.REVIEWED_PRODUCTS]: {
      [EProperty.PRODUCT_ID]: string;
      [EProperty.QUANTITY]: number;
      [EProperty.PRICE]: {
        [EPrice.TOTAL]: number;
        [EPrice.SALE]: number;
        [EPrice.CASH]: number;
        [EPrice.REBATE]: number;
      };
    }[];
    [EReviewResult.CASH_AMOUNT]: number;
    [EReviewResult.REFUND]: {
      [EProperty.AMOUNT]: number;
      [EProperty.TOTAL_AMOUNT]: number;
      [EProperty.STATUS]: ERefundStatus;
      [EProperty.ISSUING_STATUS]: EIssuingStatus;
      [EProperty.CONFIRMED_AT]: string;
    };
    [EReviewResult.REFUNDS]: {
      [EProperty.AMOUNT]: number;
      [EProperty.STATUS]: ERefundStatus;
      [EProperty.CONFIRMED_AT]: string;
    }[];
  };
  [EComplaintPreview.SOURCE]: ESource;
  [EComplaintPreview.PRODUCTS]: TProduct[];
  [EComplaintPreview.HAS_COUPON]: boolean;
};
