import { TStore } from "types/configFields";

import { componentsMapper } from "./components";
import { openingScheduleMapper } from "./openingSchedule";
import { parametersMapper } from "./parameters";

export const responseMapper = (response: TStore): TStore => {
  const { openingSchedule, parameters, components, ...restResponse } = response;

  const mappedParameters = parametersMapper(parameters);
  const mappedComponents = componentsMapper(components);
  const mappedSchedule = openingScheduleMapper(openingSchedule, true);

  return {
    ...restResponse,
    parameters: mappedParameters,
    components: mappedComponents,
    openingSchedule: mappedSchedule
  };
};
