import {
  StoreIcon,
  WrenchIcon,
  ZapIcon,
  NetworkIcon,
  KeyRoundIcon,
  MessageCircleWarningIcon
  // ReceiptIcon,
  // MessageCircleQuestionIcon,
  // SquareUserRoundIcon,
  // ShoppingBagIcon,
  // CreditCardIcon,
  // ScanBarcodeIcon,
  // PackageIcon,
} from "lucide-react";
import { isProduction } from "utils";

import { Routes } from "routes";
import { EditablePermissions, ReadOnlyPermissions } from "hooks/roles/enums";

import { TNavigationLink, TParentTab } from "./types";

const isProd = isProduction();

export const navigation: (TNavigationLink | TParentTab)[] = [
  {
    label: "Sklepy",
    icon: StoreIcon,
    to: Routes.STORES,
    permissions: [
      ReadOnlyPermissions.canReadStores,
      EditablePermissions.canEditStores
    ]
  },
  {
    label: "Narzędzia konfiguracyjne",
    icon: WrenchIcon,
    children: [
      {
        label: "Sieci sklepów",
        icon: NetworkIcon,
        to: Routes.STORE_CHAINS,
        permissions: [
          ReadOnlyPermissions.canReadStoreChains,
          EditablePermissions.canEditStoreChains
        ]
      },
      {
        label: "Klucze konfiguracyjne",
        icon: KeyRoundIcon,
        to: Routes.APP_CONFIG_KEYS,
        permissions: [EditablePermissions.canEditAppConfigKeys]
      }
    ]
  },
  // Uncomment when pages are created
  // {
  //   label: "Obsługa klienta",
  //   icon: ReceiptIcon,
  //   children: [
  //     {
  //       label: "Reklamacje",
  //       icon: MessageCircleQuestionIcon,
  //       to: Routes.COMPLAINTS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadComplaints,
  //         EditablePermissions.canAcceptComplaints,
  //       ],
  //     },
  //     {
  //       label: "Klienci",
  //       icon: SquareUserRoundIcon,
  //       to: Routes.CLIENTS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadStoreChains,
  //         EditablePermissions.canEditStoreChains,
  //       ],
  //     },
  //     {
  //       label: "Zamówienia",
  //       icon: ShoppingBagIcon,
  //       to: Routes.ORDERS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadStoreChains,
  //         EditablePermissions.canEditStoreChains,
  //       ],
  //     },
  //     {
  //       label: "Sesje",
  //       icon: ScanBarcodeIcon,
  //       to: Routes.SESSIONS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadStoreChains,
  //         EditablePermissions.canEditStoreChains,
  //       ],
  //     },
  //     {
  //       label: "Płatności",
  //       icon: CreditCardIcon,
  //       to: Routes.PAYMENTS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadStoreChains,
  //         EditablePermissions.canEditStoreChains,
  //       ],
  //     },
  //     {
  //       label: "Produkty",
  //       icon: PackageIcon,
  //       to: Routes.PRODUCTS,
  //       permissions: [
  //         ReadOnlyPermissions.canReadStoreChains,
  //         EditablePermissions.canEditStoreChains,
  //       ],
  //     },
  //   ],
  // },

  ...(!isProd
    ? [
        {
          label: "Reklamacje",
          icon: MessageCircleWarningIcon,
          to: Routes.COMPLAINTS,
          permissions: [ReadOnlyPermissions.canReadComplaints]
        }
      ]
    : []),
  {
    label: "Energetyki",
    icon: ZapIcon,
    to: Routes.ENERGY_DRINKS,
    permissions: [
      EditablePermissions.canLockEnergyDrinks,
      EditablePermissions.canChangeEnergyDrinksServiceMode,
      ReadOnlyPermissions.canReadEnergyDrinks
    ]
  }
];
