// API/SSD/013

import { API_ROOT } from "consts";

import { StoreConfig } from "./common";

export function getStoreConfigUrl(storeId: string): URL {
  return new URL(`${API_ROOT}/stores/${storeId}`);
}

export type GetStoreConfigResponse = StoreConfig;
