import { extendTheme } from "@chakra-ui/react";

import Accordion from "./components/accordion";
import Alert from "./components/alert";
import Badge from "./components/badge";
import Button from "./components/button";
import Card from "./components/card";
import Checkbox from "./components/checkbox";
import Heading from "./components/heading";
import Input from "./components/input";
import Modal from "./components/modal";
import Select from "./components/select";
import Spinner from "./components/spinner";
import Switch from "./components/switch";
import Text from "./components/text";
import Textarea from "./components/textarea";
import { colors, fonts } from "./themeConsts";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    white: colors.WHITE,
    black: colors.BLACK,
    blue: {
      dark: colors.BLUE_DARK,
      medium: colors.BLUE_MEDIUM,
      light: colors.BLUE_LIGHT
    },
    red: { dark: colors.RED_DARK, light: colors.RED_LIGHT },
    purple: {
      dark: colors.PURPLE_DARK,
      light: colors.PURPLE_LIGHT
    },
    green: {
      dark: colors.GREEN_DARK,
      light: colors.GREEN_LIGHT
    },
    yellow: {
      dark: colors.YELLOW_DARK,
      light: colors.YELLOW_LIGHT
    },
    gray: {
      dark: colors.GRAY_DARK,
      medium: colors.GRAY_MEDIUM,
      light: colors.GRAY_LIGHT,
      50: colors.GRAY_50,
      200: colors.GRAY_200,
      400: colors.GRAY_400,
      500: colors.GRAY_500,
      600: colors.GRAY_600,
      800: colors.GRAY_800
    }
  },
  breakpoints: {
    sm: "560px",
    md: "640px",
    lg: "780px",
    xl: "1000px"
  },
  fonts: {
    heading: fonts.heading,
    body: fonts.body
  },
  components: {
    Alert,
    Accordion,
    Badge,
    Button,
    Card,
    Checkbox,
    Input,
    Modal,
    Select,
    Spinner,
    Switch,
    Text,
    Textarea,
    Heading
  },
  styles: {
    global: {
      html: {
        scrollbarGutter: "stable"
      },
      body: {
        bg: colors.GRAY_50
      }
    }
  },
  fontSizes: {
    "4xl": "1.75rem"
  }
});

export default theme;
