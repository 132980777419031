import { Flex, Text } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import { headerStyles } from "pages/store/styles";
import { EMonitor } from "types/configFields";

import StoreChainField from "./StoreChainField";

type TProps = {
  monitorPath: string;
  title?: string;
};

const MonitorFields = ({ monitorPath, title }: TProps) => {
  const getMonitorPath = (key: string) => `${monitorPath}.${key}`;
  const getActionPath = (key: string) =>
    `${monitorPath}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${key}`;

  return (
    <Flex
      direction="column"
      gap="1.5rem"
      padding="1rem"
      border="1px solid"
      borderColor="gray.400"
      borderRadius="1rem"
      maxWidth="50%"
    >
      {title && (
        <Text {...headerStyles} fontSize="14px">
          {title}
        </Text>
      )}
      <StoreChainField
        fieldType={EFieldType.CHECKBOX}
        name={getMonitorPath(EMonitor.IS_ENABLED)}
      />

      <StoreChainField
        fieldType={EFieldType.CHECKBOX}
        name={getMonitorPath(EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS)}
      />

      <StoreChainField
        fieldType={EFieldType.CHECKBOX}
        name={getActionPath(EMonitor.IS_ACTIVE)}
      />

      <StoreChainField
        fieldType={EFieldType.TEXT}
        name={getActionPath(EMonitor.CLOSE_THRESHOLD)}
      />

      <StoreChainField
        fieldType={EFieldType.TEXT}
        name={getActionPath(EMonitor.CLOSE_PERIOD)}
      />

      <StoreChainField
        fieldType={EFieldType.TEXT}
        name={getActionPath(EMonitor.OPEN_THRESHOLD)}
      />

      <StoreChainField
        fieldType={EFieldType.TEXT}
        name={getActionPath(EMonitor.OPEN_PERIOD)}
      />
    </Flex>
  );
};

export default MonitorFields;
