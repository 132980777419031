import { Tab } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { LucideIcon } from "lucide-react";

import { Routes } from "routes";

import { PageLink } from "./PageLink";
import { TNavigationLink } from "./types";

const tabStyles = {
  flexDirection: {
    base: "column",
    xl: "row"
  },
  sx: {
    svg: {
      height: "1.5rem",
      width: "1.5rem",
      color: "gray.600",
      marginRight: { base: "0", lg: "0.25rem" }
    }
  }
} as const;

export type NavigationTabProps = {
  label: string;
  icon: LucideIcon;
  to?: Routes;
  children?: TNavigationLink[];
};

export function NavigationTab({
  label,
  icon,
  children,
  to
}: NavigationTabProps) {
  return (
    <Tab
      position="relative"
      bottom="-1px"
      padding="0.625rem 1rem"
      border="1px solid transparent"
      borderTopRadius="0.5rem"
      {...tabStyles}
      _selected={{
        bg: "white",
        borderColor: "gray.400",
        borderBottomColor: "white"
      }}
    >
      {children && (
        <>
          <Icon as={icon} />
          {label}
        </>
      )}

      {to && <PageLink icon={icon} name={label} to={to} {...tabStyles} />}
    </Tab>
  );
}
