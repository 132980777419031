import { Button, Flex } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import { Trash2 } from "lucide-react";

import { FieldCustomDatePicker } from "components/customDatePicker/FieldCustomDatePicker";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { Property } from "components/property";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { ESchedule, EStore, TStore } from "types/configFields";

import { tileStyles } from "../../styles";

type TProps = {
  index: number;
  onRemove: () => void;
};

const CloseDayTile = ({ index, onRemove }: TProps) => {
  const { errors, values } = useFormikContext<TStore>();
  const { isReadOnly } = useFieldGenerationContext();
  const closeDaysKey = `${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}.${ESchedule.CLOSED_DAYS}.${index}`;

  if (isReadOnly)
    return (
      <Flex key={index} {...tileStyles}>
        <Property id={closeDaysKey} label="data">
          {getIn(values, closeDaysKey)}
        </Property>
      </Flex>
    );

  return (
    <Flex key={index} {...tileStyles}>
      <FieldWrapper
        name={closeDaysKey}
        label="data"
        w="100%"
        errorMessage={getIn(errors, closeDaysKey)}
      >
        <FieldCustomDatePicker key={closeDaysKey} name={closeDaysKey} />
      </FieldWrapper>

      <Button
        onClick={onRemove}
        variant="ghost"
        colorScheme="red"
        color="red.dark"
        width="min-content"
        marginLeft="auto"
        leftIcon={<Trash2 size="1rem" />}
      >
        Usuń
      </Button>
    </Flex>
  );
};

export default CloseDayTile;
