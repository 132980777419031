import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "blue",
    size: "sm",
    variant: "outline"
  },
  baseStyle: {
    borderRadius: "full",
    _disabled: {
      opacity: ".2",
      filter: "brightness(80%)"
    },
    _active: { filter: "brightness(90%)" }
  },
  sizes: {
    sm: {
      height: 10,
      fontSize: ".875rem",
      lineHeight: 5,
      padding: "2.5 9",
      borderRadius: "10px"
    },
    md: {
      height: 12,
      fontSize: "1rem",
      lineHeight: 5,
      padding: "4 9"
    }
  },
  variants: {
    solidPrimary: {
      bg: "blue.dark",
      color: "white"
    },
    solidSecondary: {
      bg: "gray.200",
      color: "gray.800"
    },
    outlinePrimary: {
      bg: "transparent",
      color: "blue.dark",
      border: "1px solid",
      borderColor: "blue.dark",
      _active: { bg: "gray.200", filter: "brightness(90%)" }
    },
    outlineSecondary: {
      bg: "transparent",
      color: "gray.dark",
      border: "1px solid",
      borderColor: "gray.400",
      _active: { bg: "gray.200", filter: "brightness(90%)" }
    },
    outlineRed: {
      bg: "transparent",
      color: "red.dark",
      border: "1px solid",
      borderColor: "red.dark",
      _active: { bg: "gray.200", filter: "brightness(90%)" }
    },
    ghostPrimary: {
      bg: "transparent",
      color: "blue.dark",
      fontWeight: "bold"
    },
    ghostSecondary: {
      bg: "transparent",
      color: "gray.dark",
      fontWeight: "bold"
    },
    ghostRed: {
      bg: "transparent",
      color: "red.dark",
      fontWeight: "bold"
    }
  }
};

export default Button;
