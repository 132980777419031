import {
  EClient,
  EComplaintPreview,
  EPrice,
  EProducts
} from "./methods/useGetComplaint/types";
import { EComplaintsStats } from "./methods/useGetComplaintStats/types";
import { Bill } from "./tabs/Bill";
import { ClientData } from "./tabs/ClientData";
import { Complaint } from "./tabs/Complaint";
import { CorrectBill } from "./tabs/correctBill/CorrectBill";
import { Orders } from "./tabs/Orders";
import { PriceList } from "./tabs/PriceList";

export const tabs = [
  { label: "Reklamacja", component: Complaint },
  { label: "Dane klienta", component: ClientData },
  { label: "Zamówienie", component: Orders },
  { label: "Cennik", component: PriceList },
  {
    label: "Rachunek, który otrzymał klient ",
    component: Bill
  },
  { label: "Poprawny rachunek", component: CorrectBill }
];

export const labels = {
  [EComplaintPreview.ID]: "ID reklamacji",
  [EComplaintPreview.CREATED_AT]: "Data zgłoszenia reklamacji",
  [EComplaintPreview.UPDATED_AT]: "Data ostatniej modyfikacji",
  [EComplaintPreview.STATUS]: "Status reklamacji",
  [EComplaintPreview.TRACK]: "Sposób rozpatrzenia reklamacji",
  [EComplaintPreview.SOURCE]: " Źródło reklamacji",
  [EComplaintPreview.CONTENT]: " Wiadomość użytkownika",

  [`${EComplaintPreview.CLIENT}.${EClient.LOYAL_ID}`]: "Barcode",
  [`${EComplaintPreview.CLIENT}.${EClient.PHONE}`]: "Numer telefonu",
  [`${EComplaintPreview.CLIENT}.${EClient.EMAIL}`]: "Email",
  [`${EComplaintPreview.CLIENT}.${EClient.CRM_ID}`]: "Synerise ID",
  [`${EComplaintPreview.CLIENT}.${EComplaintsStats.COUNT}`]:
    "Liczba reklamacji klienta"
};

export const billTableHeadLabels = [
  {
    key: `${EProducts.DISPLAY_NAME}`,
    value: "Nazwa produktu"
  },
  { key: `${EProducts.BARCODE}`, value: "EAN produktu" },
  {
    key: `${EProducts.PRICE}.${EPrice.TOTAL}`,
    value: "Cena produktu"
  },
  {
    key: `${EProducts.PRICE}.${EPrice.CASH}`,
    value: "Kwota pobrana od klienta"
  },
  {
    key: `${EProducts.PRICE}.${EPrice.LOYALTY_POINTS}`,
    value: "Naliczone żappsy"
  },
  { key: `${EProducts.QUANTITY}`, value: "Liczba sztuk" }
];
