import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useField } from "formik";

import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { cardStyles } from "pages/store/styles";

interface TogglableFormPartProps {
  name: string;
  headerText: string;
  headerSize?: string;
  children: ReactNode;
  emptyInitialValue?: any;
}

const determineIfFieldHasValue = (value: any) => {
  if (value === true || value === false) {
    return true;
  }

  if (value === null || value === undefined) {
    return false;
  }

  return true;
};

const TogglableFormPart = ({
  name,
  headerText,
  headerSize,
  emptyInitialValue,
  children
}: TogglableFormPartProps) => {
  const [field, , { setValue }] = useField(name);
  const value = field.value;
  const hasValue = determineIfFieldHasValue(value);

  const { isReadOnly } = useFieldGenerationContext();
  const [lockedValue, setLockedValue] = useState<any>(value);
  const [isChecked, setIsChecked] = useState(hasValue);

  const hasLockedValue = determineIfFieldHasValue(lockedValue);

  const handleChange = () => {
    if (isChecked) {
      setLockedValue(value);
      setValue(null);
    } else {
      if (hasLockedValue) {
        setValue(lockedValue);
      } else if (!hasValue) {
        setValue(emptyInitialValue);
      }

      setLockedValue(null);
    }
    setIsChecked(prev => !prev);
  };

  useEffect(() => {
    if (!isReadOnly && !hasValue) {
      setValue(null);
    }
  }, [hasValue, isReadOnly, setValue, value]);

  if (isReadOnly) {
    return (
      <Flex {...cardStyles} data-searchid={name} gap={0}>
        {headerText && (
          <Text fontSize="1rem" fontWeight="bold" mb="1.5rem">
            {headerText}
          </Text>
        )}
        {children}
      </Flex>
    );
  }
  return (
    <Flex {...cardStyles} data-searchid={name} gap={0}>
      {!isReadOnly && (
        <Flex alignItems="center" mb="0.5rem">
          <Switch
            size="md"
            colorScheme="blue"
            isChecked={isChecked}
            onChange={handleChange}
          />
          <FormLabel htmlFor={name} mb="0" ml="1rem" cursor="pointer">
            {`Sekcja ${isChecked ? "włączona" : "wyłączona"}`}
          </FormLabel>
        </Flex>
      )}
      {headerText && (
        <Text
          fontSize={headerSize ? headerSize : "1rem"}
          fontWeight="bold"
          mb="1.5rem"
        >
          {headerText}
        </Text>
      )}

      <Flex
        _before={
          !isChecked
            ? {
                content: "''",
                border: "1px dashed gray",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                background:
                  "repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.15) 10px, rgba(0, 0, 0, 0.15) 20px)",
                opacity: 0.6,
                zIndex: 1
              }
            : undefined
        }
        position="relative"
        pointerEvents={!isChecked ? "none" : "all"}
        zIndex={1}
        flexDir="column"
        alignItems="flex-start"
        width="100%"
      >
        {!isChecked && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={2}
          >
            <Box
              p="10px 15px"
              textAlign="center"
              alignContent="center"
              background="linear-gradient(90deg, rgba(28,0,0,0.97) 0%, rgba(121,9,9,0.929030987394958) 95%)"
              borderRadius="15px"
              color="red"
              zIndex={3}
            >
              Sekcja wyłączona
            </Box>
          </Flex>
        )}
        {children}
      </Flex>
    </Flex>
  );
};

export default TogglableFormPart;
