import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    display: "flex",
    flexDirection: "column",
    ".chakra-collapse": {
      overflow: "unset !important"
    }
  },
  container: {
    border: "0"
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    padding: ".875rem .5rem .375rem 1rem",
    textAlign: "initial",
    color: "black",
    border: "1px solid",
    borderColor: "transparent",

    _hover: {
      background: "gray.50",
      border: "1px solid",
      borderColor: "blue.dark"
    }
  },
  panel: {
    fontsize: ".75rem"
  },
  icon: {
    width: "1.75rem",
    height: "1.75rem"
  }
});

const framed = definePartsStyle({
  container: {
    border: "1px solid",
    borderColor: "gray.400 !important",
    borderRadius: ".5rem",
    boxShadow: "none"
  },
  icon: {
    fill: "gray.600",
    color: "gray.600",
    width: "1.75rem",
    height: "1.75rem"
  }
});

const bill = definePartsStyle({
  container: {
    borderTop: "1px dashed",
    borderColor: "gray.500 !important",

    _last: {
      borderBottom: "none"
    }
  }
});

const Accordion = defineMultiStyleConfig({
  baseStyle,
  variants: { framed, bill }
});

export default Accordion;
