import * as Yup from "yup";

import {
  ECardIssuerActions,
  ECLientPriority,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameterProperty,
  EParameterPropertySmsThreshold,
  EParameters,
  EParameterSmsLanguage,
  EPrefixConfigFields,
  EPrefixFields,
  PaymentMethodFields
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const actionsMinimumOneFieldRequired =
  "Co najmniej jedno z pól bins, brands, countryCodes jest wymagane";

const cardIssuerActionSchema = Yup.object()
  .shape({
    [ECardIssuerActions.BINS]: Yup.string(),
    [ECardIssuerActions.BRANDS]: Yup.string(),
    [ECardIssuerActions.COUNTRY_CODES]: Yup.string(),
    [EIssuerActionProperty.ACTION]: Yup.object().shape({
      [EIssuerActionProperty.TYPE]: Yup.string().required(requiredMessage),
      [EIssuerActionProperty.PREAUTH_AMOUNT]: Yup.number()
        .nullable()
        .when(EIssuerActionProperty.TYPE, {
          is: (value: string) => value === EIssuerActionType.OVERRIDE_PREAUTH,
          then(schema) {
            return schema.required(requiredMessage);
          },
          otherwise(schema) {
            return schema;
          }
        })
    })
  })
  .test(
    "required bins, brands, countryCodes",
    actionsMinimumOneFieldRequired,
    function (values) {
      const { bins, brands, countryCodes } = values;

      if (!bins && !brands && !countryCodes) {
        return new Yup.ValidationError([
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            bins,
            `${this.path}.bins`
          ),
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            brands,
            `${this.path}.brands`
          ),
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            countryCodes,
            `${this.path}.countryCodes`
          )
        ]);
      }

      return true;
    }
  );

const smsLanguagesSchema = Yup.object().shape({
  [EParameterSmsLanguage.PREFIX]: Yup.string().required(requiredMessage),
  [EParameterSmsLanguage.LANGUAGE]: Yup.string().required(requiredMessage)
});

const ordersRulesSchema = Yup.object().shape({
  [ECLientPriority.ORDERS_THRESHOLD]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
});

const periodRulesSchema = Yup.object().shape({
  [ECLientPriority.STARTS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.ENDS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
});

const retryPaymentMethodsSchema = Yup.object().shape({
  [PaymentMethodFields.NAME]: Yup.string().required(requiredMessage),
  [PaymentMethodFields.TYPE]: Yup.string().required(requiredMessage)
});

const prefixConfigSchema = Yup.object().shape({
  [EPrefixFields.PREFIX]: Yup.string().required(requiredMessage),
  [EPrefixFields.CHANNEL]: Yup.string().required(requiredMessage)
});

export const parametersSchema = Yup.object().shape({
  [EParameters.PURCHASE_CONFIRMATION_DOC]: Yup.object().shape({
    [EParameterProperty.TYPE]: Yup.string().required(requiredMessage),
    [EParameterProperty.DOMAIN]: Yup.string().required(requiredMessage)
  }),
  [EParameters.DEFAULT_LANGUAGE]: Yup.string().optional(),
  [EParameters.COUNTRY_CODE]: Yup.string().optional(),
  [EParameters.DEFAULT_PREAUTH_AMOUNT]: Yup.number()
    .typeError("Podana wartość musi być liczbą")
    .optional(),
  [EParameters.APP_PREAUTH_ENABLED]: Yup.boolean().optional(),
  [EParameters.CURRENCY]: Yup.string().optional(),
  [EParameters.SMS]: Yup.object().shape({
    [EParameterProperty.PREFIX_CONFIG]: Yup.object()
      .optional()
      .shape({
        [EPrefixConfigFields.DEFAULT_CHANNEL]: Yup.string().test(
          "at-least-one-prefix-required",
          function (value, context) {
            const parent = this.parent;
            const grandparent = context.from ? context.from[1] : null;

            const prefixes = parent.prefixes;

            if (prefixes && prefixes.length > 0 && !value) {
              return this.createError({
                message:
                  "Domyślny kanał wysyłki jest wymagany jeśli dodano co najmniej jedną konfigurację prefixów.",
                path: `${this.path}`
              });
            } else if (grandparent && grandparent.value) {
              const hasPrefixConfig = Object.values(grandparent.value).some(
                smsField => smsField === "PREFIX_CONFIG"
              );

              if (hasPrefixConfig && !value) {
                return this.createError({
                  message:
                    "Co najmniej jedno pole konfiguracji SMS posiada wartość PREFIX_CONFIG. Podaj domyślny kanał wysyłki.",
                  path: `${this.path}`
                });
              }

              return true;
            }

            return false;
          }
        ),
        [EPrefixConfigFields.PREFIXES]: Yup.array().of(prefixConfigSchema)
      }),
    [EParameterPropertySmsThreshold.AUTO_RESCUE_INIT_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.LAST_RESCUE_ATTEMPT_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER1_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER2_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER3_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER4_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER5_SMS_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage)
  }),
  [EParameters.SMS_LANGUAGES]: Yup.array()
    .required()
    .min(1, "Podaj minimum jeden język komunikacji")
    .of(smsLanguagesSchema),
  [EParameters.CARD_ISSUER_ACTIONS]: Yup.array().of(cardIssuerActionSchema),
  [EParameters.TIME_ZONE]: Yup.string().required(requiredMessage),
  [EParameters.CLIENT_PRIORITY]: Yup.object().shape({
    [ECLientPriority.ORDERS_RULES]: Yup.array().of(ordersRulesSchema),
    [ECLientPriority.DAILY_PERIOD_RULES]: Yup.array().of(periodRulesSchema),
    [ECLientPriority.PERIOD_RULES]: Yup.array().of(periodRulesSchema)
  }),
  [EParameters.RETRY_PAYMENT_METHODS]: Yup.array().of(retryPaymentMethodsSchema)
});
