// API/SSD/022

import { API_ROOT } from "consts";

import { StoreState } from "./common";

export type { ComponentsState, StoreState } from "./common";

export const batchGetStoreStateUrl = new URL(`${API_ROOT}/stores/state`);

export type StoreStatePayload = {
  storeIdList: string[];
};

export type StoreStateResponse = {
  storeId: string;
  result: StoreState;
};

export function prepareStoreStatePayload(stores: string[]): StoreStatePayload {
  return {
    storeIdList: stores
  };
}
