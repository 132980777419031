export enum EComplaintResult {
  APPROVED = "approved",
  REJECTED = "rejected"
}

export enum EComplaintDetails {
  REASON = "reason",
  RESULT = "result",
  REFUND_AMOUNT = "refundAmount",
  REVIEWED_BY = "reviewedBy",
  REVIEWED_PRODUCTS = "reviewedProducts"
}

export type TComplaintRejected = {
  [EComplaintDetails.RESULT]: EComplaintResult;
  [EComplaintDetails.REVIEWED_BY]: string;
  [EComplaintDetails.REASON]: string;
};

export type TComplaintApproved = {
  [EComplaintDetails.RESULT]: EComplaintResult;
  [EComplaintDetails.REVIEWED_BY]: string;
  [EComplaintDetails.REVIEWED_PRODUCTS]: string[];
};

export type TPayload = any;

export enum EProcess {
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  REFUND = "REFUND"
}
