import { useRef } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import {
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/react";

type TProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  placeholder?: string;
  type?: string;
};

export function SearchInput({
  value,
  onChange,
  onClear,
  placeholder,
  type = "text",
  ...restProps
}: TProps) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleClickClear = () => {
    onClear();
    searchInputRef.current?.focus();
  };

  return (
    <InputGroup size="md" style={{ flexBasis: "420px", flexGrow: 1 }}>
      <InputLeftElement width="3rem" pointerEvents="none">
        <Search2Icon color="gray.dark" w="1.125rem" />
      </InputLeftElement>
      <Input
        pl="2.5rem"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={searchInputRef}
        type={type}
        {...restProps}
      />
      <InputRightElement width="3rem">
        {value !== "" && (
          <CloseButton onClick={handleClickClear} size="sm" color="red.600" />
        )}
      </InputRightElement>
    </InputGroup>
  );
}
