import * as Yup from "yup";

import { EDay, EProperty, ESchedule } from "types/configFields";

export const requiredMessage = "To pole nie może być puste";

const openingHoursSchema = Yup.object().shape({
  [EProperty.OPENS_AT]: Yup.string(),
  [EProperty.CLOSES_AT]: Yup.string()
});

export const openingScheduleSchema = Yup.object()
  .nullable()
  .optional()
  .shape({
    [ESchedule.IS_ENABLED]: Yup.boolean().optional(),
    [ESchedule.BASE]: Yup.object()
      .shape({
        [EDay.MON]: openingHoursSchema,
        [EDay.TUE]: openingHoursSchema,
        [EDay.WED]: openingHoursSchema,
        [EDay.THU]: openingHoursSchema,
        [EDay.FRI]: openingHoursSchema,
        [EDay.SAT]: openingHoursSchema,
        [EDay.SUN]: openingHoursSchema
      })
      .optional(),
    // Temporarily disabled - to verify later

    // .when(ESchedule.IS_ENABLED, {
    //   is: true,
    //   then: schema => schema.required("Podaj parametry harmonogramu sklepu"),
    //   otherwise: schema => schema.optional()
    // }),
    [ESchedule.EXCEPTIONS]: Yup.object()
      .optional()
      .shape({
        [ESchedule.CLOSED_DAYS]: Yup.array().of(
          Yup.string().required(requiredMessage)
        ),
        [ESchedule.ADJUSTED_DAYS]: Yup.array().of(
          Yup.object().shape({
            [EProperty.DAY]: Yup.string().required(requiredMessage),
            [EProperty.OPENS_AT]: Yup.string().required(requiredMessage),
            [EProperty.CLOSES_AT]: Yup.string().required(requiredMessage)
          })
        )
      })
  });
