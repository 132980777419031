import { useMsal } from "@azure/msal-react";

export function useAuthentication() {
  const msal = useMsal();

  const getUserCurrentData = async () => {
    const [account] = (await msal.instance.getAllAccounts()) || [];

    return account;
  };

  const getAccessToken = async () => {
    const { accessToken } = await msal.instance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SCOPE],
      account: msal.accounts[0]
    });

    return accessToken;
  };

  const callSignIn = async () => {
    const loginRequest = {
      scopes: [process.env.REACT_APP_SCOPE],
      loginHint: msal.accounts[0].username
    };

    await msal.instance.acquireTokenRedirect(loginRequest);
  };

  const getAuthorizationHeader = async () => {
    const accessToken = await getAccessToken();

    return {
      Authorization: `Bearer ${accessToken}`
    };
  };

  return {
    getAccessToken,
    callSignIn,
    getAuthorizationHeader,
    getUserCurrentData
  };
}
