import { createContext, useContext } from "react";

type TProps = {
  children: React.ReactNode;
  isReadOnly?: boolean;
  isCreate?: boolean;
  isEdit?: boolean;
  labels: Record<string, string>;
};

const FieldGenerationContext = createContext<
  Omit<TProps, "children"> | undefined
>(undefined);

export const useFieldGenerationContext = () => {
  const context = useContext(FieldGenerationContext);

  if (!context) {
    throw new Error(
      "useFieldGenerationContext hook must be used within a StoreChainContextProvider"
    );
  }

  return context;
};

const FieldGenerationContextProvider = ({
  children,
  isReadOnly,
  isCreate,
  isEdit,
  labels
}: TProps) => {
  return (
    <FieldGenerationContext.Provider
      value={{
        isReadOnly,
        isCreate,
        isEdit,
        labels
      }}
    >
      {children}
    </FieldGenerationContext.Provider>
  );
};

export default FieldGenerationContextProvider;
