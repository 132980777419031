import { getConfigKeyByIdUrl } from "api/appConfigKey";
import { AppConfigKey } from "api/listAppConfigKeys";

import { useFetchRefactored } from "hooks/useFetchRefactored";

export const useGetAppConfigKey = (keyId = "") => {
  const getFetch = useFetchRefactored<null, AppConfigKey>({
    url: getConfigKeyByIdUrl(keyId),
    method: "GET"
  });

  return getFetch;
};
