import { PosComponent } from "api/common/storeChainConfig";
import { StoreChainComponents } from "api/common/storeChainConfig";
import omit from "lodash/omit";
import { convertCommaStringToObj } from "utils";

import { EPosType, MaintenanceBreak } from "types/configFields";
import { posServiceDatetimeFormatter } from "utils/dateTime";

const getPosValues = (type: EPosType, posValues: PosComponent) => {
  switch (type) {
    case EPosType.ERP:
      return omit(posValues, ["ipos", "posService"]);
    case EPosType.IPOS:
      const iposOnlyValues = omit(posValues, ["posService"]);
      const vatRates = iposOnlyValues.ipos?.vatRates;

      const mappedVatRates = vatRates
        ? convertCommaStringToObj(vatRates)
        : null;

      return {
        ...iposOnlyValues,
        ipos: {
          vatRates: mappedVatRates
        }
      };
    case EPosType.POS_SERVICE:
      const posServiceOnlyValues = omit(posValues, ["ipos"]);

      const maintenanceBreaks =
        posServiceOnlyValues.posService?.maintenanceBreaks;

      const mappedMaintenanceBreaks =
        maintenanceBreaks && maintenanceBreaks.length > 0
          ? maintenanceBreaks.map((item: MaintenanceBreak) => ({
              startsAt: posServiceDatetimeFormatter(item.startsAt, item.type),
              endsAt: posServiceDatetimeFormatter(item.endsAt, item.type)
            }))
          : null;

      return {
        ...posServiceOnlyValues,
        posService: {
          ...posServiceOnlyValues.posService,
          maintenanceBreaks: mappedMaintenanceBreaks
        }
      };
    default:
      return omit(posValues, ["ipos", "posService"]);
  }
};

export const componentsMapper = (
  components: StoreChainComponents
): StoreChainComponents => {
  return {
    ...components,
    pos: components?.pos?.type
      ? getPosValues(components.pos.type, components.pos)
      : components?.pos
  };
};
