import {
  Box,
  Skeleton,
  Text,
  BoxProps,
  Link as ChakraLink
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export type PropertyProps = BoxProps & {
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isLink?: boolean;
  href?: string;
};

export function Property({
  children,
  label,
  isLoading = false,
  isDisabled = false,
  isLink,
  href,
  ...boxProps
}: PropertyProps): JSX.Element {
  const isLinkAvailable = isLink && href;
  const content =
    typeof children === "string" ? (
      <Text fontSize="1rem" lineHeight="1.5rem">
        {children}
      </Text>
    ) : (
      children
    );

  return (
    <Box {...boxProps}>
      <Text
        fontSize="0.75rem"
        lineHeight="1.2rem"
        color="gray.600"
        wordBreak="normal"
      >
        {label}
      </Text>
      <Skeleton isLoaded={!isLoading} minWidth="4rem" minHeight="1.5rem">
        {isLinkAvailable ? (
          <ChakraLink as={Link} to={href}>
            {content}
          </ChakraLink>
        ) : (
          <Box color={isDisabled ? "gray.600" : "black"}>{content}</Box>
        )}
      </Skeleton>
    </Box>
  );
}
