export const sensitiveDataFields = ["apiKey", "token"];

export const fieldLabels: { [key: string]: string } = {
  id: "ID",
  accountId: "accountId",
  createdAt: "Data utworzenia",
  updatedAt: "Data ostatniej edycji",
  name: "Nazwa",
  key: "Klucz: ",
  type: "Typ",
  "key.baseUrl": "Base URL",
  "key.posServiceUrl": "POS Service URL",
  "key.terminalUrl": "Terminal URL",
  appConfigReference: "Nazwa klucza ustawiana w AppConfiguration",
  apiKey: "API key",
  apiKeyMask: "API key",
  token: "Token",
  tokenMask: "Token"
};
