import { API_ROOT } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";
import { TStore } from "types/configFields";

export const useUpdateStore = (storeId = "") => {
  const getFetch = useFetchRefactored<TStore, TStore>({
    url: `${API_ROOT}/stores/${storeId}`,
    method: "PUT"
  });

  return getFetch;
};
