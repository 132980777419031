// API/SSD/036

import { API_ROOT } from "consts";

import { StoreConfig } from "./common";

export function setStoreConfigUrl(storeId: string): URL {
  return new URL(`${API_ROOT}/stores/${storeId}`);
}

export type SetStoreConfigPayload = Omit<
  StoreConfig,
  "storeId" | "updatedAt" | "createdAt"
>;

export type SetStoreConfigResponse = StoreConfig;
