import { Flex, Text } from "@chakra-ui/react";

import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import { EProperty, ESchedule, EStore } from "types/configFields";

import { label } from "../../labels";

type TProps = { day: string };

const OpeningHoursTile = ({ day }: TProps) => {
  const key = `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`;

  return (
    <Flex key={key} direction="column" gap="1rem">
      <Text mb="0">{label[`day.${day}` as keyof typeof label]}</Text>

      <StoreField
        fieldType={EFieldType.TIME}
        name={`${key}.${EProperty.OPENS_AT}`}
      />

      <StoreField
        fieldType={EFieldType.TIME}
        name={`${key}.${EProperty.CLOSES_AT}`}
      />
    </Flex>
  );
};

export default OpeningHoursTile;
