import { StoreChainParameters } from "api/common/storeChainConfig";

import {
  ClientPriority,
  ECLientPriority,
  PeriodRule
} from "types/configFields";
import { timeFormatter } from "utils/dateTime";

import { cardIssuerActionsMapper } from "../common/cardIssuerActionsMapper";

const dailyPeriodRulesMapper = (dailyPeriodRules?: PeriodRule[]) => {
  if (!dailyPeriodRules) {
    return;
  }

  return dailyPeriodRules.map(rule => ({
    [ECLientPriority.STARTS_AT]: timeFormatter(
      rule[ECLientPriority.STARTS_AT],
      true
    ),
    [ECLientPriority.ENDS_AT]: timeFormatter(
      rule[ECLientPriority.ENDS_AT],
      true
    ),
    [ECLientPriority.PRIORITY]: rule[ECLientPriority.PRIORITY]
  }));
};

const clientPriorityMapper = (clientPriority?: Nullable<ClientPriority>) => {
  if (!clientPriority) {
    return;
  }

  const mappedDailyPeriodRules = dailyPeriodRulesMapper(
    clientPriority?.dailyPeriodRules
  );

  return {
    ...clientPriority,
    dailyPeriodRules: mappedDailyPeriodRules
  };
};

export const parametersMapper = (parameters: StoreChainParameters) => {
  const mappedCardIssuerActions = cardIssuerActionsMapper(
    parameters?.cardIssuerActions
  );

  const mappedClientPriority = clientPriorityMapper(parameters?.clientPriority);

  return {
    ...parameters,
    clientPriority: mappedClientPriority,
    sms: {
      ...parameters?.sms,
      prefixConfig: parameters?.sms?.prefixConfig?.defaultChannel
        ? parameters?.sms?.prefixConfig
        : null
    },
    cardIssuerActions: mappedCardIssuerActions
  };
};
