import { Center, Flex, HStack, Tag } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { isProduction } from "utils";

import { useRoles } from "hooks/roles/useRoles";

import { ReactComponent as Logo } from "./logo.svg";
import { Navigation } from "./navigation/Navigation";
import { anchorStyles } from "./navigation/PageLink";

export function Header(): JSX.Element {
  const { userRoles } = useRoles();
  return (
    <HStack
      as="nav"
      borderBottom="1px solid"
      borderBottomColor="gray.400"
      gap="0"
      position="sticky"
      zIndex="10"
      top="0"
      alignItems="stretch"
      flexWrap={{ base: "wrap", lg: "nowrap" }}
    >
      <Center
        as={NavLink}
        to="/"
        backgroundColor="blue.dark"
        padding="1.5rem 2rem"
        width={{ base: "100%", lg: "fit-content" }}
        {...anchorStyles}
      >
        <Logo />
      </Center>

      <Navigation />

      {!isProduction() && (
        <Flex
          position="absolute"
          right={{ base: "1.5rem", lg: "0.5rem" }}
          top={{ base: "1.5rem", xl: "0.5rem" }}
          gap="1rem"
        >
          <Tag size="lg" borderRadius="full" colorScheme="red">
            {process.env.REACT_APP_ENVIRONMENT}
          </Tag>
          <Tag size="lg" borderRadius="full" backgroundColor="#00FF7F">
            Roles: {JSON.stringify(userRoles)}
          </Tag>
        </Flex>
      )}
    </HStack>
  );
}
