import { ReactNode } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text
} from "@chakra-ui/react";
import { FieldArray, getIn, useFormikContext } from "formik";
import isString from "lodash/isString";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { TStore } from "types/configFields";

import useActiveValue from "../hooks/useActiveValue";
import { noDataStyles, tilesContainerStyles, tileStyles } from "../styles";

interface IStoreFieldArrayProps {
  name: string;
  header: string;
  addButtonText: string;
  emptyArrayText: string;
  emptyItem: Record<string, any>;
  renderComponent: (item: any, index: number) => ReactNode;
  renderDisabledComponent: (
    item: any,
    index: number
  ) => ReactNode | ReactNode[];
}

const StoreFieldArray = ({
  name,
  header,
  addButtonText,
  emptyArrayText,
  emptyItem,
  renderComponent,
  renderDisabledComponent
}: IStoreFieldArrayProps) => {
  const { isReadOnly } = useFieldGenerationContext();
  const { errors, setFieldValue } = useFormikContext<TStore>();
  const {
    value,
    storeChainValue,
    getActiveValue,
    getStoreBadgeClickability,
    getStoreChainBadgeClickability,
    savedStoreValue,
    setSavedStoreValue,
    shouldUseChainValue,
    setShouldUseChainValue
  } = useActiveValue({
    name
  });

  const error = getIn(errors, name);
  const hasError = Boolean(error && isString(error));

  const handleStoreChainBadgeClick = () => {
    if (getStoreChainBadgeClickability() === "pointer") {
      setSavedStoreValue(value);
      setFieldValue(name, storeChainValue);
      setShouldUseChainValue(true);
    }
  };

  const renderStoreArray = (remove: (index: number) => void) => {
    if (shouldUseChainValue) {
      return savedStoreValue && savedStoreValue.length > 0 ? (
        (savedStoreValue as any[]).map((item: any, index: number) => (
          <Flex {...tileStyles} mt="0" key={index} minW="315px">
            {renderDisabledComponent(item, index)}
          </Flex>
        ))
      ) : (
        <Text {...noDataStyles}>{emptyArrayText}</Text>
      );
    } else {
      return value && value.length > 0 ? (
        value.map((item: any, index: any) => (
          <Flex {...tileStyles} mt="0" key={index} minW="315px">
            {renderComponent(item, index)}

            {!isReadOnly && (
              <Button
                onClick={() => remove(index)}
                variant="ghost"
                colorScheme="red"
                color="red.dark"
                width="min-content"
                marginTop="auto"
                marginLeft="auto"
                leftIcon={<Trash2 size="1rem" />}
              >
                Usuń
              </Button>
            )}
          </Flex>
        ))
      ) : (
        <Text {...noDataStyles}>{emptyArrayText}</Text>
      );
    }
  };

  return (
    <Flex flexDir="column">
      <Text
        data-searchid={name}
        fontSize="0.8rem"
        fontWeight="bold"
        mt="1.5rem"
        mb="1rem"
      >
        {header}
      </Text>
      <FormControl isInvalid={hasError} position="static">
        <FieldArray name={name}>
          {({ push, remove }) => (
            <>
              {!isReadOnly && (
                <Button
                  onClick={() => push(emptyItem)}
                  variant="ghost"
                  leftIcon={<CirclePlusIcon size="1rem" />}
                  justifyContent="start"
                  mb="1rem"
                >
                  {addButtonText}
                </Button>
              )}
              <Flex gap="1rem">
                <Badge
                  fontSize=".6rem"
                  variant="solid"
                  minWidth="2.5rem"
                  maxHeight="24px"
                  colorScheme={
                    getActiveValue() === "store" ? "green" : undefined
                  }
                  cursor={getStoreBadgeClickability()}
                  onClick={() => {
                    if (getStoreBadgeClickability() === "pointer") {
                      setShouldUseChainValue(false);
                      setFieldValue(name, savedStoreValue);
                    }
                  }}
                >
                  Sklep
                </Badge>
                <Box w="100%" mb="2rem">
                  <Flex
                    {...tilesContainerStyles}
                    borderColor={
                      getActiveValue() === "store" ? "green" : "transparent"
                    }
                  >
                    {renderStoreArray(remove)}
                  </Flex>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </Box>
              </Flex>
              <Flex gap="1rem">
                <Badge
                  fontSize=".6rem"
                  variant="solid"
                  minWidth="2.5rem"
                  maxHeight="24px"
                  backgroundColor={
                    getActiveValue() === "chain" ? "green.700" : undefined
                  }
                  cursor={getStoreChainBadgeClickability()}
                  onClick={handleStoreChainBadgeClick}
                >
                  Sieć
                </Badge>
                <Flex
                  {...tilesContainerStyles}
                  mb="2rem"
                  borderColor={
                    getActiveValue() === "chain" ? "green.700" : "transparent"
                  }
                >
                  {storeChainValue && storeChainValue.length > 0 ? (
                    storeChainValue.map((item: any, index: number) => (
                      <Flex {...tileStyles} mt="0" key={index} minW="315px">
                        {renderDisabledComponent(item, index)}
                      </Flex>
                    ))
                  ) : (
                    <Text {...noDataStyles}>{emptyArrayText}</Text>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </FieldArray>
      </FormControl>
    </Flex>
  );
};

export default StoreFieldArray;
