import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: "white",

    _checked: {
      bg: "white"
    }
  },
  track: {
    bg: "gray.medium",
    _checked: {
      bg: "blue.dark"
    }
  }
});

const lg = definePartsStyle({
  container: {
    height: "min-content",
    width: "2.5rem",
    position: "relative"
  },
  thumb: {
    height: ".75rem",
    width: ".75rem",
    position: "absolute",
    top: "50%",
    transform: "translate(6px, -50%)",

    _checked: {
      height: "1.5rem",
      width: "1.5rem",
      transform: "translate(64%, -50%)",
      top: "50%"
    }
  },
  track: {
    width: "2.5rem"
  }
});

const Switch = defineMultiStyleConfig({
  baseStyle,
  sizes: { lg },
  defaultProps: { size: "lg" }
});

export default Switch;
