// API/SSD/008

import { API_ROOT } from "consts";

import {
  StoreComponentId,
  StoreComponentState,
  PosPaperStateValue
} from "./common";

const getComponentStateUrl = (storeId: string, componentId: StoreComponentId) =>
  `${API_ROOT}/stores/${storeId}/state/components/${componentId}/state`;

export type GetComponentStateResponse = StoreComponentState<PosPaperStateValue>;

export function preparePosPaperStateUrl(storeId: string): URL {
  const url = new URL(
    getComponentStateUrl(storeId, StoreComponentId.POS_PAPER_STATE)
  );
  return url;
}
