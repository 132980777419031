import {
  Button,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";

import { Routes } from "routes";
import { Healthchecks } from "components/healthchecks/Healthchecks";
import { StoreAvailability } from "components/storeAvailability/StoreAvailability";
import { HidableCell } from "components/table/columnSwitcher/HidableCell";
import { useTableContext } from "contexts/TableContext";
import { useRoles } from "hooks/roles/useRoles";
import { farRightCellStyle } from "utils/commonStyles";

import { Columns, StoreWithChainName } from "../types";

type StoresTableProps = {
  stores: StoreWithChainName[];
};

export const StoresTable = ({ stores }: StoresTableProps) => {
  const { canReadStoreDetails } = useRoles();
  const { getIsVisible } = useTableContext();

  return (
    <TableContainer maxWidth="100vw" overflowX="auto">
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.STORE_ID)}>
              ID sklepu
            </HidableCell>
            <HidableCell
              as={Th}
              isVisible={getIsVisible(Columns.EXTERNAL_STORE_ID)}
            >
              MPK
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.NAME)}>
              Nazwa sklepu
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.ADDRESS)}>
              Adres
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.CHAIN_ID)}>
              ID sieci
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.CHAIN_NAME)}>
              Nazwa sieci
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.UPDATED_AT)}>
              Ostatnia aktualizacja
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.STATE)}>
              Stan
            </HidableCell>
            <HidableCell
              as={Th}
              isVisible={getIsVisible(Columns.STORE_AVAILABILITY)}
            >
              Dostępność
            </HidableCell>

            <HidableCell as={Th} isVisible={getIsVisible(Columns.HEALTHCHECKS)}>
              Healthchecki
            </HidableCell>

            <HidableCell as={Th} isVisible={getIsVisible(Columns.AIFI_ID)}>
              AiFi ID
            </HidableCell>
            <HidableCell as={Th} isVisible={getIsVisible(Columns.SCOPE_NANOVO)}>
              Scope Nanovo
            </HidableCell>
            {canReadStoreDetails && (
              <Th {...farRightCellStyle} height="2.5rem" />
            )}
          </Tr>
        </Thead>
        <Tbody>
          {stores.map(store => (
            <Tr key={store.storeId} height="60px">
              <HidableCell as={Td} isVisible={getIsVisible(Columns.STORE_ID)}>
                {store.storeId}
              </HidableCell>

              <HidableCell
                as={Td}
                isVisible={getIsVisible(Columns.EXTERNAL_STORE_ID)}
              >
                {store.externalStoreId}
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.NAME)}>
                {store.name}
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.ADDRESS)}>
                {store.address}
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.CHAIN_ID)}>
                <NavLink to={`${Routes.STORE_CHAINS}/${store.chainId}`}>
                  {store.chainId}
                </NavLink>
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.CHAIN_NAME)}>
                <NavLink to={`${Routes.STORE_CHAINS}/${store.chainId}`}>
                  {store.chainName}
                </NavLink>
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.UPDATED_AT)}>
                <Skeleton
                  isLoaded={!store.isLoadingState}
                  minWidth="4rem"
                  height="1.5rem"
                >
                  {store.stateUpdatedAt?.toLocaleString()}
                </Skeleton>
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.STATE)}>
                <Skeleton isLoaded={!store.isLoadingState}>
                  {store.state}
                </Skeleton>
              </HidableCell>

              <HidableCell
                as={Td}
                isVisible={getIsVisible(Columns.STORE_AVAILABILITY)}
              >
                <Skeleton isLoaded={!store.isLoadingState}>
                  <StoreAvailability
                    storeAvailability={store.storeAvailability}
                  />
                </Skeleton>
              </HidableCell>

              <HidableCell
                as={Td}
                isVisible={getIsVisible(Columns.HEALTHCHECKS)}
              >
                <Healthchecks
                  posPaperState={store.posPaperState}
                  isLoadingPosPaperState={store.isLoadingPOSPaperState}
                  healthchecks={store.healthchecks}
                  isLoadingHealthchecks={store.isLoadingState}
                />
              </HidableCell>

              <HidableCell as={Td} isVisible={getIsVisible(Columns.AIFI_ID)}>
                <Skeleton isLoaded={!store.isLoadingState}>
                  {store.components.aifi.aifiId}
                </Skeleton>
              </HidableCell>

              <HidableCell
                as={Td}
                isVisible={getIsVisible(Columns.SCOPE_NANOVO)}
              >
                <Skeleton isLoaded={!store.isLoadingState}>
                  {store.components.infokiosk.scope}
                </Skeleton>
              </HidableCell>

              {canReadStoreDetails && (
                <Td {...farRightCellStyle}>
                  <Button as={Link} to={store.storeId}>
                    Więcej
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
