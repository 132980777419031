import { Box, Button, Center } from "@chakra-ui/react";

import { CustomSpinner } from "components/customSpinner/CustomSpinner";

type TableBottomSectionProps = {
  isMore?: boolean;
  isLoading: boolean;
  noData: boolean;
  fetchMore?: () => void;
  entityName: string;
};

export function TableBottomSection({
  isLoading,
  isMore = false,
  noData,
  fetchMore,
  entityName
}: TableBottomSectionProps): JSX.Element | null {
  if (isLoading) {
    return <CustomSpinner />;
  }
  if (isMore && fetchMore) {
    return (
      <Center h="4rem">
        <Button variant="solid" mt="10px" onClick={fetchMore}>
          Pokaż więcej
        </Button>
      </Center>
    );
  }

  if (noData) {
    return <Box p="1.5rem">Brak {entityName} do wyświetlenia</Box>;
  }

  return null;
}
