// API/SSD/019

import { API_ROOT, DEFAULT_RESULTS_PER_PAGE } from "consts";

const listAppConfigKeysUrl = new URL(`${API_ROOT}/appConfigKeys`);

export type AppConfigKeysType =
  | "AIFI"
  | "ADYEN"
  | "INFOKIOSK"
  | "ERP"
  | "POS"
  | "SHORTENER"
  | "SYNERISE"
  | "IO_CONTROLLER";

type KeyData = {
  type: AppConfigKeysType;
  baseUrl: string;
  apiKeyMask: string;
  tokenMask: string;
};

type ListAppConfigKeysQueryParams = {
  type?: AppConfigKeysType;
  maxResults?: string;
  nextPageMarker?: string;
};

export type AppConfigKey = {
  id: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  key: KeyData;
  appConfigReference: string;
};

export type ListAppConfigKeysResponse = {
  nextPageMarker?: string;
  appConfigKeys: AppConfigKey[];
};

export function prepareUrlWithSearchParams({
  maxResults = DEFAULT_RESULTS_PER_PAGE,
  nextPageMarker,
  type = undefined
}: ListAppConfigKeysQueryParams): URL {
  const params: ListAppConfigKeysQueryParams = {
    maxResults
  };

  if (nextPageMarker) {
    params.nextPageMarker = nextPageMarker;
  }

  if (type) {
    params.type = type;
  }

  listAppConfigKeysUrl.search = new URLSearchParams(params).toString();

  return listAppConfigKeysUrl;
}
