import filter from "lodash/filter";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import { toggleCommaStringToArray } from "utils";

import { CardIssuerAction, EMaintenanceBreakType } from "types/configFields";

export const replaceWithInitial = <T>(initial: T, configPart?: T) =>
  !configPart ? initial : configPart;

export const getTypeFromDatetime = (dateTimeString: string) => {
  const dailyRegex = /^\d{2}:\d{2}:\d{2}$/;
  const yearlyRegex = /^\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;
  const oneTimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

  if (dailyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.DAILY;
  } else if (yearlyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.YEARLY;
  } else if (oneTimeRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.ONE_TIME;
  } else {
    return EMaintenanceBreakType.DAILY;
  }
};

export const cardIssuerActionsMapper = (
  cardIssuerActions?: CardIssuerAction[],
  isResponse?: boolean
) => {
  if (!cardIssuerActions) {
    return [];
  }

  return cardIssuerActions.map(({ bins, brands, countryCodes, action }) => {
    const toggledBins = toggleCommaStringToArray(bins);
    const toggledBrands = toggleCommaStringToArray(brands);
    const toggledCountryCodes = toggleCommaStringToArray(countryCodes);

    if (isResponse) {
      return {
        bins: bins ? toggledBins : "",
        brands: brands ? toggledBrands : "",
        countryCodes: countryCodes ? toggledCountryCodes : "",
        action
      };
    } else {
      return {
        bins: bins ? toggledBins : [],
        brands: brands ? toggledBrands : [],
        countryCodes: countryCodes ? toggledCountryCodes : [],
        action
      };
    }
  });
};

export const cleanObject = (value: Object): any => {
  if (isArray(value)) {
    return filter(
      value.map(cleanObject),
      (value: any) =>
        !isNil(value) &&
        (!isArray(value) || value.length > 0) &&
        (!isObject(value) || Object.keys(value).length > 0)
    );
  } else if (isObject(value)) {
    return omitBy(
      mapValues(value, cleanObject),
      (value: any) =>
        (typeof value === "string" && value === "") ||
        isNil(value) ||
        (isArray(value) && value.length === 0) ||
        (isObject(value) && Object.keys(value).length === 0)
    );
  }
  return value;
};
