import { ReactNode } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text
} from "@chakra-ui/react";
import { StoreChainConfig } from "api/common";
import { FieldArray, getIn, useFormikContext } from "formik";
import isString from "lodash/isString";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import {
  noDataStyles,
  tilesContainerStyles,
  tileStyles
} from "pages/store/styles";

interface IStoreChainFieldArrayProps {
  name: string;
  header?: string;
  addButtonText: string;
  emptyArrayText: string;
  emptyItem: Record<string, any>;
  renderComponent: (item: any, index: number) => ReactNode;
}

const StoreChainFieldArray = ({
  name,
  header,
  addButtonText,
  emptyArrayText,
  emptyItem,
  renderComponent
}: IStoreChainFieldArrayProps) => {
  const { values, errors } = useFormikContext<StoreChainConfig>();
  const { isReadOnly } = useFieldGenerationContext();

  const value = getIn(values, name);
  const error = getIn(errors, name);
  const hasError = Boolean(error && isString(error));

  return (
    <Flex data-searchid={name} flexDir="column">
      {header && (
        <Text fontSize="0.8rem" fontWeight="bold" mb="2rem">
          {header}
        </Text>
      )}

      <FormControl isInvalid={hasError} position="static">
        <FieldArray name={name}>
          {({ push, remove }) => (
            <>
              {!isReadOnly && (
                <Button
                  onClick={() => push(emptyItem)}
                  variant="ghost"
                  leftIcon={<CirclePlusIcon size="1rem" />}
                  justifyContent="start"
                  mb="1rem"
                >
                  {addButtonText}
                </Button>
              )}

              <Flex
                {...tilesContainerStyles}
                borderColor={hasError ? "red" : "transparent"}
              >
                {value && value.length > 0 ? (
                  value.map((item: any, index: any) => (
                    <Flex {...tileStyles} mt="0" key={index} minW="315px">
                      {renderComponent(item, index)}

                      {!isReadOnly && (
                        <Button
                          onClick={() => remove(index)}
                          variant="ghost"
                          colorScheme="red"
                          color="red.dark"
                          width="min-content"
                          marginTop="auto"
                          marginLeft="auto"
                          leftIcon={<Trash2 size="1rem" />}
                        >
                          Usuń
                        </Button>
                      )}
                    </Flex>
                  ))
                ) : (
                  <Text {...noDataStyles}>{emptyArrayText}</Text>
                )}
              </Flex>
              <FormErrorMessage>
                {error && isString(error) && error}
              </FormErrorMessage>
            </>
          )}
        </FieldArray>
      </FormControl>
    </Flex>
  );
};

export default StoreChainFieldArray;
